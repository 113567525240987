import React from 'react';
import IndustryFintech1 from '../../components/main/IndustryFintech1/IndustryFintech1';
import IndustryFintech2 from '../../components/main/IndustryFintech2/IndustryFintech2';
import IndustryFintech3 from '../../components/main/IndustryFintech3/IndustryFintech3';
import IndustryFintech4 from '../../components/main/IndustryFintech4/IndustryFintech4';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import ClientTestimonial1 from '../../components/main/ClientTestimonial1/ClientTestimonial1';

const IndustryFintechPage = () => {
  return (
	<div>
	  <IndustryFintech1 />
	  <IndustryFintech2 />
	  <IndustryFintech3 />
	  <IndustryFintech4 />
	  <CaseStudy bgWhite/>
	  <ClientTestimonial1 />
	  <FooterSection />
      <MiniFooter />
	</div>
  );
};

export default IndustryFintechPage;
import { actionTypes } from '../types';

export const news_event = (payload, callback) => ({
    type: actionTypes.NEWS_EVENT_REQUEST,
    payload,
    callback
});

export const news_eventSuccess = (payload) => ({
    type: actionTypes.NEWS_EVENT_SUCCESS,
    payload,
});

export const news_eventFailure = (payload) => ({
    type: actionTypes.NEWS_EVENT_FAILURE,
    payload,
});

export const news_eventReset = () => ({
    type: actionTypes.NEWS_EVENT_RESET,
});

export const news_eventResetAll = () => ({
    type: actionTypes.NEWS_EVENT_RESET_ALL,
}); 
import React from 'react';
import "./CarrerPage1.css"
import SelectButton from '../../common/SelectButton/SelectButton';
import backgroundImage from "../../../static/img/careerbg.png";


const CarrerPage1 = () => {
    return (
        <div className='carrer w-full h-full flex flex-col' style={{backgroundImage: `url(${backgroundImage})`}}>
            <div className='container-lg w-full h-screen flex justify-center'>
                <div className='w-full flex flex-col items-center justify-center'>
                    <h1 className='text-[5rem] xl:text-[7rem] 2xl:text-[9rem] poppins-extralight text-white text-center overflow-hidden'>
                        Join Our Journey of Innovation and Growth
                    </h1>
                    <p className='w-full text-white text-[3.2rem] text-center poppins-light'>At Infominez, we're shaping the future of technology. Join us and be part of a team that thrives on
                    innovation, creativity, and growth.
                    </p>
                    <div className='py-[2rem] mt-[2rem]'>
                        <SelectButton
                            title={'Explore Open Positions'}
                            bgColor="bg-[#00D47E]"
                            width="w-[31.2rem]"
                            textSize="text-[1.8rem]"
                            fontWeight="poppins-medium"
                            height="h-[4.7rem]"
                        // onClick={() => { Navigate("#") }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarrerPage1
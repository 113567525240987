import React, {useEffect} from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import "./IMSPage5.css";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {case_studies} from "../../../Store/actions/case_studiesActions";

const IMSPage5 = () => {

    const dispatch = useDispatch();
    const { caseId } = useParams();

    const case_studiesReducerData = useSelector((state) => state.case_studiesReducer?.data || []);

    useEffect(() => {
        dispatch(case_studies({}));
    }, []);

    const data = case_studiesReducerData.find((item) => item.id == parseInt(caseId))


    const arrayBenefits = data.attributes.Content3.split('\n\n');

    return (
        <div className="ims w-full h-full flex flex-col bg-[#0B1120]">
            <div className='container-lg w-full h-full flex flex-col py-[4rem]'>
            <div className="w-full text-center mt-[2rem] flex flex-col justify-center items-center">
                <h1 className="text-[#F4F7FA] text-[4.5rem] poppins-medium my-[2rem]">{data?.attributes?.Title3}</h1>
                <div className="w-[70%] flex justify-center items-center">
                    <p className="text-[#FFFFFF] text-[2.2rem] poppins-light text-center">
                        {data.attributes.Description}
                    </p>
                </div>
            </div>

                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[4rem] mt-[4rem]">
                        {arrayBenefits.map((item, index) => (
                            <div className="flex text-center" key={index}>
                                <div className="w-[8%] flex justify-start">
                                    <FaCheckCircle className="text-[#FFFFFF] text-[3rem] text-start"/>
                                </div>
                                <h3 className="w-full text-[#F4F7FA] text-[2.2rem] text-start poppins-light mb-[1rem]">
                                    {item}
                                </h3>
                            </div>
                        ))}
                    </div>
            </div>
        </div>
    );
};

export default IMSPage5;

import React from 'react';
import { IndustryImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';

const IndustryFintech4 = () => {
    const services = [
        {
            title: "Fintech Software Development",
            description:
                "From sleek mobile apps to robust web platforms and analytics, we build solutions that fit your vision.",
            imgSrc: IndustryImg.industry6,
        },
        {
            title: "Payment Processing Solutions",
            description:
                "Seamless, secure, scalable payment gateway integrations with Stripe, PayPal, Razorpay & more, serving USA, India, Australia globally.",
            imgSrc: IndustryImg.industry10,
        },
        {
            title: "Blockchain and Cryptocurrency",
            description:
                "Stay at the forefront of the digital revolution with our blockchain and cryptocurrency services.",
            imgSrc: IndustryImg.industry11,
        },
        {
            title: "Regulatory Compliance",
            description:
                "Navigate the complexities of legal requirements and industry standards with ease.",
            imgSrc: IndustryImg.industry7,
        },
        {
            title: "Data Analytics",
            description:
                "Unlock actionable insights from your financial data to inform better decisions.",
            imgSrc: IndustryImg.industry8,
        },
        {
            title: "Security & Fraud Prevention",
            description:
                "Protect your business with multi-layered security measures, from cooling periods to advanced authentication and PPI monitoring.",
            imgSrc: IndustryImg.industry9,
        },
    ];

    return (
        <div className="bg-[#F2F4F4] py-[6rem]">
            <div className="container-lg w-full flex flex-col ">
                <SectionHeaders
                    contentPrimary={"Service Offerings"}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
                />
                <div className="mt-[6rem] mb-[4rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative">
                    <div className="hidden lg:block absolute inset-x-0 top-[50%] transform -translate-y-1/2 border-t-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-y-0 left-1/3 transform -translate-x-1/2 border-l-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-y-0 left-2/3 transform -translate-x-1/2 border-l-[.1rem] border-[#00234480]"></div>

                    {services.map((service, index) => (
                        <div className='flex flex-col justify-items-start'>
                            <div
                                key={index}
                                className="flex flex-col justify-center items-center gap-[2rem] px-[2rem] py-[2rem] ">
                                <div className="w-[4.3rem] h-[4.3rem] ">
                                    <img
                                        src={service.imgSrc}
                                        alt={service.title}
                                        className="w-full h-full"
                                    />
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                    <h3 className="text-[2.8rem] poppins-semibold text-center text-[#3C436A]">
                                        {service.title}
                                    </h3>
                                    <p className="mt-2 text-[2.2rem] poppins-light text-center text-[#707F96]">{service.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className='flex justify-center'>
                    <SelectButton
                        title={'Contact Us Now!'}
                        bgColor="bg-[#41DF6B]"
                        width="w-[29.8rem]"
                        textSize="text-[1.8rem]"
                        fontWeight="poppins-regular"
                        titleColor={`text-white hover:text-[#2B2B2B]`}
                        height="h-[4.7rem]"
                    />
                </div> */}
            </div>
        </div>
    );
};
export default IndustryFintech4;


// Navbar imgs
export const NavbarImg = {
    LogoLight: require('../assets/img/logos/infominez-logo-light.png'),
    LogoDark: require('../assets/img/logos/infominez-logo-dark.png'),
    // LogoLight: require('../assets/img/infominez-logo-new-light.png'),
    // LogoDark: require("../img/logo-dark.svg"),
    // logoSmall: require("../img/logo-small.png")
};


export const AboutUsImg = {
    aboutus1: require("../assets/img/backgrounds/about-us-bg.png"),
    aboutus2: require("../img/aboutus2.png"),
    ajit: require("../assets/img/team/ajit.png"),
    kevin: require("../assets/img/team/kevin.png"),
    andy: require("../assets/img/team/andy.png"),
    sanjay: require("../assets/img/team/sanjay.png"),
    // rajdeep: require("../assets/img/team/rajdeep.jpg"),
    aditya: require("../assets/img/team/aditya.png"),
    mayank: require("../assets/img/team/mayank1.jpg"),
    chhavinath: require("../assets/img/team/chhavinath1.jpg"),
    raksha: require("../assets/img/team/raksha.png"),
    harshit: require("../assets/img/team/harshit2.png"),
    tarun: require("../assets/img/team/tarun.png"),
    monesh: require("../assets/img/team/monesh.jpg"),
    kapil: require("../assets/img/team/kapil.png"),
    rajdeep: require("../assets/img/team/rajdeep.jpg"),
    alok: require("../assets/img/team/alok.png"),
    aditya1: require("../assets/img/team/aditya1.jpg"),
    pankaj: require("../assets/img/team/pankaj.jpg"),
    akshay: require("../assets/img/team/akshay.jpg"),
    pratik: require("../assets/img/team/pratik.png"),
    nagendra: require("../assets/img/team/nagendra.jpg"),
    jay: require("../assets/img/team/jay.png"),
    anita: require("../assets/img/team/anita.jpg"),
    rashmi: require("../assets/img/team/rashmi.png"),
    vision: require("../img/vision.jpg"),
    arrow: require("../img/arrow.png"),
    arrow2: require("../img/arrow2.png"),
    service01: require("../img/service01.png"),
    service02: require("../img/service02.png"),
    service03: require("../img/service03.png"),
    service04: require("../img/service04.png"),
    service05: require("../img/service05.png"),
    service06: require("../img/service06.png"),
    service07: require("../img/service07.png"),
    service08: require("../img/service08.png"),
    MigrationIcon: require("../img/MigrationIcon.png"),
    MigrationIcon1: require("../img/MigrationIcon1.png"),
    SecurityIcon: require("../img/SecurityIcon.png"),
    CloudIcon: require("../img/CloudIcon.png"),
    InfraIcon: require("../img/InfraIcon.png"),
    DevOpsIcon: require("../img/DevOpsIcon.png"),

};


export const ServiceImg = {
    service1: require("../img/service1.png"),
    service2: require("../img/service2.png"),
    service3: require("../img/service3.png"),
    service4: require("../img/service4.png"),
    service5: require("../img/service5.png"),
    service6: require("../img/service6.png"),
    service7: require("../img/service7.png"),
    service8: require("../img/service8.png"),
    aiservice8: require("../img/aiservice8.png"),
    aiservice08: require("../img/aiservice08.png"),
    aiservice09: require("../img/aiservice09.jpg"),
    servicebg1: require("../img/servicebg1.png"),
    service9: require("../img/service9.png"),
    service10: require("../img/service10.png"),
    service11: require("../img/service11.png"),
    service12: require("../img/service12.png"),
    service13: require("../img/service13.png"),
    ThomasMay: require("../img/thomas-may.jpg"),
    fintech: require("../img/fintech.png"),
    fintech01: require("../img/fintech01.png"),
    // fintech010: require("../img/fintech010.png"),
    fintech02: require("../img/fintech02.png"),
    // fintech020: require("../img/fintech020.png"),
    fintech03: require("../img/fintech03.png"),
    // servicebg: require("../assets/img/backgrounds/service-lading-bg.png"),
};


export const CaseStudyImg = {
    casestudy1: require("../img/casestudy1.png"),
    case1: require("../img/case1.png"),
    case2: require("../img/case2.png"),
    case3: require("../img/case3.png"),
    case4: require("../img/case4.png"),
    ims1: require("../img/ims1.png"),
    ims2: require("../img/ims2.png"),
    ims3: require("../img/ims3.png"),
    ims4: require("../img/ims4.png"),
    tech1: require("../img/tech1.png"),
    tech2: require("../img/tech2.png"),
    tech3: require("../img/tech3.png"),
    tech4: require("../img/tech4.png"),
    tech5: require("../img/tech5.png"),
    tech6: require("../img/tech6.png"),

};


export const ContactImg = {
    ContactIcon1: require("../assets/img/icons/contact-icon-1.png"),
    ContactIcon2: require("../assets/img/icons/contact-icon-2.png"),
    ContactIcon3: require("../assets/img/icons/contact-icon-3.png")
};


export const IndustryImg = {
    IndustryPage1: require("../img/industry1.png"),
    IndustryPage2: require("../img/industry2.png"),
    IndustryPage3: require("../img/industry3.png"),
    IndustryPage4: require("../img/industry4.png"),
    IndustryPage5: require("../img/industry5.png"),
    industry6: require("../img/industry6.png"),
    industry7: require("../img/industry7.png"),
    industry8: require("../img/industry8.png"),
    industry9: require("../img/industry9.png"),
    industry10: require("../img/industry10.png"),
    industry11: require("../img/industry11.png"),
    industry12: require("../img/industry12.png"),
    IndustryPage01: require("../img/IndustryPage01.png"),
    IndustryPage02: require("../img/IndustryPage02.png"),
    IndustryPage03: require("../img/IndustryPage03.png"),
    IndustryPage04: require("../img/IndustryPage04.png"),
    IndustryPage05: require("../img/IndustryPage05.png"),
    IndustryPage06: require("../img/IndustryPage06.png"),
    IndustryPage15: require("../img/industry05.png"),
    IndustryPage151: require("../img/industry050.png"),
    IndustryPage152: require("../img/industry051.png"),
    IndustryPage153: require("../img/industry052.png"),
    IndustryPage154: require("../img/industry053.png"),
    IndustryPage155: require("../img/industry054.png"),
    IndustryPage156: require("../img/industry055.png"),
    IndustryPage157: require("../img/industry056.png"),
    IndustryPage158: require("../img/industry057.png"),
    IndustryPage159: require("../img/industry058.png"),
    IndustryPage160: require("../img/industry059.png"),
    IndustryPage161: require("../img/industry060.png"),
    IndustryPage162: require("../img/industry061.png"),
    IndustryPage163: require("../img/industry062.png"),
    IndustryPage164: require("../img/industry063.png"),
    IndustryPage165: require("../img/industry064.png"),
    IndustryPage166: require("../img/industry065.png"),
    IndustryPage167: require("../img/industry066.png"),
    IndustryPage168: require("../img/industry067.png"),
    IndustryPage169: require("../img/industry068.png"),
    IndustryPage170: require("../img/industry069.png"),

};

export const LandingImgs = {
    // HomeLandingImg: require('../../assets/img/home-landing-img.jpg'),
    // HomeLandingActionIcon: require('../../assets/img/home-landing-action-icon.png'),
    // IndustriesLandingImg: require('../../assets/img/industries-landing-img.jpg'),
    // ServicesLandingImg: require('../../assets/img/services-landing-img.jpg'),
    // ContactUsLandingImg: require('../../assets/img/contact-us-landing-img.jpg'),
    // HomeLandingImgNew: require('../assets/img/home-landing-img-new.jpg')
    HomeLandingImgNew: require('../assets/img/backgrounds/hero-landing-bg.gif')
}

// export const ServicesPageImgs = {
//     ServicesPageBgImg1: require("../../assets/img/services-page-img1.jpg"),
//     ServicesPageBgImg2: require("../../assets/img/services-page-img2.jpg"),
//     rightSemiCircleSmall: require("../../assets/img/right-semicircle-small.png"),
// }

export const EndToEndDevelopmentServicesImgs = {
    EndToEndDevelopmentServicesProductEngImg: require("../../static/assets/icons/end-to-end-development-services-product-eng.png"),
    EndToEndDevelopmentServicesDataEngImg: require("../../static/assets/icons/end-to-end-development-services-data-eng.png"),
    EndToEndDevelopmentServicesBlockChaninImg: require("../../static/assets/icons/end-to-end-development-services-blockchain.png"),
    EndToEndDevelopmentServicesCloudEngImg: require("../../static/assets/icons/end-to-end-development-services-cloud-eng.jpg"),
}

export const ProcessModelSectionImages = {
    ProcessModelFlowImg: require("../assets/img/process-model-flow.png")
}

export const AboutUsSectionImages = {
    AboutUsExperienceImage: require('../img/AboutUsExperienceImage.jpg'),
    // AboutUsExperienceImage: require('../assets/img/About-us-section-img.png'),
}

export const AboutUsSectionIcons = {
    AboutUsIdeaIcon: require('../assets/icons/About-us-idea-icon.png')
}

export const IndustrySectionIcons = {
    IndustryFintechIcon: require('../assets/icons/industry-fintech-icon.png'),
    IndustryEdtechIcon: require('../assets/icons/industry-edtech-icon.png'),
    IndustryECommerceIcon: require('../assets/icons/industry-e-commerce-icon.png'),
    IndustryHealthCareIcon: require('../assets/icons/industry-healthcare-design-icon.jpg'),
}

export const WhyInfominezSectionIcons = {
    WhyInfominezGrowthIcon: require('../assets/icons/why-infominez-growth-icon.jpg'),
    WhyInfominezInnovationIcon: require('../assets/icons/why-infominez-innovation-icon.jpg'),
    WhyInfominezTechnologyIcon: require('../assets/icons/why-infominez-technology-icon.jpg'),
    WhyInfominezScrollLogo: require('../assets/icons/why-infominex-scroll-logo.png')
}

export const CaseStudySectionImage = {
    CaseStudyATMImage: require('../assets/img/case-study-img.png'),
}

export const ClientTestimonialSectionClientImage = {
    ShaibuCherianImage: require('../assets/img/shaibu-cherian-client-testimonial-image.png'),
    SonaliBhansalImage: require(`../assets/img/sonali-bhansal-client-testimonial-image.png`),
    ManoharBhoiImage: require(`../assets/img/manohar-bhoi-client-testimonial-image.png`),
    client1: require("../assets/img/client1.jpg"),
    client2: require("../assets/img/client2.jpg"),
    client3: require("../assets/img/client3.jpg"),
}

export const ClientTestimonialSectionClientIcon = {
    LinkedInIcon: require('../assets/icons/linkedin-icon.png'),
    TwitterIcon: require('../assets/icons/twitter-icon.png'),
    quates: require("../assets/img/quates.png"),
    test1: require("../img/test1.png"),
    test2: require("../img/test2.png"),
    test3: require("../img/test3.png"),

}

export const FooterSectionicons = {
    ContactUsIcon: require('../assets/icons/contact-us-icon.png'),
    EmailUsIcon: require('../assets/icons/email-us-icon.png'),
    LocateUsIcon: require('../assets/icons/locate-us-icon.png'),
    LinkedInIcon: require('../assets/icons/linkedin-icon-bg-less.png'),
    InstagramIcon: require('../assets/icons/instagram-icon.png'),
    FacebookIcon: require('../assets/icons/facebook-icon-bgless.png'),
    TwitterIcon: require('../assets/icons/twitter-icon-bgless.png')
}

export const ServicesSectionPageImages = {
    servicesHomeBgImage: require('../assets/img/Services-landing-bg.png'),
    ProductEngineeringServiceImage: require('../assets/img/product-engineering-services-image.png'),
    PartnerWithInfominezImage: require('../assets/img/partner-with-infominez-image.png'),
    PartnerWithInfominezImage1: require('../assets/img/partner-with-infominez-image1.jpg'),
    OurAi: require('../assets/img/our-ai.png'),
    OurMl: require('../assets/img/our-ml.jpg'),
    Excellence: require('../assets/img/ML-Excellence.jpg'),
}

export const ServicesSectionPageIcons = {
    AccelerateToMarketIcon: require('../assets/icons/accelerate-to-market-icon.png'),
    CostEffectiveSolutionIcon: require('../assets/icons/cost-effective-solns-icon.png'),
    EndToEndExpertiseIcon: require('../assets/icons/end-to-end-expertise-icon.png'),
    FutureProofInnovationIcon: require('../assets/icons/future-proof-innovation-icon.png'),
    IntuitiveUserExperienceIcon: require('../assets/icons/intutive-user-experience-icon.png'),
    PartnershipBeyondLaunchIcon: require('../assets/icons/partnership-beyond-launch-icon.png'),
}


export const CareerImg = {
    careerbg: require("../img/careerbg.png"),
    carrer1: require("../img/carrer1.jpg"),
    career2: require("../img/career21.jpg"),
    career3: require("../img/career3.png"),
    career4: require("../img/career4.png"),
    career5: require("../img/career5.png"),
    career6: require("../img/career6.png"),
    career7: require("../img/career7.jpg"),
    quates1: require("../img/quates1.png"),
    employee: require("../img/employee.png"),
    employee1: require("../img/employee1.jpg"),
    carrer11: require("../img/carrer11.jpg"),
    carrer01: require("../img/carrer01.jpg"),
};


export const BlogBImg = {
    blogb: require("../img/blogb.png"),
    blogb1: require("../img/blogb1.png"),
    blogb2: require("../img/blogb2.png"),
    blogb3: require("../img/blogb3.png"),
    blogb4: require("../img/blogb4.png"),
    blogb5: require("../img/blogb5.png"),
    ThomasMay: require("../img/thomas-may.jpg"),
    pod1: require("../img/pod1.jpg"),
};


import React from 'react';
import IMSPage1 from '../../components/main/IMSPage1/IMSPage1';
import IMSPage2 from '../../components/main/IMSPage2/IMSPage2';
import IMSPage3 from '../../components/main/IMSPage3/IMSPage3';
import IMSPage4 from '../../components/main/IMSPage4/IMSPage4';
import IMSPage5 from '../../components/main/IMSPage5/IMSPage5';
import IMSPage6 from '../../components/main/IMSPage6/IMSPage6';
import AboutUsForm from '../../components/main/AboutUsForm/AboutUsForm';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';


const IMSDetails = () => {
    return (
        <div>
            <IMSPage1 />
            <IMSPage2 />
            <IMSPage3 />
            <IMSPage4 />
            <IMSPage5 />
            <IMSPage6 />
            <GetStartedDetailsForm />
            <FooterSection />
            <MiniFooter />
        </div>
    );
};

export default IMSDetails;

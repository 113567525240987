import React from "react";
import { CareerImg } from '../../../static/constants/imageConstant'; 

function CarrerPage2() {

    return (
        <div className="bg-[#fff] w-full">
            <div className="">
                <div className="poppins-extralight text-[6rem] text-center py-[5rem]">
                    Life at Infominez
                </div>
                <div className="relative flex flex-col lg:flex-row mb-[6rem]">
                    <div className="lg:w-[60%] w-full">
                        <img
                            src={CareerImg.carrer1}
                            className="w-full h-full"
                        />
                    </div>
                    <div className="lg:my-[5%] lg:translate-x-[-12.5%] bg-white px-[7rem] lg:w-[50%] 2xl:h-full w-full">
                        <div className="w-full text-[2.2rem] 2xl:text-[2.8rem] text-[#707F96] poppins-medium text-start my-[1rem] px-[2rem] py-[4rem] 2xl:px-[6.7rem] 2xl:py-[14rem]">
                            At Infominez, we foster a culture of innovation, collaboration, and growth.
                            Our team thrives in a dynamic environment where creativity is encouraged,
                            ideas are valued, and every challenge is an opportunity to learn. Whether
                            it’s through mentorship, hands-on projects, or upskilling, we are committed
                            to helping each individual reach their full potential, making Infominez not
                            just a workplace, but a community where talent grows and success flourishes.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarrerPage2;
import React from 'react';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';

const ServicesProductPage2 = () => {
    const scrollToSection = () => {
        const element = document.getElementById('GetStartedDetailsForm');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <div className="container-lg">
            <SectionHeaders
                contentPrimary={<h2 className="overflow-hidden">Our Product Engineering <span className="text-[#1E91EE]">Services</span></h2>}
                classes={`poppins-extralight text-[6rem] items-center text-center justify-center my-[7.2rem]`}
            />
            <div className="w-full h-full flex flex-col lg:flex-row gap-[4rem]">
                <div className="w-full lg:w-[48%] h-full">
                    <img
                        src={ServiceImg.service8}
                        alt="Designer working on screen"
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="w-full lg:w-[52%] h-full flex flex-col justify-start">
                    <div className='w-full'>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Product Development</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Full product development using state-of-the-art tech, agile methods, and clear communication.
                                </p>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">UI/UX Design</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Intuitive and visually appealing user interfaces to boost engagement and satisfaction.
                                </p>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Quality Assurance</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Ensuring your product works flawlessly, scales securely, and delivers user-friendly experiences.
                                </p>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">DevOps and Continuous Integration</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Seamless user experiences that foster customer loyalty.
                                </p>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Maintenance and Support</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Ongoing maintenance and support services to ensure your product stays up-to-date and responsive to changing market demands.
                                </p>
                            </div>
                        </li>
                    </div>
                    <div className="mt-[4rem] px-[3rem] mb-[4rem]">
                        <SelectButton
                            title={'Ready to Transform Your Idea'}
                            bgColor="bg-[#00D47E]"
                            width="w-[37rem]"
                            textSize="text-[1.6rem]"
                            fontWeight="poppins-regular"
                            titleColor={`text-white hover:text-[#2B2B2B]`}
                            height="h-[4.7rem]"
                            onClick={scrollToSection}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesProductPage2;
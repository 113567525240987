import React from 'react';
import { CareerImg } from '../../../static/constants/imageConstant';

const PODServices3 = () => {
    return (
        <div className="bg-[#fff] w-full">
            <div className="">
                <div className="relative flex flex-col lg:flex-row mb-[6rem]">
                    <div className="lg:my-[5%] lg:translate-x-[10%] bg-white py-[4rem] px-[7rem] lg:w-[50%] w-full ">
                        <h1 className='text-[6rem] text-[#000000] poppins-extralight'>Growth POD</h1>
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-medium text-[2.8rem] text-[#3C436A]">For Growing Companies Ready to Accelerate </h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        An expanded team designed for scaling businesses with evolving product needs, handling complex projects with enhanced efficiency.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-medium text-[2.8rem] text-[#3C436A]">Team Composition</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        2 Frontend Developers, 2 Backend Developers, 1 QA Tester, 1 Project Manager
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-medium text-[2.8rem] text-[#3C436A]">Ideal for:</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Full product development, scaling applications, integrating advanced features.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-medium text-[2.8rem] text-[#3C436A]">Benefits</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Balanced, expert-driven team with built-in project management for streamlined execution and faster delivery.
                                    </p>
                                </div>
                            </li>
                        </div>
                    </div>
                    <div className="lg:w-[60%] w-full">
                        <img
                            src={CareerImg.carrer11}
                            className="w-full h-full object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PODServices3
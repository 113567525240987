import { actionTypes } from '../types';

export const contactUsv2 = (payload, callback) => ({
    type: actionTypes.CONTACT_US_V2_REQUEST,
    payload,
    callback
});

export const contactUsv2Success = (payload) => ({
    type: actionTypes.CONTACT_US_V2_SUCCESS,
    payload,
});

export const contactUsv2Failure = (payload) => ({
    type: actionTypes.CONTACT_US_V2_FAILURE,
    payload,
});

export const contactUsv2Reset = () => ({
    type: actionTypes.CONTACT_US_V2_RESET,
});

export const contactUsv2ResetAll = () => ({
    type: actionTypes.CONTACT_US_V2_RESET_ALL,
}); 
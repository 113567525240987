import React, { useEffect } from 'react';
import { CaseStudyImg } from '../../../static/constants/imageConstant';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { case_studies } from "../../../Store/actions/case_studiesActions";

const CasePage1 = () => {
    const dispatch = useDispatch();
    const { caseId } = useParams();

    const case_studiesReducerData = useSelector((state) => state.case_studiesReducer?.data || []);

    useEffect(() => {
        dispatch(case_studies({}));
    }, []);

    const data = case_studiesReducerData.find((item) => item.id == parseInt(caseId));

    return (
        <div className='bg-[#F4F7FA] mt-[20rem]'>
            <div className="w-full h-full container-lg flex flex-col py-[4rem]">
                <div className="w-full text-center">
                    <h1 className="text-[4.5rem] text-[#000] poppins-medium">{data?.attributes?.title_5}</h1>
                </div>

                <div className="flex flex-col lg:flex-row items-center gap-[7.2rem] mt-[4rem]">
                    <div className="w-full lg:w-1/2 flex items-center justify-center p-[2rem]">
                        <div className='w-full h-full'>
                            <img src={CaseStudyImg.ims2} alt="Illustration" className="w-full h-auto " />
                        </div>
                    </div>

                    <div className="w-full lg:w-1/2 space-y-[1.5rem] p-[2rem]">
                        {[
                            "Optimized Field Force Utilization: Speedy ATM issue resolution and seamless ticket assignment for optimal field force utilization.",
                            "Customizable and Scalable: Highly customizable and scalable solution to meet evolving needs.",
                            "Enhanced Operational Visibility: Role-specific reports, dashboards, and mobile application for enhanced control.",
                            "Improved Efficiency: Faster ticket resolution, reduced operational costs, and enhanced overall efficiency.",
                            "Enhanced User Experience: Intuitive interface and mobile application for a seamless experience.",
                            "Increased Control: Full ownership of application enhancements and customization."
                        ].map((text, index) => (
                            <div key={index} className="flex items-start space-x-[2.2rem]">
                                <span className="w-[6%] text-[#0478FF] text-[2.2rem]">✔</span>
                                <p className="w-full text-[2.2rem] text-[#333840] poppins-light">{text}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CasePage1;

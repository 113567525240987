import React from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders';
import { CaseStudySectionImage } from '../../../static/constants/imageConstant';
import SelectButton from '../../common/SelectButton/SelectButton';

function DataEngineering6({ bgWhite }) {
    return (
        <div className={bgWhite ? "bg-white" : "bg-[#F2F4F4]"}>
      <div className="container-lg py-[8rem]">
        <div className="relative flex flex-col md:flex-row my-[5rem]  justify-center container">
          <div className="md:w-[60%] w-full">
            <img
              src={CaseStudySectionImage.CaseStudyATMImage}
              className="w-full h-full"
            />
          </div>
          <div className="md:flex justify-center items-center h-full mx-[3.5rem] ">
            <div className="h-[0rem] md:h-[59rem] w-[0rem] md:w-[0.2rem] bg-[#C7C7C7] flex justify-center">
              <div className="absolute top-[8rem] w-[0rem] md:w-[0.8rem] h-[0rem] md:h-[16rem] rounded-full bg-[#00D47E]"></div>
            </div>
          </div>

          <div className="md:w-[40%] mt-[1rem] md:mt-0 w-full">
            <h1 className="text-[#010101] text-[6rem] poppins-extralight mb-[2.7rem]">Case Study</h1>
            <div className="poppins-semibold w-full">
              <div className="text-[4.5rem] mb-[4rem]">Fintech</div>
              <div className='w-full h-[0.2rem] mt-[2rem] mb-[4rem] bg-[#C7C7C7] flex justify-center'></div>
              <div className="text-[3rem] my-[1.5rem] text-wrap">
                Maximize Efficiency, Minimize Costs, and Enhance...
              </div>
              <div className="poppins-regular text-[#5D656D] text-[2.2rem]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since.
              </div>
              <div className="mt-[10.5rem]">
                <SelectButton
                  titleColor="text-white"
                  classNames=""
                  bgColor={`bg-[#00D47E]`}
                  fontWeight={`poppins-regular`}
                  height={`h-[5.7rem]`}
                  width={`w-[25.7rem]`}
                  title={`Read More`}
                  textSize={`text-[1.8rem]`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
};

export default DataEngineering6
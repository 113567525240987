import React from 'react';
import CloudEngineering1 from '../../components/main/CloudEngineering1/CloudEngineering1';
import CloudEngineering2 from '../../components/main/CloudEngineering2/CloudEngineering2';
import CloudEngineering3 from '../../components/main/CloudEngineering3/CloudEngineering3';
import CloudEngineering4 from '../../components/main/CloudEngineering4/CloudEngineering4';
import CloudEngineering5 from '../../components/main/CloudEngineering5/CloudEngineering5';
import CloudEngineering6 from '../../components/main/CloudEngineering6/CloudEngineering6';
import CloudEngineering7 from '../../components/main/CloudEngineering7/CloudEngineering7';
import CloudEngineering8 from '../../components/main/CloudEngineering8/CloudEngineering8';
import Insights from '../../components/main/Insights/Insights';
import ClientTestimonial from '../../components/main/ClientTestimonial/ClientTestimonial';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';

const CloudEngineering = () => {
  return (
    <div>
      <CloudEngineering1 />
      <CloudEngineering2 />
      <CloudEngineering3 />
      <CloudEngineering4 />
      <CloudEngineering5 />
      <CloudEngineering6 />
      <CloudEngineering7 />
      <CaseStudy bgWhite />
      <Insights />
      <ClientTestimonial />
      <GetStartedDetailsForm bgWhite />
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default CloudEngineering
import React from 'react';
import "./IMSBlogPage.css"
import { BlogBImg } from '../../../static/constants/imageConstant';

const IMSBlogPage = () => {
  return (
    <div className='ims-blog w-full h-full'>
      <div className='w-full h-[45rem]'>
        <div className='container-lg w-full h-full flex flex-col my-auto justify-center'>
        <h1 className="text-[2.5rem] sm:text-[3rem] lg:text-[6rem] text-[#fff] text-center mt-[2rem] lg:mt-[4rem] poppins-extralight overflow-hidden">
        Home  Digital  It’s the Best. We Must<p className='leading-[7rem] overflow-hidden'>Do  Better</p>
        </h1>
        </div>
      </div>
    </div>
  )
}

export default IMSBlogPage
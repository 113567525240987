import React from 'react'
import IMSBlogPage1 from '../../components/main/IMSBlogPage1/IMSBlogPage1'
import IMSBlogPage3 from '../../components/main/IMSBlogPage3/IMSBlogPage3'
import IMSBlogPage from '../../components/main/IMSBlogPage/IMSBlogPage'
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm'
import FooterSection from '../../components/common/FooterSection/FooterSection'
import MiniFooter from '../../components/common/MiniFooter/MiniFooter'

const IMSBlog = () => {
  return (
    <div>
      <IMSBlogPage />
      <IMSBlogPage1 />
      <IMSBlogPage3 />
      <GetStartedDetailsForm />
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default IMSBlog
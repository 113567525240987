export const actionTypes = {

    // utilActions

    SET_STRAPI_TOKEN: "SET_STRAPI_TOKEN",

    CONTACT_US_V1_REQUEST: 'CONTACT_US_V1_REQUEST',
    CONTACT_US_V1_SUCCESS: 'CONTACT_US_V1_SUCCESS',
    CONTACT_US_V1_FAILURE: 'CONTACT_US_V1_FAILURE',
    CONTACT_US_V1_RESET: 'CONTACT_US_V1_RESET',
    CONTACT_US_V1_RESET_ALL: 'CONTACT_US_V1_RESET_ALL',

    CONTACT_US_V2_REQUEST: 'CONTACT_US_V2_REQUEST',
    CONTACT_US_V2_SUCCESS: 'CONTACT_US_V2_SUCCESS',
    CONTACT_US_V2_FAILURE: 'CONTACT_US_V2_FAILURE',
    CONTACT_US_V2_RESET: 'CONTACT_US_V2_RESET',
    CONTACT_US_V2_RESET_ALL: 'CONTACT_US_V2_RESET_ALL',

    STRAPI_TOKEN_REQUEST: 'STRAPI_TOKEN_REQUEST',
    STRAPI_TOKEN_SUCCESS: 'STRAPI_TOKEN_SUCCESS',
    STRAPI_TOKEN_FAILURE: 'STRAPI_TOKEN_FAILURE',
    STRAPI_TOKEN_RESET: 'STRAPI_TOKEN_RESET',
    STRAPI_TOKEN_RESET_ALL: 'STRAPI_TOKEN_RESET_ALL',

    STRAPI_BLOGS_REQUEST: 'STRAPI_BLOGS_REQUEST',
    STRAPI_BLOGS_SUCCESS: 'STRAPI_BLOGS_SUCCESS',
    STRAPI_BLOGS_FAILURE: 'STRAPI_BLOGS_FAILURE',
    STRAPI_BLOGS_RESET: 'STRAPI_BLOGS_RESET',
    STRAPI_BLOGS_RESET_ALL: 'STRAPI_BLOGS_RESET_ALL',

    CASE_STUDIES_REQUEST: 'CASE_STUDIES_REQUEST',
    CASE_STUDIES_SUCCESS: 'CASE_STUDIES_SUCCESS',
    CASE_STUDIES_FAILURE: 'CASE_STUDIES_FAILURE',
    CASE_STUDIES_RESET: 'CASE_STUDIES_RESET',
    CASE_STUDIES_RESET_ALL: 'CASE_STUDIES_RESET_ALL',

    WHITE_PAPER_REQUEST: 'WHITE_PAPER_REQUEST',
    WHITE_PAPER_SUCCESS: 'WHITE_PAPER_SUCCESS',
    WHITE_PAPER_FAILURE: 'WHITE_PAPER_FAILURE',
    WHITE_PAPER_RESET: 'WHITE_PAPER_RESET',
    WHITE_PAPER_RESET_ALL: 'WHITE_PAPER_RESET_ALL',

    NEWS_EVENT_REQUEST: 'NEWS_EVENT_REQUEST',
    NEWS_EVENT_SUCCESS: 'NEWS_EVENT_SUCCESS',
    NEWS_EVENT_FAILURE: 'NEWS_EVENT_FAILURE',
    NEWS_EVENT_RESET: 'NEWS_EVENT_RESET',
    NEWS_EVENT_RESET_ALL: 'NEWS_EVENT_RESET_ALL',

    TECHNOLOGIES_REQUEST: 'TECHNOLOGIES_REQUEST',
    TECHNOLOGIES_SUCCESS: 'TECHNOLOGIES_SUCCESS',
    TECHNOLOGIES_FAILURE: 'TECHNOLOGIES_FAILURE',
    TECHNOLOGIES_RESET: 'TECHNOLOGIES_RESET',
    TECHNOLOGIES_RESET_ALL: 'TECHNOLOGIES_RESET_ALL',

}
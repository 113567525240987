import React, { useState } from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import IndustriesSecCard from '../../common/cards/IndustriesSecCard';
// import IndustriesSecCard from '../../common/Cards/IndustriesSecCard'
// import { IndustriesSecCardImgs } from '../../../static/constants/imgConstants/imgConstants';
import { IndustrySectionIcons } from '../../../static/constants/imageConstant';

function IndustriesSec() {
    const [selectId, setSelectId] = useState('fintech');
    const [data] = useState([
        {
            id: "fintech",
            title: `Fintech and Banking`,
            isSelected: false,
            content: `Pioneering the Digital Finance Revolution`,
            iconLight: IndustrySectionIcons.IndustryFintechIcon,
            // iconDark: IndustriesSecCardImgs.IndustriesIconBlue1,
            path: "/industry-fintech-page"
        },
        {
            id: "e-commerce",
            title: `E Commerce and Retail`,
            isSelected: false,
            content: `Retail Mastery: Conquer the Digital Landscape`,
            iconLight: IndustrySectionIcons.IndustryECommerceIcon,
            // iconDark: IndustriesSecCardImgs.IndustriesIconBlue2,
            path: "/industry-e-commerce-retial"
        },
        {
            id: "edutech",
            title: `Edutech and learning`,
            isSelected: false,
            content: "Empower Your Learning, Unleash Your Potential",
            iconLight: IndustrySectionIcons.IndustryEdtechIcon,
            // iconDark: IndustriesSecCardImgs.IndustriesIconBlue3,
            path: "/industry-edutech"
        },
        {
            id: "healthcare",
            title: `Healthcare and Wellness design`,
            isSelected: false,
            content: "Discover Your Path to Optimal Well-Being",
            iconLight: IndustrySectionIcons.IndustryHealthCareIcon,
            // iconDark: IndustriesSecCardImgs.IndustriesIconBlue4,
            path: "/industry-health-care"
        },
    ]);

    return (
        <div className='industries-sec bg-[#F2F4F4] py-[10rem] flex flex-col justify-center items-center text-center'>
            <SectionHeaders 
                contentPrimary="Industry We Serve "
                classes="text-[6rem] poppins-extralight text-[#0A0A0A]"
            />
            <div className='lg:flex mt-[6rem] lg:gap-x-[1rem] items-center justify-center justify-items-center'>
                {data?.length &&
                    data?.map((item)=> {
                        return(
                            <IndustriesSecCard 
                                key={item.id}
                                id={item.id}
                                selectId={selectId}
                                setSelectId={setSelectId}
                                title={item.title} 
                                content={item.content}
                                iconLight={item.iconLight}
                                iconDark={item.iconDark}
                                path={item.path}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default IndustriesSec

import React from 'react'

// import LatestInsights from '../../main/LatestInsights/LatestInsights'
// import CaseStudy from '../../main/CaseStudy/CaseStudy'
// import ContactUs from '../../main/ContactUs/ContactUs'
// import FooterMain from '../../common/Footer/FooterMain'
// import FooterMini from '../../common/Footer/FooterMini'
import { BiSolidArrowToTop } from 'react-icons/bi';
import Navbar from '../Navbar/Navbar';

function PrimaryLayout({ showNavbar, showFooterMain, ShowFooterMini, ShowCaseStudy, showLatestInsights, showContactUs, isContactUsAltBg, children }) {

    // console.log('====================================');
    // console.log("window.scrollY :", window.scrollY);
    // console.log('====================================');
    console.log(children);
    return (
        <div className='primary-layout w-full'>
            {showNavbar ? <Navbar /> : null }
            {children}
            {/* {ShowCaseStudy ? <CaseStudy /> : null }
            {showLatestInsights ? <LatestInsights /> : null }
            {showContactUs ? <ContactUs isContactUsAltBg={isContactUsAltBg} /> : null }
            {showFooterMain ? <FooterMain /> : null }
            {ShowFooterMini ? <FooterMini /> : null } */}
            <div 
                className='fixed bottom-[2rem] right-[2rem] rounded-full bg-[#115555] p-[.8rem] cursor-pointer hover:scale-105'
                onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }}
            >
                <BiSolidArrowToTop color="#FFFFFF" size={24} />
            </div>
        </div>
    )
}

export default PrimaryLayout

import React from 'react'
import { ServiceImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';

function DataEngineering2() {
  const scrollToSection = () => {
    const element = document.getElementById('GetStartedDetailsForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const features = [
    {
      title: 'Make informed decisions',
      description: 'Leverage data-driven insights to optimize operations, identify new opportunities, and mitigate risks.',
      imgSrc: ServiceImg.service2,
    },
    {
      title: 'Enhance operational efficiency',
      description: 'Streamline data processes, improve data quality, and boost productivity.',
      imgSrc: ServiceImg.service5,
    },
    {
      title: 'Gain a competitive edge',
      description: 'Understand your customers, markets, and trends better than ever before.',
      imgSrc: ServiceImg.service3,
    },
    {
      title: 'Protect your data',
      description: 'Ensure data privacy, security, and compliance with industry regulations.',
      imgSrc: ServiceImg.service6,
    },
    {
      title: 'Maximize ROI',
      description: 'Extract maximum value from your data investments.',
      imgSrc: ServiceImg.service4,
    },
    {
      title: 'Partnership Beyond Launch',
      description: 'Rely on our ongoing maintenance and support to ensure your product continues to thrive, adapt, and evolve with your users needs.',
      imgSrc: ServiceImg.service7,
    },
  ];

  return (
    <div className="w-full h-full bg-[#F2F4F4]">
      <div className="container-lg w-full h-full flex flex-col items-center">
        <SectionHeaders
          contentPrimary={'Transform Your Vision into a Thriving Reality'}
          classes={`poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[7.2rem]`}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[6rem] gap-x-[2rem] py-[2rem]">
          {features.map((feature, index) => (
            <div key={index} className="flex items-start space-x-[4rem]">
              <div className="w-[10rem] h-[10rem] flex-shrink-0">
                <img src={feature.imgSrc} alt={feature.title} className="w-full h-full object-contain" />
              </div>
              <div className='w-[80%] text-start'>
                <h2 className="text-[2.8rem] poppins-semibold text-[#000]">{feature.title}</h2>
                <p className="text-[#8A929A] text-[2.2rem] poppins-regular mt-2">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='py-[4rem]'>
          <SelectButton
            title={'Transform Your Idea Today'}
            bgColor="bg-[#00D47E]"
            width="w-[37.9rem]"
            textSize="text-[1.6rem]"
            fontWeight="poppins-regular"
            titleColor={`text-white hover:text-[#2B2B2B]`}
            height="h-[4.7rem]"
            onClick={scrollToSection}
          />
        </div>
      </div>
    </div>
  );
}

export default DataEngineering2
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { strapiBlogs } from '../../../Store/actions/strapiBlogsActions';
import { strapi_base_url } from '../../../Store/constants';
import { FaArrowRight } from 'react-icons/fa';
import { ServiceImg } from '../../../static/constants/imageConstant';
import { useNavigate, useParams } from 'react-router';

const IMSBlogPage3 = () => {
    const { blogId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const strapiBlogsReducerData = useSelector((state) => state.strapiBlogsReducer?.data);

    useEffect(() => {
        dispatch(strapiBlogs());
    }, []);

    return (
        <div className="container-lg w-full h-full py-[4rem]">
            <h1 className="text-[4.5rem] text-[#000] poppins-regular py-[2rem]">Related Posts</h1>
            <div className="w-full flex flex-col lg:flex-row justify-between">
                <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-[5rem] py-[4rem]">
                    {strapiBlogsReducerData?.data
                        ?.filter((item) => item.id.toString() !== blogId) // Exclude the blog with matching blogId
                        .slice(0, 3) // Show only the first 3 blogs
                        ?.map((item, index) => (
                            <div
                                key={index}
                                className="relative bg-[#fff] rounded-[1rem] shadow-xl overflow-hidden group"
                                onClick={() => {
                                    navigate('/blog_details/' + item.id);
                                }}
                            >
                                <div className="w-full h-[28.5rem]">
                                    <img
                                        src={`${strapi_base_url + `/api_img`}${item?.attributes?.main_image?.data.attributes.url}`}
                                        alt=" "
                                        className="w-full h-full"
                                    />
                                </div>
                                <div className="px-[2.7rem] mb-[2rem]">
                                    <div className="flex items-center justify-between text-gray-600 my-[1.8rem]">
                                        <div className="w-full flex items-center gap-[0.2rem]">
                                            <div className="w-[16%]">
                                                <div className="w-[5rem] h-[5rem]">
                                                    <img
                                                        src={ServiceImg.service9}
                                                        alt="Author"
                                                        className="w-full h-full rounded-full"
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-[95%] flex justify-between">
                                                <span className="text-[1.8rem] text-[#010001] poppins-regular">
                                                    {item?.attributes?.author_name}
                                                </span>
                                                <span className="text-[#535B63] text-[1.8rem] poppins-regular">
                                                    {moment(item?.attributes?.updatedAt).format("DD-MMM-YYYY")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className="text-[2.2rem] poppins-regular text-[#000000]">
                                        {item.attributes.main_title}
                                    </h2>
                                    <p className="text-[#000] poppins-light mb-[4rem] text-[2.2rem]">
                                        {item?.attributes?.sub_main_title}
                                    </p>
                                </div>

                                <div className="absolute left-0 bottom-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                    <button
                                        className="w-[5rem] h-[5rem] rounded-full border-[2px] text-black border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center"
                                        onClick={() => console.log('Next button clicked')}
                                    >
                                        <FaArrowRight size={20} />
                                    </button>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default IMSBlogPage3;


import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import { ProcessModelSectionImages } from "../../../static/constants/imageConstant";

function ProcessModel() {
  const processSteps = [
    {
      title: "Research",
      color: "#F1776A",
      details: `Interview/Questionnaire<br />Requirement Gathering<br />Field Study<br />Focus Group<br />White-boarding<br />Heuristic Evaluations`,
    },
    {
      title: "Design",
      color: "#00D47E",
      details: `User Journey/Flow-diagram<br />Structure Design<br />Information Architect<br />Wireframe<br />Mockup/Prototyping<br />UX Guidelines/Style Guide`,
    },
    {
      title: "Development",
      color: "#3394CF",
      details: `Structure Design<br />Prototyping<br />Animation Design<br />Icon Design<br />UX Guidelines`,
    },
    {
      title: "Launch",
      color: "#F08637",
      details: `Evaluation<br />Usability Test<br />Heuristic Evaluation`,
    },
  ];

  return (
    <div className="processmodel-sec py-[10rem] flex flex-col justify-center items-center">
      <SectionHeaders
        contentPrimary="Process Model"
        classes="text-[7rem] poppins-extralight mb-[1.2rem]"
      />
      <h2 className="text-[2.5rem] text-[#5D656D] poppins-medium text-center w-full">
        From Imagination to Realization and Beyond: Unveiling Our
        Process Model for Continuous Support
      </h2>
      <div className="container-lg my-[10rem]">
        <img
          src={ProcessModelSectionImages.ProcessModelFlowImg}
          className="w-full h-full"
          alt="Process Model Flow"
        />
      </div>
      <div className="container-lg w-full grid grid-cols-2 lg:grid-cols-4 2xl:pl-[4rem]">
        {processSteps.map((step, index) => (
          <div
            key={index}
            className="flex items-start gap-[2rem]"
            style={{ width: "100%", maxWidth: "100%", flexBasis: "25%" }}
          >
            <div
              className="h-[35rem] w-[2rem] flex"
              style={{ backgroundColor: step.color }}
            ></div>
            <div>
              <h1
                className="text-[2rem] sm:text-[2.8rem] md:text-[3.5rem] lg:text-[4.2rem] poppins-medium mb-[1.8rem]"
                style={{ color: step.color }}
              >
                {step.title}
              </h1>
              <p
                className="text-[#333333] text-[1.5rem] md:text-[2rem] lg:text-[2.4rem] poppins-medium"
                dangerouslySetInnerHTML={{ __html: step.details }}
              ></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProcessModel;


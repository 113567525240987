import React from 'react';
import AboutUsLanding from '../../components/main/AboutUsLanding/AboutUsLanding';
import OurStory from '../../components/main/OurStory/OurStory';
import OurLeadershipTeam from '../../components/main/OurLeadershipTeam/OurLeadershipTeam';
import Expertise from '../../components/main/Expertise/Expertise';
import VisionMission from '../../components/main/VisionMission/VisionMission';
import AboutUsForm from '../../components/main/AboutUsForm/AboutUsForm';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import LifeAtInfominez from '../../components/main/LifeAtInfominez/LifeAtInfominez';

function AboutUs() {
    return (
        <div>
            <AboutUsLanding />
            <OurStory />
            <OurLeadershipTeam />
            <Expertise />
            <VisionMission />
            <LifeAtInfominez />
            <GetStartedDetailsForm bgWhite/>
            <FooterSection />
            <MiniFooter />
        </div>
    )
}

export default AboutUs

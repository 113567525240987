import React from 'react';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';

const IndustryPage2 = () => {
    return (
        <div className="bg-[#F2F4F4]">
            <div className="container-lg">
                <SectionHeaders
                    contentPrimary={'Advantages with Us'}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center my-[4rem]`}
                />
                <div className="w-full h-full flex flex-col lg:flex-row justify-between">
                    <div className="w-full lg:w-[40%] flex flex-col items-center">
                        <img
                            src={ServiceImg.service8}
                            alt="Designer working on screen"
                            className="w-full h-full"
                        />
                    </div>
                    <div className="w-full lg:w-[50%] h-full flex flex-col justify-start py-[3rem] mb-[3rem]">
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-[80%]'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Industry Expertise</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Leverage our deep-rooted knowledge in thefinancial sector.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-[80%]'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Innovation</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Stay ahead with cutting-edge technology and trends.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-[80%]'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Custom Solutions</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Receive tailor-made solutions for your unique business needs.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-[80%]'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Security</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Benefit from robust security measures protecting your financial data.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-[80%]'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Client-Centric Approach</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Achieve success with our dedicated, client-focused strategies
                                    </p>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryPage2;

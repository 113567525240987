import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import { AboutUsImg } from "../../../static/constants/imageConstant";

const services = [
    {
        id: 1,
        title: "Enterprise Web/Mobile Solutions",
        description:
            "Front-End and Back-End Development, Database Management, Security, Scalability and Performance Optimization, API Development and Integration, DevOps, Microservices Architecture",
        technologies: "Java, Python, RDBMS, NoSQL Kotlin, Swift, ReactNative...",
        image: AboutUsImg.service01
    },
    {
        id: 2,
        title: "Cloud Engineering",
        description:
            "Front-End and Back-End Development, Database Management, Security, Scalability and Performance Optimization, API Development and Integration, DevOps, Microservices Architecture",
        technologies: "Azure, AWS, GCP etc.",
        image: AboutUsImg.service02,
    },
    {
        id: 3,
        title: "Enterprise Mobile Solutions: ",
        description:
            "Native App and Cross-Platform Mobile App Development, Offline Functionality, Deployment and Distribution, Device Management and MDM Integration, Push Notifications and Messaging",
        technologies: "Java, Python, RDBMS, NoSQL Kotlin, Swift, ReactNative...",
        image: AboutUsImg.service03,
    },
    {
        id: 4,
        title: "Data Engineering: ",
        description:
            "Native App and Cross-Platform Mobile App Development, Offline Functionality, Deployment and Distribution, Device Management and MDM Integration, Push Notifications and Messaging",
        technologies: "DataBricks, Data Factory, Tableau, Lookr etc.",
        image: AboutUsImg.service04,
    },
    {
        id: 5,
        title: "UI/UX: ",
        description:
            "Competitive Analysis, User Flow, Wireframes, Style Guide, High Fidelity Mockups, Prototypes",
        technologies: "Kotlin, Swift, ReactNative ...",
        image: AboutUsImg.service05,
    },
    {
        id: 6,
        title: "Quality Assurance",
        description:
            "Competitive Analysis, User Flow, Wireframes, Style Guide, High Fidelity Mockups, Prototypes",
        technologies: "JMeter, Selenium, Postman etc.",
        image: AboutUsImg.service06,
    },
    {
        id: 7,
        title: "AI and ML: ",
        description:
            "Predictive Analytics, Natural Language Processing (NLP), Computer Vision, Recommendation Systems, Model Training and Optimization, Deployment and Monitoring, Data Preprocessing and Feature Engineering, AI/ML Integration with Enterprise Applications",
        technologies: "TensorFlow, PyTorch, OpenAI API, etc.",
        image: AboutUsImg.service07,
    },
    {
        id: 8,
        title: "Smart Device Integration: ",
        description:
            "Device Communication Protocols, Sensor Data Management, Edge Computing, Cloud Connectivity, IoT Device Security, Real-Time Data Processing, Smart Home and Industrial Automation Solutions",
        technologies: "IoT Core, MQTT, Zigbee, etc.",
        image: AboutUsImg.service08,
    },
];

export default function Expertise() {
    return (
        <div className="bg-[#F2F4F4]">
            <div className="container-lg w-full h-full py-[10rem]">
                <SectionHeaders
                    contentPrimary={"Our Expertise"}
                    classes={`poppins-extralight text-[6rem] items-center text-center mb-[1.5rem]`}
                    contentSecondary={
                        <>
                            We excel in developing groundbreaking solutions that redefine industry
                            standards. <br /> Our core services include:
                        </>
                    }
                    secondaryClasses={`poppins-regular text-[2.5rem] text-[#8A929A] mb-[6rem]`}
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-16 mt-[4rem]">
                    {services.map((service) => (
                        <div key={service.id} className="flex items-start space-x-8 text-left">
                            <div className="flex items-center justify-center w-[8rem] h-[8rem] bg-[#010101] rounded-full">
                                <div className="w-[3.8rem] h-[3.8rem]">
                                    <img
                                    src={service.image}
                                    alt={service.title}
                                    className="w-full h-full rounded-full"
                                /></div>
                            </div>
                            <div className="w-[80%]">
                                <h3 className="text-[2.8rem] poppins-regular text-[#121212]">
                                    {service.title}
                                </h3>
                                <p className="text-[2.8rem] poppins-regular text-[#121212]">
                                    ({service.technologies})
                                </p>
                                <p className="text-[2.2rem] text-[#707F96] poppins-regular">
                                    {service.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

import { actionTypes } from '../types';

export const strapiBlogs = (payload, callback) => ({
    type: actionTypes.STRAPI_BLOGS_REQUEST,
    payload,
    callback
});

export const strapiBlogsSuccess = (payload) => ({
    type: actionTypes.STRAPI_BLOGS_SUCCESS,
    payload,
});

export const strapiBlogsFailure = (payload) => ({
    type: actionTypes.STRAPI_BLOGS_FAILURE,
    payload,
});

export const strapiBlogsReset = () => ({
    type: actionTypes.STRAPI_BLOGS_RESET,
});

export const strapiBlogsResetAll = () => ({
    type: actionTypes.STRAPI_BLOGS_RESET_ALL,
}); 
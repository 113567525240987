export const fontsData = [
    {
        title: 'dm sans medium',
        className: 'dm-sans-medium',
        fontWeight: 400,
    },
    {
        title: 'dm sans medium italic',
        className: 'dm-sans-medium-italic',
        fontWeight: 400,
    },
    {
        title: 'dm sans bold italic',
        className: 'dm-sans-bold-italic',
        fontWeight: 600,
    },
    {
        title: 'montserrat medium',
        className: 'montserrat-medium',
        fontWeight: 400,
    },
    {
        title: 'montserrat semibold',
        className: 'montserrat-semibold',
        fontWeight: 500,
    },
    {
        title: 'montserrat bold',
        className: 'montserrat-bold',
        fontWeight: 700,
    },
    {
        title: 'poppins thin',
        className: 'poppins-thin',
        fontWeight: 600,
    },
    {
        title: 'poppins extralight',
        className: 'poppins-extralight',
        fontWeight: 700,
    },
    {
        title: 'poppins light',
        className: 'poppins-light',
        fontWeight: 400,
    },
    {
        title: 'poppins regular',
        className: 'poppins-regular',
        fontWeight: 500,
    },
    {
        title: 'poppins medium',
        className: 'poppins-medium',
        fontWeight: 600,
    },
    {
        title: 'poppins semibold',
        className: 'poppins-semibold',
        fontWeight: 700,
    },
    {
        title: 'poppins bold',
        className: 'poppins-bold',
        fontWeight: 400,
    },
    {
        title: 'poppins extrabold',
        className: 'poppins-extrabold',
        fontWeight: 600,
    },
    {
        title: 'poppins black',
        className: 'poppins-black',
        fontWeight: 700,
    }
]




import { actionTypes } from '../types';

export const strapiToken = (payload, callback) => ({
    type: actionTypes.STRAPI_TOKEN_REQUEST,
    payload,
    callback
});

export const strapiTokenSuccess = (payload) => ({
    type: actionTypes.STRAPI_TOKEN_SUCCESS,
    payload,
});

export const strapiTokenFailure = (payload) => ({
    type: actionTypes.STRAPI_TOKEN_FAILURE,
    payload,
});

export const strapiTokenReset = () => ({
    type: actionTypes.STRAPI_TOKEN_RESET,
});

export const strapiTokenResetAll = () => ({
    type: actionTypes.STRAPI_TOKEN_RESET_ALL,
}); 
import React from 'react';
import "./IndustryECommerceRetial1.css";
import BtnOutlined from '../../common/BtnOutlined/BtnOutlined';
import backgroundImage from "../../../static/assets/img/backgrounds/industries-landing-bg.jpg";

const IndustryECommerceRetial1 = () => {
    return (
        <div>
            <div className="industryecomm w-full h-[63rem] flex flex-col items-center justify-center" style={{backgroundImage: `url(${backgroundImage})`}}>
                <div className="container-lg flex flex-col items-center justify-center text-center text-white px-4">
                    <h1 className="text-[2.5rem] sm:text-[3rem] lg:text-[5.6rem] poppins-thin overflow-hidden">
                        Retail Mastery: Conquer the Digital Landscape
                    </h1>
                    <p className="text-[2.4rem] md:text-[2.7rem] lg:text-[3.2rem] poppins-light">
                        Maximize your retail potential with our data-driven solutions, designed to enhance agility, reduce costs, and improve customer satisfaction.
                    </p>
                    <div className='flex justify-center mt-[4rem]'>
                        <BtnOutlined
                            title="Learn More"
                            width="w-[29.4rem]"
                            textSize="text-[2.2rem]"
                            classNames={"border-2 rounded-full"}
                            titleColor={`text-white hover:text-[#0E0731]`}
                            borderColor="border-[#00D47E]"
                            bgColor={`bg-inherit text-white hover:bg-[#00D47E]`}
                            fontWeight={"poppins-medium"}
                            ButtonHeight={"h-[8rem]"}
                            hasArrow
                        //onClick={() => { navigate("/contact-us") }}
                        />
                    </div>
                </div>
            </div>
            <div className="container-lg bg-white text-center">
                <p className="text-[#8A929A] text-[2.5rem] sm:text-[2rem] md:text-[2.4rem] py-[9rem]">
                    Optimizing supply chain and retail management with innovative technology solutions for enhanced efficiency and customer satisfaction. We can be your strategic partner in transforming supply chain and retail management through cutting-edge technology solutions.
                </p>
            </div>
        </div>
    )
}

export default IndustryECommerceRetial1;
import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import { WhyInfominezSectionIcons } from "../../../static/constants/imageConstant";

function WhyInfominezSec() {
    const data = [
        {
            id: 1,
            title: "Technological Mastery",
            icon: WhyInfominezSectionIcons.WhyInfominezTechnologyIcon,
            contentOne: `Our team champions deep expertise across diverse tech domains. We leverage this knowledge to build robust, scalable, and future-ready solutions tailored to your business needs.`,
        },
        {
            id: 2,
            title: "Innovation Hub",
            icon: WhyInfominezSectionIcons.WhyInfominezInnovationIcon,
            contentOne: `We're perpetually pioneering new ways to harness next generation tech for your advantage. Our innovative mindset ensures you're always ahead of the curve in a rapidly evolving digital landscape.`,
        },
        {
            id: 3,
            title: "Your Growth Catalyst",
            icon: WhyInfominezSectionIcons.WhyInfominezGrowthIcon,
            contentOne: `Your Growth Catalyst: We prioritize understanding your unique ambitions to engineer solutions that propel you toward success. By aligning our strategies with your goals, we create impactful outcomes that accelerate your growth journey`,
        },
    ];

    return (
        <div className="bg-[#F2F4F4] flex flex-col justify-between items-center">
            <div className="container-lg py-[7rem]">
                <SectionHeaders
                    contentPrimary={"Why Infominez Stands Out:"}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
                />
                <div className="container-lg flex">
                    <div className="grid lg:grid-cols-3 justify-items-center lg:gap-x-[4.5rem] gap-y-[2rem] mt-[8rem] mb-[2.4rem]">
                        {data.map((item) => {
                            return (
                                <div className="bg-white w-full items-center justify-center px-[3rem]">
                                    <div className="lg:w-full">
                                        <div className="poppins-semibold text-[2.8rem] text-[#3C436A] py-[2rem] px-[3rem]">
                                            {item.title}
                                        </div>
                                        <div className="flex justify-center">
                                            <div className="flex items-start justify-center w-[9rem] m-[2rem]">
                                                <img
                                                    src={item.icon}
                                                    className="w-full"
                                                />
                                            </div>
                                            <div className="w-[85%] poppins-regular text-[2rem] text-[#707F96] ml-[2rem] mb-[2rem] line-clamp-5 2xl:line-clamp-none">
                                                {item.contentOne}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyInfominezSec;

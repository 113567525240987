import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { strapiBlogs } from '../../../Store/actions/strapiBlogsActions';
import { strapi_base_url } from '../../../Store/constants';
import { ServiceImg } from '../../../static/constants/imageConstant';

const IMSBlogPage1 = () => {
    const { blogId } = useParams();

    const dispatch = useDispatch();
    const strapiBlogsReducerData = useSelector((state) => state.strapiBlogsReducer?.data);

    useEffect(() => {
        dispatch(strapiBlogs());
    }, []);

    console.log("strapiBlogsReducerData :", strapiBlogsReducerData?.data);
    console.log("blogId :", blogId);

    const blogData = strapiBlogsReducerData?.data?.find(item => item.id === parseInt(blogId));

    console.log("blogData :", blogData);

    return (
        <div className="container-lg w-full flex flex-col lg:flex-row justify-between mt-[2rem] py-[2rem] bg-[#fff]">
            {blogData && (
                <div className="w-full">
                    <p className="text-[1.8rem] text-[#000000] poppins-regular mb-2">
                        {moment(blogData?.attributes?.updatedAt).format("DD-MMM-YYYY")}
                    </p>
                    <h1 className="text-[4.5rem] text-[#000000] poppins-regular leading-[6rem] mb-4 overflow-hidden">
                        {blogData?.attributes?.main_title}
                    </h1>
                    <p className="text-[#000000] text-[1.9rem] poppins-light">
                        {blogData?.attributes?.sub_main_title}
                    </p>
                    <div className='w-full h-auto py-[4rem]'>
                        <img
                            src={`${strapi_base_url}/api_img${blogData?.attributes?.main_image?.data.attributes.url}`}
                            alt="Developer"
                            className="w-full rounded-lg mb-6"
                        />
                    </div>
                    <div className="w-full">
                        <p
                            className="text-[#000000] text-[1.9rem] poppins-light leading-relaxed"
                            dangerouslySetInnerHTML={{
                                __html: blogData?.attributes?.description_1?.replace(/\n \n/g, '<br/><br/>'),
                            }}
                        />
                    </div>

                    <div className=" w-full flex flex-col py-[2rem]">
                        <h1 className="text-[4.5rem] text-[#000] poppins-regular mb-6">
                            {blogData?.attributes?.title_1}
                        </h1>
                        <p className="text-[#000] text-[1.9rem] poppins-light leading-relaxed mb-6">
                            {blogData?.attributes?.sub_title_1}
                        </p>
                        <div className="grid grid-cols-2 gap-[1.5rem] mb-[2rem]">
                            <img
                                src={`${strapi_base_url}/api_img${blogData?.attributes?.sub_image_1?.data.attributes.url}`}
                                alt="Conference Image 1"
                                className="w-full h-auto  rounded-lg"
                            />
                            <img
                                src={`${strapi_base_url}/api_img${blogData?.attributes?.sub_image_2?.data.attributes.url}`}
                                alt="Conference Image 2"
                                className="w-full h-auto  rounded-lg"
                            />
                        </div>
                        <p className="text-[#000] text-[1.9rem] poppins-light leading-relaxed mb-8">
                            {blogData?.attributes?.description_2}
                        </p>
                        <h2 className="text-[4.5rem] text-[#000] poppins-regular mb-4">
                            {blogData?.attributes?.title_3}
                        </h2>
                        <p className="text-[#000] text-[1.9rem] poppins-light leading-relaxed">
                            {blogData?.attributes?.description_3}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default IMSBlogPage1;

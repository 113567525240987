import React from 'react';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';
import { ServicesSectionPageImages } from '../../../static/constants/imageConstant';

const Ai_Ml_Services4 = () => {
    const scrollToSection = () => {
        const element = document.getElementById('GetStartedDetailsForm');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    return (
        <div className="bg-[#F2F4F4]">
            <div className="container-lg mb-[8rem]">
                <SectionHeaders
                    contentPrimary={<>Our AI and ML Services Include</>}
                    classes={`poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[7.2rem]`}
                />
                <div className="w-full h-full flex flex-col lg:flex-row gap-[4rem] items-start">
                    <div className="w-full lg:w-[53%] h-full flex flex-col justify-start">
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">AI Strategy & Consulting</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Partner with us to define a scalable AI strategy that aligns with your business vision.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Custom Machine Learning Models</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Develop tailored ML models for predictive analytics, NLP, computer vision, and more.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Data Preprocessing & Integration</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Ensure high-quality data pipelines and seamless integration across platforms.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">AI-Powered Automation</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Optimize workflows through AI-driven robotic process automation (RPA).
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Advanced Analytics & Insights</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Turn data into actionable strategies with AI-powered tools.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Compliance & Ethical AI</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Implement responsible AI systems with built-in compliance to industry standards.
                                    </p>
                                </div>
                            </li>
                        </div>
                        <div className="mt-[4rem] px-[3rem]">
                            <SelectButton
                                title={'Discover How We Can Help You Succeed'}
                                bgColor="bg-[#00D47E]"
                                width="w-[41.6rem]"
                                textSize="text-[1.6rem]"
                                fontWeight="poppins-regular"
                                titleColor={`text-white hover:text-[#2B2B2B]`}
                                height="h-[4.7rem]"
                                onClick={scrollToSection}
                            />
                        </div>
                    </div>
                    <div className="w-full lg:w-[45%]">
                        <div className='w-full'>
                        <img
                            src={ServicesSectionPageImages.OurMl}
                            alt="img"
                            className="w-full"
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ai_Ml_Services4
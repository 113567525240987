import { put, call, takeLatest } from 'redux-saga/effects';
import { endpoints, strapi_base_url } from '../constants';
import { AxiosService } from '../services';
import { actionTypes } from '../types';
import { case_studiesFailure, case_studiesSuccess } from '../actions/case_studiesActions';

function* case_studies({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: strapi_base_url,
            endPoint: endpoints.case_studies + `?populate=*`,
            data: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("CASE_STUDIES_SUCCESS status :", response.status);
        console.log("CASE_STUDIES_SUCCESS data :", response.data);
        console.log("CASE_STUDIES_SUCCESS message :", response.message);
        console.log('====================================');
        yield put(case_studiesSuccess({
            status: response?.status,
            data: response?.data?.data,
            message: response?.message
        })
        )
        if (callback) {
            callback(
                response?.status,
                response?.response,
                response?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("CASE_STUDIES_FAILURE error :", error.response?.status);
        console.log("CASE_STUDIES_FAILURE message :", error.response?.message);
        console.log("CASE_STUDIES_FAILURE error :", error);
        console.log('====================================');
        yield put(case_studiesFailure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* case_studiesSaga() {
    yield takeLatest(actionTypes.CASE_STUDIES_REQUEST, case_studies);
}

export default case_studiesSaga;

import React, {useEffect} from 'react';
import { CaseStudyImg } from '../../../static/constants/imageConstant';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {case_studies} from "../../../Store/actions/case_studiesActions";
import {strapi_base_url} from "../../../Store/constants";

const IMSPage6 = () => {

    const dispatch = useDispatch();
    const { caseId } = useParams();

    const case_studiesReducerData = useSelector((state) => state.case_studiesReducer?.data || []);

    useEffect(() => {
        dispatch(case_studies({}));
    }, []);

    const data = case_studiesReducerData.find((item) => item.id == parseInt(caseId))


    const arrayTechnologyUsed = data.attributes?.TechnologyUsed.data

    return (
        <div className='w-full h-full flex flex-col bg-gredient-1 py-[4rem]'>
            <div className='container-lg w-full h-full flex flex-col'>
                <div className='w-full  '>
                    <h1 className='text-[#0C0E2A] text-[4.5rem] poppins-medium'>Technology Used:</h1>
                </div>
                <div className="w-full h-full grid grid-cols-1 sm:grid-cols-2 lg:flex lg:justify-between gap-[2rem]  py-[4rem]">

                    {arrayTechnologyUsed.length > 0 && arrayTechnologyUsed.map((item)=> {
                        return <div className="w-[34rem] h-[9rem]">
                            <img
                                src={`${strapi_base_url + `/api_img`}${item?.attributes?.url}`}
                                alt="" className="w-full h-full"/>
                        </div>
                    })}
                </div>


            </div>
                <div className='w-full h-full bg-[#2666CF] flex flex-col py-[4rem]'>
                  <div className="container-lg" >
                    <h1 className='text-[#fff] text-[4.5rem] poppins-semibold  py-[2rem]'>{data?.attributes?.Title4}</h1>
                    <div className='w-full flex '>
                        <div className='w-[62%]'>
                            <p className='text-[#F6F7FC] text-[2.4rem] poppins-regular'>
                                {data.attributes?.Conclusion}
                            </p>
                        </div>
                        <div className='w-[30%] flex justify-center items-center'>
                            <button className='text-[#0E102D] text-[2.4rem] poppins-regular p-[1rem] bg-[#41DF6B] rounded-[0.6rem]'>Download Case Study</button>
                        </div>
                    </div>
                    </div>
                </div>
        </div>
    )
}

export default IMSPage6

import React, {useEffect} from 'react';
import { CaseStudyImg } from '../../../static/constants/imageConstant';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {case_studies} from "../../../Store/actions/case_studiesActions";
import {strapi_base_url} from "../../../Store/constants";

const IMSPage4 = () => {

    const dispatch = useDispatch();
    const { caseId } = useParams();

    const case_studiesReducerData = useSelector((state) => state.case_studiesReducer?.data || []);

    useEffect(() => {
        dispatch(case_studies({}));
    }, []);

    const data = case_studiesReducerData.find((item) => item.id == parseInt(caseId))


    const arraySolution = data.attributes.content2.split('\n\n');

    return (
        <div className='bg-[#F4F7FA] w-full '>
            <div className="w-full h-full container-lg flex flex-col py-[4rem]">
                <div className="w-full text-center ">
                    <h1 className="text-[4.5rem] text-[#000] poppins-medium">{data?.attributes?.title2}</h1>
                </div>

                <div className="flex flex-col lg:flex-row items-center gap-[7.7rem]">
                    <div className="w-full lg:w-1/2 flex items-center justify-center p-[2rem]">
                        <div className='w-full h-full'>
                            <img
                                src={`${strapi_base_url}/api_img${data?.attributes?.image2?.data.attributes.url}`}
                                alt="Illustration" className="w-full h-full " />
                        </div>
                    </div>

                    <div className="w-full lg:w-1/2 space-y-[1.5rem] p-[2rem]">
                        {arraySolution.length > 0 && arraySolution.map((item)=> {
                            return <div className="flex items-start space-x-[2.2rem]">
                                <span className="w-[6%] text-[#0478FF] text-[2.2rem]">✔</span>
                                <p className="w-full text-[2.2rem] text-[#333840] poppins-light">
                                    {item}
                                </p>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IMSPage4

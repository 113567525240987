import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import { IndustryImg } from "../../../static/constants/imageConstant";

const cardData = [
    {
        id: 1,
        title: "Enhanced Student Success",
        description: "Drive higher engagement, improved outcomes, and increased retention.",
        icon: IndustryImg.IndustryPage157,
    },
    {
        id: 2,
        title: "Optimized Operations",
        description: "Streamline processes and make data-driven decisions.",
        icon: IndustryImg.IndustryPage158,
    },
    {
        id: 3,
        title: "Future-Ready Institution",
        description: "Stay ahead of educational trends with adaptable technology.",
        icon: IndustryImg.IndustryPage159,
    },
    {
        id: 4,
        title: "Empowered Educators",
        description: "Support personalized instruction and focus on student growth.",
        icon: IndustryImg.IndustryPage160,
    },
];

const cardsData = [
    {
        id: 1,
        title: "Personalized Learning",
        description: "Experience tailored education to match individual needs and pace.",
        icon: IndustryImg.IndustryPage161,
    },
    {
        id: 2,
        title: "Expanded Access",
        description: "Enjoy flexible learning opportunities and quality education.",
        icon: IndustryImg.IndustryPage162,
    },
    {
        id: 3,
        title: "Interactive Learning",
        description: "Engage with peers and instructors in collaborative environments.",
        icon: IndustryImg.IndustryPage163,
    },
    {
        id: 4,
        title: "Improved Outcomes",
        description: "Achieve academic success through effective learning experiences.",
        icon: IndustryImg.IndustryPage164,
    },
];

const IndustryEdutech3 = () => {
    return (
        <div className="w-full h-full flex flex-col items-center px-[7rem]">
            <div className="container-lg py-[4rem] mt-[3.2rem]">
                <SectionHeaders
                    contentPrimary={"Value Propositions"}
                    classes={`poppins-extralight text-[6rem] text-center`}
                />
            </div>
            <div className="text-[4rem] text-[#000000] text-center poppins-light mb-[2.6rem]">For Educational Institutions</div>

            <div className="container-lg grid grid-cols-1 md:grid-cols-2  gap-y-[4rem] gap-x-[3rem] w-full mb-[8rem]">
                {cardData.map((card) => (
                    <div
                        key={card.id}
                        className="flex flex-col items-center p-[4rem] bg-[#F2F4F4] rounded-[1rem] shadow-md hover:shadow-lg transition-shadow duration-300"
                    >
                        <div className="flex items-start text-center">
                            <div className="w-[36%]">
                                <div className="w-[18.1rem] h-[18.1rem]">
                                    <img
                                        src={card.icon}
                                        alt={card.title}
                                        className="w-full h-full"
                                    />
                                </div>
                            </div>
                            <div className="w-[60%] flex flex-col text-start">
                                <h1 className="poppins-medium text-[2.8rem] text-[#000000] mb-[1rem]">
                                    {card.title}
                                </h1>
                                <h3 className="border border-t border-[#979EA5] h-[1px] w-full mt-[0.8rem] mb-[0.8rem]"></h3>
                                <p className="text-[2.2rem] text-[#979EA5] poppins-light">{card.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="text-[4rem] text-[#000000] text-center poppins-light mb-[2.6rem]">For Educational Institutions</div>

            <div className="container-lg grid grid-cols-1 md:grid-cols-2  gap-y-[4rem] gap-x-[3rem] w-full mb-[8rem]">
                {cardData.map((cards) => (
                    <div
                        key={cards.id}
                        className="flex flex-col items-center p-[4rem] bg-[#F2F4F4] rounded-[1rem] shadow-md hover:shadow-lg transition-shadow duration-300"
                    >
                        <div className="flex items-start text-center">
                            <div className="w-[36%]">
                                <div className="w-[18.1rem] h-[18.1rem]">
                                    <img
                                        src={cards.icon}
                                        alt={cards.title}
                                        className="w-full h-full"
                                    />
                                </div>
                            </div>
                            <div className="w-[60%] flex flex-col text-start">
                                <h1 className="poppins-medium text-[2.8rem] text-[#000000] mb-[1rem]">
                                    {cards.title}
                                </h1>
                                <h3 className="border border-t border-[#979EA5] h-[1px] w-full mt-[0.8rem] mb-[0.8rem]"></h3>
                                <p className="text-[2.2rem] text-[#979EA5] poppins-light">{cards.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IndustryEdutech3;
import React from "react";
import {
  FooterSectionicons,
  NavbarImg,
} from "../../../static/constants/imageConstant";

function FooterSection() {
  const data = [
    {
      id: "contactus",
      title: "Call Us 24/7",
      content: "+91- 98260-61259",
      extraContent: "",
      icon: FooterSectionicons.ContactUsIcon,
    },
    {
      id: "email",
      title: "Make a Quote",
      content: "hello@infominez.com",
      extraContent: "",
      icon: FooterSectionicons.EmailUsIcon,
    },
    {
      id: "location",
      title: "Location",
      content: (
        <>
          253, Sai Kripa Colony,
          <br /> Scheme No 171,
        </>
      ),
      extraContent: "Indore - 452010",
      icon: FooterSectionicons.LocateUsIcon,
    },
  ];

  const exploreData = [
    {
      id: "home",
      title: "HOME",
      href: "/",
    },
    {
      id: "about",
      title: "ABOUT",
      href: "/about-us",
    },
    {
      id: "portfolio",
      title: "PORTFOLIO",
      href: "",
    },
    {
      id: "casestudy",
      title: "CASESTUDY",
      href: "",
    },
    {
      id: "blog",
      title: "BLOG",
      href: "/all-blogs",
    },
  ];

  const socialData = [
    {
      id: "linkedin",
      title: "LINKEDIN",
      href: "https://www.linkedin.com/company/infominez/",
      target: "_blank",
      icon: FooterSectionicons.LinkedInIcon,
    },
    {
      id: "facebook",
      title: "FACEBOOK",
      href: "https://www.facebook.com/infominez?mibextid=ZbWKwL",
      target: "_blank",
      icon: FooterSectionicons.FacebookIcon,
    },
    {
      id: "twitter",
      title: "TWITTER",
      href: "https://x.com/infominez",
      target: "_blank",
      icon: FooterSectionicons.TwitterIcon,
    },
    {
      id: "instagram",
      title: "INSTAGRAM",
      href: "https://www.instagram.com/infominez?igsh=MXQ0bTRiODl1YjNmYg==",
      target: "_blank",
      icon: FooterSectionicons.InstagramIcon,
    },
  ];

  return (
    <div className="bg-[#010101]">
      <div className="items-center justify-center container-lg">
        <div className="flex flex-col md:flex-row items-center justify-center">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex flex-col justify-center items-start my-[5rem] h-[20rem] w-full md:flex-row ${
                  index < data.length - 1
                    ? "border-r border-b border-[#FFFFFF]"
                    : "border-b border-r md:border-r-0"
                }`}
              >
                <div className="flex justify-center items-start mt-[2rem] px-[1rem] [@media(max-width:768px)]:flex-row [@media(max-width:1000px)]:flex-col">
                  <div className="w-[6.1rem] h-[6.1rem] mr-[2rem]">
                    <img src={item.icon} alt="" className="w-full h-full" />
                  </div>
                  <div className="flex flex-col text-[#FBFBFB] poppins-regular text-[2.4rem]">
                    <div className="">{item.title}</div>
                    <div className="text-wrap poppins-bold">{item.content}</div>
                    {item.extraContent ? <div className="poppins-bold">{item.extraContent}</div> : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex flex-wrap w-full justify-between my-[3rem] md:my-[8rem] md:flex-row">
          <div className="flex-col w-full md:w-[30%]">
            <div className=" md:w-[22.8rem] h-[5.2rem]">
              <img src={NavbarImg.LogoLight} alt="" className="w-full h-full" />
            </div>
            <div className="montserrat-medium text-[1.8rem] md:text-[1.4rem] my-[4rem] text-white">
              We are an agency specializing in web design & web automation since
              2013
            </div>
          </div>
          {/* explore section */}
          <div className="">
            <div className="montserrat-semibold text-[#41DF6B] text-[1.6rem] my-[1.5rem]">
              Explore
            </div>
            <div className="flex flex-col gap-y-[0.5rem]">
              {exploreData.map((item) => (
                <a
                  key={item.id}
                  className="montserrat-medium text-white text-[1.6rem]"
                  href={item.href}
                  // target={item.href ? "_blank" : "_self"}
                  rel={item.href ? "noopener noreferrer" : undefined}
                >
                  {item.title}
                </a>
              ))}
            </div>
          </div>
          {/* support section */}
          <div className="">
            <div className="montserrat-semibold text-[#41DF6B] text-[1.6rem] my-[1.5rem]">
              Support
            </div>
            <div className="flex flex-col gap-y-[0.5rem]">
              <a
                href="/contact-us"
                className="montserrat-medium text-white text-[1.6rem]"
              >
                CONTACT US
              </a>
              {/* <a
                href=""
                className="montserrat-medium text-white text-[1.6rem]"
              >
                PRIVACY POLICY
              </a> */}
            </div>
          </div>
          {/* social section */}
          <div className="">
            <div className="montserrat-semibold text-[#41DF6B] text-[1.6rem] my-[1.5rem]">
              Social
            </div>
            <div className="flex flex-col gap-y-[0.5rem]">
              {socialData.map((item) => (
                <a
                  key={item.id}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex"
                >
                  <div className="w-[2rem]">
                    <img src={item.icon} alt="" className="rounded-[0.2rem]" />
                  </div>
                  <div className="montserrat-medium text-[1.6rem] mx-[2rem] text-white">
                    {item.title}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterSection;

import React from 'react'

function LatestInsightSections() {
    const headerData = ['All', 'Blog', 'Whitepaper', 'Newsletter', 'Podcast', 'News Event']
  return (
    <div className="container-lg">
        <div className="flex flex-col my-[5rem] items-center justify-center">
            <div className="poppins-bold text-[7.5rem] text-center">
                Read Our Latest Insights
            </div>
            <div className="flex gap-x-[6rem] items-center justify-center w-full">
                {
                    headerData.map((d) => {
                        return (
                            <button className="text-[2rem] poppins-regular text-[#2666CF] mx-[2rem]" onClick={()=> {}}>
                                {d}
                            </button>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default LatestInsightSections


                {/* <button className="text-[2rem] poppins-regular text-[#2666CF] mx-[2rem]" onClick={()=> {}}>
                    All
                </button>
                <button className="text-[2rem] poppins-regular text-[#2666CF] mx-[2rem]" onClick={()=> {}}>
                    Blog
                </button>
                <button className="text-[2rem] poppins-regular text-[#2666CF] mx-[2rem]" onClick={()=> {}}>
                    Whitepaper
                </button>
                <button className="text-[2rem] poppins-regular text-[#2666CF] mx-[2rem]" onClick={()=> {}}>
                    Newsletter
                </button>
                <button className="text-[2rem] poppins-regular text-[#2666CF] mx-[2rem]" onClick={()=> {}}>
                    Podcast
                </button>
                <button className="text-[2rem] poppins-regular text-[#2666CF] mx-[2rem]" onClick={()=> {}}>
                    News Event
                </button> */}
import React from 'react';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import { ServiceImg } from '../../../static/constants/imageConstant';

const DataEngineering4 = () => {
    return (
        <div className="bg-[#F2F4F4]">
            <div className="container-lg mb-[8rem]">
                <SectionHeaders
                    contentPrimary={<>Our Data Governance and Analytics Services Include</>}
                    classes={`poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[7.2rem]`}
                />
                <div className="w-full h-full flex flex-col lg:flex-row gap-[5rem]">
                    <div className="w-full lg:w-[50%] h-full flex flex-col justify-start">
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Data Strategy & Consulting</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Collaborate with our experts to define a data strategy aligned with your business objectives, identifying key sources and establishing governance policies. 
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Data Integration & Management</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Seamless integration of disparate data sources to consolidate and centralize your data, with robust practices to maintain quality and consistency. 
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Data Analytics & Visualization</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Transform your data into actionable insights using advanced analytics and visualization techniques to drive informed decision-making. 
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Business Intelligence</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Empower your teams with self-service tools that allow for data exploration, report creation, and dashboard generation, making data-driven decisions accessible to everyone. 
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Data Governance & Compliance</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Implement frameworks to ensure data privacy, security, and compliance with regulations like GDPR and HIPAA, maintaining data lineage, auditing, and access controls. 
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Compliance & Ethical AI</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Implement responsible AI systems with built-in compliance to industry standards.
                                    </p>
                                </div>
                            </li>
                        </div>
                    </div>
                    <div className="w-full lg:w-[48%]">
                        <img
                            src={ServiceImg.aiservice08}
                            alt="img"
                            className="w-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataEngineering4
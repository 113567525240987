import React from 'react';
import CaseStudyLanding from '../../components/main/CaseStudyLanding/CaseStudyLanding';
import CasePage1 from '../../components/main/CasePage1/CasePage1';
import AboutUsForm from '../../components/main/AboutUsForm/AboutUsForm';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';

const CaseStudy = () => {
  return (
    <div>
      <CaseStudyLanding />
      <CasePage1 />
      <GetStartedDetailsForm />
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default CaseStudy
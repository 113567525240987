import React, { useState } from 'react'
import { EndToEndDevelopmentServicesImgs } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import ServicesCard from '../../common/cards/ServicesCard';

function ServicesSec({ isServicesAlt, subHeading }) {
    const [data] = useState([
        {   
            id: "product-engineering",
            title: `Product Engineering`,
            icon: EndToEndDevelopmentServicesImgs.EndToEndDevelopmentServicesProductEngImg,
            path: "/services-product",
            content: "Our Product Engineering Services offer end-to-end solutions to transform your ideas into  successful, market-ready products.",
            buttonContent: "Transform Your Idea",
        },
        {   
            id: "data-governance-and-analytics",
            title: `Data Engineering`,
            icon: EndToEndDevelopmentServicesImgs.EndToEndDevelopmentServicesDataEngImg,
            path: "/data-engineering",
            content: "Transform data into insights: clear governance and powerful analytics unlock your data's potential.",
            buttonContent: "Start Your Data Journey",
        },
        {   
            id: "blockchain",
            title: `AI ML Services`,
            icon: EndToEndDevelopmentServicesImgs.EndToEndDevelopmentServicesBlockChaninImg,
            path: "/ai_ml_services",
            content: "Leverage the power of AI and ML to transform data into actionable insights, automate processes, and drive smarter business decisions for sustainable growth.",
            buttonContent: "Transform with AI",
        },
        {   
            id: "cloud-engineering",
            title: `Cloud Engineering`,
            icon: EndToEndDevelopmentServicesImgs.EndToEndDevelopmentServicesCloudEngImg,
            path: "/cloud-engineering",
            content: "Scale and transform your business with pace and agility using our cloud engineering services.",
            buttonContent: "Unlock Your Cloud Potential",
        },
    ]);

    return (
        <div className='services py-[10rem] flex flex-col justify-center items-center container-lg'>
            {!isServicesAlt && 
                <SectionHeaders
                    contentPrimary="Service Offering"
                    classes="text-[6rem] poppins-extralight mb-[1.2rem]"
                />
            }
            <div className='container-lg grid lg:grid-cols-2 gap-[3.2rem] my-[5rem] items-center justify-center'>
                {
                    data.map((item) => {
                        return (
                            <ServicesCard
                                id={item.id}
                                title={item.title}
                                content={item.content}
                                icon={item.icon}
                                isServicesAlt={isServicesAlt}
                                path={item.path}
                                buttonContent={item.buttonContent}
                            />
                        )
                    })
                }
                <div className="absolute w-[14.5rem] h-[14.5rem] top-[69rem] right-0 bottom-0">
                </div>
            </div>
        </div>
    )
}

export default ServicesSec

import React from "react";
import { GoChevronDown, GoChevronRight } from "react-icons/go";
import SelectButton from "../../common/SelectButton/SelectButton";
import { Navigate } from "react-router";

const CareerPage3 = () => {
    return (
        <div className="bg-[#F2F4F4] px-[8rem] py-[4rem]">
            <div className="container-lg w-full h-full flex flex-col">
                <div className="w-full flex flex-col justify-center items-center text-center mb-8">
                    <h1 className="text-[6rem] text-[#000000] poppins-extralight mb-4">Trending Opportunities</h1>
                    <p className="w-full text-[2.2rem] text-[#8A929A] lg:px-[20rem] dm-sans-medium">We promise you an inclusive work
                        environment where you will fall in love with challenging as well as getting challenged.
                    </p>
                </div>

                <div className="w-[full] flex justify-center items-center py-[2rem]">
                    <div className="w-full flex justify-between items-center p-[0.8rem] bg-[#fff] rounded-[1.6rem] shadow-md mb-8">
                        <input
                            type="text"
                            className="flex justify-center bg-transparent text-white text-[2rem] px-[2rem] placeholder-[#fff] poppins-regular outline-none"
                        />
                        <SelectButton
                            title={'Search'}
                            bgColor="bg-[#00D47E]"
                            width="w-[18.6rem]"
                            textSize="text-[1.6rem]"
                            fontWeight="poppins-regular"
                            height="h-[4.7rem]"
                            onClick={() => { Navigate("#") }}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-4 gap-[4rem] poppins-regular text-gray-300 mb-6">
                    <div className="flex items-center justify-between border-b border-[#000000] pb-[1rem] px-[0.5rem] md:px-[1rem] lg:px-[2rem]">
                        <span className="text-[1.6rem] md:text-[2rem] lg:text-[2.2rem] text-[#000000] poppins-regular">Filter by</span>
                        <span className="cursor-pointer text-[#00D47E] text-[1.6rem] md:text-[2rem] lg:text-[2.4rem]"><GoChevronDown /></span>
                    </div>
                    <div className="flex items-center justify-between border-b border-[#000000] pb-[1rem] px-[0.5rem] md:px-[1rem] lg:px-[2rem]">
                        <span className="text-[1.6rem] md:text-[2rem] lg:text-[2.2rem] text-[#000000] poppins-regular">Function</span>
                        <span className="cursor-pointer text-[#00D47E] text-[1.6rem] md:text-[2rem] lg:text-[2.4rem]"><GoChevronDown /></span>
                    </div>
                    <div className="flex items-center justify-between border-b border-[#000000] pb-[1rem] px-[0.5rem] md:px-[1rem] lg:px-[2rem]">
                        <span className="text-[1.6rem] md:text-[2rem] lg:text-[2.2rem] text-[#000000] poppins-regular">Location</span>
                        <span className="cursor-pointer text-[#00D47E] text-[1.6rem] md:text-[2rem] lg:text-[2.4rem]"><GoChevronDown /></span>
                    </div>
                    <div className="flex items-center justify-between border-b border-[#000000] pb-[1rem] px-[0.5rem] md:px-[1rem] lg:px-[2rem]">
                        <span className="text-[1.6rem] md:text-[2rem] lg:text-[2.2rem] text-[#000000] poppins-regular">Experience</span>
                        <span className="cursor-pointer text-[#00D47E] text-[1.6rem] md:text-[2rem] lg:text-[2.4rem]"><GoChevronDown /></span>
                    </div>
                </div>

                <div className="space-y-[2rem] py-[2rem]">
                    {Array(4)
                        .fill(0)
                        .map((_, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between bg-[#525252] rounded-[1.6rem] px-6 py-6 shadow-lg"
                            >
                                <div className="flex">
                                    <p className="poppins-regular text-[#fff] text-[2.2rem]">Technical Project Manager</p>
                                </div>
                                <p className="text-[2.2rem] text-[#909090] text-center poppins-regular">Indore</p>
                                <span className="text-[#00D47E] text-[2.4rem] poppins-regular"><GoChevronRight /></span>
                            </div>
                        ))}
                </div>
                <div className='mt-[4rem] flex justify-center'>
                    <SelectButton
                        title={'Apply Now'}
                        bgColor="bg-[#00D47E]"
                        width="w-[17.4rem]"
                        textSize="text-[1.6rem]"
                        fontWeight="poppins-regular"
                        titleColor={`text-white hover:text-[#2B2B2B]`}
                        height="h-[4.7rem]"
                    />
                </div>
            </div>
        </div>
    );
};

export default CareerPage3;

import React from 'react';
import IndustryEdutech1 from '../../components/main/IndustryEdutech1/IndustryEdutech1';
import IndustryEdutech2 from '../../components/main/IndustryEdutech2/IndustryEdutech2';
import IndustryEdutech3 from '../../components/main/IndustryEdutech3/IndustryEdutech3';
import IndustryEdutech4 from '../../components/main/IndustryEdutech4/IndustryEdutech4';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import ClientTestimonial1 from '../../components/main/ClientTestimonial1/ClientTestimonial1';

const IndustryEdutech = () => {
    return (
        <div>
            <IndustryEdutech1 />
            <IndustryEdutech2 />
            <IndustryEdutech3 />
            <IndustryEdutech4 />
            <CaseStudy bgWhite />
            <ClientTestimonial1 />
            <FooterSection />
            <MiniFooter />
        </div>
    )
}

export default IndustryEdutech
import { all } from "redux-saga/effects";
import contactUsv1Saga from "./sagas/contactUsv1Saga";
import contactUsv2Saga from "./sagas/contactUsv2Saga";
import strapiTokenSaga from "./sagas/strapiTokenSaga";
import strapiBlogsSaga from "./sagas/strapiBlogsSaga";
import case_studiesSaga from "./sagas/case_studiesSaga";
import white_paperSaga from "./sagas/white_paperSaga";
import news_eventSaga from "./sagas/news_eventSaga";
import technologiesSaga from "./sagas/technologiesSaga";


export default function* rootSaga() {
    yield all([
        // add sagas here
        contactUsv1Saga(),
        contactUsv2Saga(),
        strapiTokenSaga(),
        strapiBlogsSaga(),
        case_studiesSaga(),
        white_paperSaga(),
        news_eventSaga(),
        technologiesSaga(),
    ])
}
import { put, call, takeLatest } from 'redux-saga/effects';
import { endpoints, strapi_base_url } from '../constants';
import { AxiosService } from '../services';
import { actionTypes } from '../types';
import { strapiBlogsFailure, strapiBlogsSuccess } from '../actions/strapiBlogsActions';

function* strapiBlogs({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: strapi_base_url,
            endPoint: endpoints.strapiBlogs + `?populate=*`,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("STRAPI_BLOGS_SUCCESS status :", response.status);
        console.log("STRAPI_BLOGS_SUCCESS data :", response.data);
        console.log("STRAPI_BLOGS_SUCCESS message :", response.message);
        console.log('====================================');
        yield put(strapiBlogsSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.message
        })
        )
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_BLOGS_FAILURE error :", error.response?.status);
        console.log("STRAPI_BLOGS_FAILURE message :", error.response?.data.statusmessage);
        console.log("STRAPI_BLOGS_FAILURE error :", error);
        console.log('====================================');
        yield put(strapiBlogsFailure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* strapiBlogsSaga() {
    yield takeLatest(actionTypes.STRAPI_BLOGS_REQUEST, strapiBlogs);
}

export default strapiBlogsSaga;

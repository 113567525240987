import React, { useEffect, useState } from 'react'
import { fontsData } from './fontsData'
import { TfiCheckBox, TfiLayoutWidthFull } from "react-icons/tfi";
import { useToast } from '../../hooks/useToast';

function FontHelper() {
    const Toast = useToast();
    const [selectedFont, setSelectedFont] = useState('');

    useEffect(() => {
        if(selectedFont.length > 0){
            navigator.clipboard.writeText(selectedFont)
                .then(() => {
                    Toast('Text copied to clipboard');
                }).catch((error) => {
                    console.error('Could not copy text: ', error);
                });
        }
    }, [selectedFont])

    return (
        <div className='bg-[#efefef]'>
            <div className='container-lg flex flex-col'>
                <h1 className='text-5xl underline text-center my-4 text-[#333] font-[600] uppercase'>Fonts Style Guide</h1>
                {fontsData?.map((font, i) =>{
                    return (
                        <div key={i} className={`flex justify-between items-center px-10 py-2 text-[3.6rem] rounded-[8px] cursor-pointer hover:bg-[#0ff] ${font.className}`} onClick={() => {setSelectedFont(font.className)}}>
                            <h5 className='overflow-hidden'>{font.title} -- {font.fontWeight}</h5>
                            <div className='flex gap-4 align-center'>
                                <h4 className='overflow-hidden'>{selectedFont == font.className ? "Copied" : "Click to copy Font className" }</h4>
                                {selectedFont == font.className ?
                                    <TfiCheckBox color='#f0f'/> 
                                    :
                                    <TfiLayoutWidthFull />
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default FontHelper

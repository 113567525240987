import React from 'react';
import ReactPlayer from 'react-player';
import { AboutUsImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';

const OurStory = () => {
    return (
        <div className='w-full h-full bg-[#F2F4F4] py-[5rem] mb-[5rem]'>
            <div className='container-lg w-full h-full flex flex-col'>
                <SectionHeaders
                    contentPrimary={'Our Story'}
                    classes={`poppins-extralight text-[6rem] items-start text-start mb-[1.2rem]`}
                    contentSecondary={`Infominez was founded in 2021 by a group of passionate entrepreneurs who saw an opportunity to bridge the gap between technology and business. Since then, we've grown from a small startup to a leading innovator in fintech, blockchain, and data analytics. Our journey has been marked by numerous milestones, including:`}
                    secondaryClasses={`poppins-medium text-[2.2rem] text-[#8A929A] mb-[6rem]`}
                />

                <div className='w-full flex flex-col lg:flex-row gap-[3rem] lg:gap-[6.4rem]'>
                    <div className='w-full lg:w-[48%] bg-black overflow-hidden shadow-lg'>
                        <ReactPlayer
                            url='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/TearsOfSteel.mp4'
                            light={<img src={AboutUsImg.aboutus2} alt='Video' className='w-[69rem] h-[46rem]' />}
                            controls
                            width={"100%"}
                            playing={true}
                            height={"100%"}
                        />
                    </div>

                    <div className="w-full lg:w-[50%] h-full flex flex-col justify-start">
                        <div className='w-full mt-[2.4rem] lg:mt-0 py-[4rem]'>
                            <li className="flex items-start gap-[2rem] mb-[1.8rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#06304C] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <p className="text-[#8A929A] text-[2.2rem] poppins-light mb-[1rem]">
                                    We're not just a company, we're a talent greenhouse. We cultivate homegrown heroes from Indore and beyond, nurturing their skills from local saplings to global shade-givers.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.8rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#06304C] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <p className="text-[#8A929A] text-[2.2rem] poppins-light mb-[1rem]">
                                    80% of our team: We believe in empowering the youth and tier-3 talent, giving them a shot at world-changing projects and real-world problem-solving.                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.8rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#06304C] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <p className="text-[#8A929A] text-[2.2rem] poppins-light mb-[1rem]">
                                    Our impact is brilliant! We've upskilled over …. individuals in just… years, and our training programs boast a 90% success rate, planting them in dream roles.                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#06304C] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <p className="text-[#8A929A] text-[2.2rem] poppins-light mb-[1rem]">
                                    We're building a vibrant ecosystem of training programs, mentorship, and collaboration.                                    </p>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurStory;

import React from 'react';
import { CaseStudyImg } from '../../../static/constants/imageConstant';

const CaseStudyLanding = () => {
  return (
    <div className='w-full h-full'>
      <div className='relative w-full min-h-max'>
        <img src={CaseStudyImg.casestudy1} alt="About Us" className="w-full h-full" />
        <div className='absolute inset-0 flex items-center justify-center'>
          <h1 className='text-[9rem] poppins-extralight text-white text-center'>Case Study</h1>
        </div>
      </div>
    </div>
  )
}

export default CaseStudyLanding
import { actionTypes } from "../types";

const initialState = {
    loading: false,
    status: '',
    message: '',
    data: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STRAPI_BLOGS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.STRAPI_BLOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.data?.message,
                data: action?.payload?.data
            }
        case actionTypes.STRAPI_BLOGS_FAILURE:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
            }
        case actionTypes.STRAPI_BLOGS_RESET:
            return {
                ...state,
                status: '',
                message: ''
            }
        case actionTypes.STRAPI_BLOGS_RESET_ALL:
            return initialState
        default:
            return state;
    }
};
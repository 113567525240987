import { actionTypes } from '../types';

export const technologies = (payload, callback) => ({
    type: actionTypes.TECHNOLOGIES_REQUEST,
    payload,
    callback
});

export const technologiesSuccess = (payload) => ({
    type: actionTypes.TECHNOLOGIES_SUCCESS,
    payload,
});

export const technologiesFailure = (payload) => ({
    type: actionTypes.TECHNOLOGIES_FAILURE,
    payload,
});

export const technologiesReset = () => ({
    type: actionTypes.TECHNOLOGIES_RESET,
});

export const technologiesResetAll = () => ({
    type: actionTypes.TECHNOLOGIES_RESET_ALL,
}); 
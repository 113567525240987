import { actionTypes } from '../types';

const initialState = {
    strapiToken: '',
}


export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_STRAPI_TOKEN:
            return {
                ...state,
                strapiToken: action.payload
            }
        default:
            return state;
    }
}
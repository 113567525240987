import React from 'react';
import './HomeLandingSections.css';
import { LandingImgs } from '../../../static/constants/imageConstant';
import backgroundGif from '../../../static/assets/img/backgrounds/hero-landing-bg1.mkv'; 
import ReactPlayer from 'react-player'; // Import ReactPlayer
import { useNavigate } from 'react-router-dom';
import BtnOutlined from '../BtnOutlined/BtnOutlined';

function HomeLandingSections({ heading, subHeading, actionTitle }) {
    const navigate = useNavigate();

    return (
        <div className='home-landing-sections relative w-full'>
            <div className='home-landing-sections-bg w-full h-[98rem] sm:h-full'>
                <ReactPlayer
                    url={backgroundGif}
                    playing
                    loop
                    muted
                    width="100%"
                    height="100%"
                    className="h-full w-full object-cover"
                />
            </div>
            <div className='home-landing-sections-content flex flex-col container-lg absolute justify-between items-center md:items-start'>
                <div className='text-[#FFFFFF] poppins-extralight text-center md:text-start lg:text-[6rem] text-[3rem] my-[1rem] w-full'>
                    Transform Your Business with Intelligent<br />
                    AI & ML Solutions
                </div>
                <div className='poppins-light lg:text-[3.2rem] text-[1.8rem] text-white lg:mt-[3.3rem] w-full text-center md:text-start'>
                    Drive Innovation and Growth with AI-Powered Solutions
                </div>
                <div className="h-[8rem]"></div>
                <BtnOutlined
                    title="Transform Your Business with AI & ML"
                    width="w-[55.6rem]"
                    textSize="text-[2.2rem]"
                    classNames={"border-2 rounded-full"}
                    titleColor={`text-white hover:text-[#0E0731]`}
                    borderColor="border-[#00D47E]"
                    bgColor={`bg-inherit text-white hover:bg-[#00D47E]`}
                    fontWeight={"poppins-medium"}
                    ButtonHeight={"h-[8rem]"}
                    hasArrow
                    onClick={() => { navigate("/contact-us") }}
                />
            </div>
        </div>
    );
}

export default HomeLandingSections;



// import React from 'react';
// import ReactPlayer from 'react-player';
// import './HomeLandingSections.css';
// import { useNavigate } from 'react-router-dom';
// import BtnOutlined from '../BtnOutlined/BtnOutlined';
// import backgroundGif from '../../../static/assets/img/backgrounds/hero-landing-bg1.mkv';

// function HomeLandingSections() {
//   const navigate = useNavigate();

//   return (
//     <div className="home-landing-sections relative w-full h-[98rem] sm:h-screen overflow-hidden">
//       {/* Video Background */}
//       <div className="home-landing-sections-bg absolute inset-0 -z-10">
//         <ReactPlayer
//           url={backgroundGif}
//           className="react-player"
//           playing
//           loop
//           muted
//           width="100%"
//           height="100%"
//           style={{ position: 'absolute', top: 0, left: 0, objectFit: 'cover' }}
//         />
//       </div>

//       {/* Content Section */}
//       <div className="home-landing-sections-content flex flex-col container-lg absolute inset-0 justify-center items-center md:items-start px-4">
//         <h1 className="text-white poppins-extralight text-center md:text-left lg:text-[6rem] text-[3rem] my-[1rem]">
//           Transform Your Business with Intelligent<br />
//           AI & ML Solutions
//         </h1>
//         <p className="poppins-light lg:text-[3.2rem] text-[1.8rem] text-white lg:mt-[3.3rem] w-full text-center md:text-left">
//           Drive Innovation and Growth with AI-Powered Solutions
//         </p>
//         <BtnOutlined
//           title="Transform Your Business with AI & ML"
//           width="w-[55.6rem]"
//           textSize="text-[2.2rem]"
//           classNames="border-2 rounded-full"
//           titleColor="text-white hover:text-[#0E0731]"
//           borderColor="border-[#00D47E]"
//           bgColor="bg-inherit text-white hover:bg-[#00D47E]"
//           fontWeight="poppins-medium"
//           ButtonHeight="h-[8rem]"
//           hasArrow
//           onClick={() => navigate('/contact-us')}
//         />
//       </div>
//     </div>
//   );
// }

// export default HomeLandingSections;

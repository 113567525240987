import React from 'react';
import Services from '../../components/main/Services/Services';
import TransformYourVision from '../../components/main/TransformYourVision/TransformYourVision';
import OurProduct from '../../components/main/OurProduct/OurProduct';
import WhyInfominez from '../../components/main/WhyInfominez/WhyInfominez';
import Insights from '../../components/main/Insights/Insights';
import ClientTestimonial from '../../components/main/ClientTestimonial/ClientTestimonial';
import AboutUsForm from '../../components/main/AboutUsForm/AboutUsForm';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';

const ServicesPage = () => {
  return (
    <div>
        <Services />
        <TransformYourVision />
        <OurProduct />
        <WhyInfominez />
        <CaseStudy />
        <ClientTestimonial />
        <Insights />
        <GetStartedDetailsForm bgWhite/>
        <FooterSection />
        <MiniFooter />
    </div>
  )
}

export default ServicesPage
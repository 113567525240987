import React from 'react';
import DataEngineering1 from '../../components/main/DataEngineering1/DataEngineering1';
import DataEngineering2 from '../../components/main/DataEngineering2/DataEngineering2';
import DataEngineering3 from '../../components/main/DataEngineering3/DataEngineering3';
import DataEngineering4 from '../../components/main/DataEngineering4/DataEngineering4';
import DataEngineering5 from '../../components/main/DataEngineering5/DataEngineering5';
import DataEngineering6 from '../../components/main/DataEngineering6/DataEngineering6';
import Insights from '../../components/main/Insights/Insights';
import ClientTestimonial from '../../components/main/ClientTestimonial/ClientTestimonial';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';

const DataEngineering = () => {
    return (
        <div>
            <DataEngineering1 />
            <DataEngineering2 />
            <DataEngineering3 />
            <DataEngineering4 />
            <DataEngineering5 />
            <CaseStudy bgWhite />
            <Insights />
            <ClientTestimonial />
            <GetStartedDetailsForm bgWhite />
            <FooterSection />
            <MiniFooter />
        </div>
    )
}

export default DataEngineering
import React from 'react';
import ServicesProductPage from '../../components/main/ServicesProductPage/ServicesProductPage';
import ServicesProductPage1 from '../../components/main/ServicesProductPage1/ServicesProductPage1';
import ServicesProductPage2 from '../../components/main/ServicesProductPage2/ServicesProductPage2';
import ServicesProductPage3 from '../../components/main/ServicesProductPage3/ServicesProductPage3';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';
import ClientTestimonial1 from '../../components/main/ClientTestimonial1/ClientTestimonial1';
import Insights from '../../components/main/Insights/Insights';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';

const ServicesProduct = () => {
  return (
    <div>
        <ServicesProductPage />
        <ServicesProductPage1 />
        <ServicesProductPage2 />
        <ServicesProductPage3 />
        <CaseStudy bgWhite />
        <ClientTestimonial1 />
        <Insights />
        <GetStartedDetailsForm bgWhite/>
        <FooterSection />
        <MiniFooter />
    </div>
  )
}

export default ServicesProduct
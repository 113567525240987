import React from 'react';
import "./ServicesProductPage3.css";
import { ServicesSectionPageImages } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';

const ServicesProductPage3 = () => {
    const scrollToSection = () => {
        const element = document.getElementById('GetStartedDetailsForm');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
    return (
        <div className="bg-[#F2F4F4]">
            <div className="container-lg mb-[8rem]">
                <SectionHeaders
                    contentPrimary={<>Why Partner with Infominez<br />for End-to-End Success</>}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center my-[7.2rem]`}
                />
                <div className="w-full h-full flex flex-col lg:flex-row gap-[5rem] items-center">
                    <div className="w-full lg:w-[50%] h-full flex flex-col justify-start">
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Expertise and Experience</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Leverage our extensive industry knowledge to ensure project success and minimize risks.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Cost-Effective Solutions</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Benefit from efficient development and competitive pricing that align with your budget and goals.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Innovative Solutions</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Access proven technologies and innovative strategies that help keep your product competitive and future-ready.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Adaptive Project Delivery</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Experience agile project management that quickly adapts to evolving requirements while staying on time and within budget.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Collaboration & Transparency</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Enjoy open communication and a collaborative approach that keeps your vision at the forefront.
                                    </p>
                                </div>
                            </li>
                        </div>
                        <div className="mt-[4rem] px-[3rem]">
                            <SelectButton
                                title={'Discover How We Can Help You Succeed'}
                                bgColor="bg-[#00D47E]"
                                width="w-[41.6rem]"
                                textSize="text-[1.6rem]"
                                fontWeight="poppins-regular"
                                titleColor={`text-white hover:text-[#2B2B2B]`}
                                height="h-[4.7rem]"
                                onClick={scrollToSection}
                            />
                        </div>
                    </div>
                    <div className="w-full lg:w-[48%]">
                        <img
                            src={ServicesSectionPageImages.PartnerWithInfominezImage}
                            alt="img"
                            className="w-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesProductPage3;
import React from 'react';
import "./IndustryPage1.css";
import BtnOutlined from '../../common/BtnOutlined/BtnOutlined';

const IndustryPage1 = () => {
    return (
        <div>
            <div className="industry w-full h-[75vh] flex flex-col items-center justify-center">
                <div className="container-lg flex flex-col items-center justify-center text-center text-white px-4">
                    <h1 className="text-[2.5rem] sm:text-[3rem] lg:text-[5.6rem] poppins-light leading-snug overflow-hidden">
                        Pioneering the Digital Finance Revolution
                    </h1>
                    <p className="text-[2.4rem] md:text-[2.7rem] lg:text-[3.2rem] poppins-light mt-[1rem]">
                        Experience the Future of Finance with Seamless, Secure, and Transformative Solutions
                    </p>
                    <div className='flex justify-center mt-[4rem]'>
                        <BtnOutlined
                            title="Learn More"
                            width="w-[29.4rem]"
                            textSize="text-[2.2rem]"
                            classNames={"border-2 rounded-full"}
                            titleColor={`text-white hover:text-[#0E0731]`}
                            borderColor="border-[#00D47E]"
                            bgColor={`bg-inherit text-white hover:bg-[#00D47E]`}
                            fontWeight={"poppins-medium"}
                            ButtonHeight={"h-[8rem]"}
                            hasArrow
                        //onClick={() => { navigate("/contact-us") }}
                        />
                    </div>
                </div>
            </div>
            <div className="container-lg bg-white text-center">
                <p className="text-[#8A929A] text-[1.6rem] sm:text-[2rem] md:text-[2.4rem] py-[9rem]">
                    Revolutionize your financial operations with Infominez. Our deep-rooted expertise and advanced technology solutions are designed to enhance customer experiences, optimize processes, and drive growth. We offer a comprehensive suite of services, including fintech software development, payment processing, blockchain, regulatory compliance, data analytics, and robust security measures. Partner with us to stay ahead in the competitive fintech landscape.
                </p>
            </div>
        </div>
    )
}

export default IndustryPage1
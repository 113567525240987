import React from 'react';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';
import { ServicesSectionPageImages } from '../../../static/constants/imageConstant';

const Ai_Ml_Services5 = () => {
    return (
        <div className="bg-[#F2F4F4] mt-[6rem]">
            <div className="container-lg mb-[8rem]">
                <SectionHeaders
                    contentPrimary={<> Why Choose Infominez for AI & <br/>ML Excellence? </>}
                    classes={`poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[7.2rem]`}
                />
                <div className="w-full h-full flex flex-col lg:flex-row gap-[4.5rem] items-start">
                    <div className="w-full lg:w-[48%]">
                        <div className='w-full'>
                        <img
                            src={ServicesSectionPageImages.Excellence}
                            alt="img"
                            className="w-full "
                        />
                        </div>
                    </div>
                    <div className="w-full lg:w-[50%] h-full flex flex-col justify-start">
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Expertise in AI & ML</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Leverage our deep technical knowledge and experience in delivering AI solutions. 
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Innovative Problem Solving</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Create cutting-edge solutions tailored to your unique business challenges. 
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Scalable and Flexible Systems</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Build adaptable AI infrastructure to meet your future needs. 
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Accelerated Insights</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Achieve faster time-to-value with efficient deployment and iteration.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Ethical AI Practices</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Ensure responsible and compliant AI implementation. 
                                    </p>
                                </div>
                            </li>
                        </div>
                        {/* <div className="mt-[4rem] px-[3rem]">
                            <SelectButton
                                title={'Explore Our AI and ML Services'}
                                bgColor="bg-[#00D47E]"
                                width="w-[41.6rem]"
                                textSize="text-[1.6rem]"
                                fontWeight="poppins-regular"
                                titleColor={`text-white hover:text-[#2B2B2B]`}
                                height="h-[4.7rem]"
                            />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ai_Ml_Services5
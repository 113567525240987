import { put, call, takeLatest } from 'redux-saga/effects';
import { endpoints, strapi_base_url } from '../constants';
import { AxiosService } from '../services';
import { actionTypes } from '../types';
import { technologiesFailure, technologiesSuccess } from '../actions/technologiesActions';

function* technologies({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: strapi_base_url,
            endPoint: endpoints.technologies + `?populate[logo]=*`,
            data: payload,
            isAuth: true,
        });
        console.log('====================================');
        console.log("TECHNOLOGIES_SUCCESS status :", response.status);
        console.log("TECHNOLOGIES_SUCCESS data :", response.data);
        console.log("TECHNOLOGIES_SUCCESS message :", response.message);
        console.log('====================================');
        yield put(technologiesSuccess({
            status: response?.status,
            data: response?.data?.data,
            message: response?.message
        })
        )
        if (callback) {
            callback(
                response?.status,
                response?.response,
                response?.message
            ); // Pass the response data to the callback
        }
    } catch (error) {
        console.log('====================================');
        console.log("TECHNOLOGIES_FAILURE error :", error.response?.status);
        console.log("TECHNOLOGIES_FAILURE message :", error.response?.message);
        console.log("TECHNOLOGIES_FAILURE error :", error);
        console.log('====================================');
        yield put(technologiesFailure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* technologiesSaga() {
    yield takeLatest(actionTypes.TECHNOLOGIES_REQUEST, technologies);
}

export default technologiesSaga;

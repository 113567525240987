import { put, call, takeLatest } from 'redux-saga/effects';
import { endpoints, strapi_base_url } from '../constants';
import { AxiosService } from '../services';
import { actionTypes } from '../types';
import { setStrapiToken } from '../actions/utilsActions';
import { strapiTokenFailure, strapiTokenSuccess } from '../actions/strapiTokenActions';

function* strapiToken({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: strapi_base_url,
            endPoint: endpoints.strapiToken,
            data: payload,
            isAuth: false,
        });

        console.log('====================================');
        console.log("STRAPI_TOKEN_SUCCESS status :", response.status);
        console.log("STRAPI_TOKEN_SUCCESS data :", response.data);
        console.log("STRAPI_TOKEN_SUCCESS message :", response.message);
        console.log('====================================');
        yield put(strapiTokenSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.message
        })
        )
        yield put(setStrapiToken(response?.data.jwt));
    } catch (error) {
        console.log('====================================');
        console.log("STRAPI_TOKEN_FAILURE error :", error.response?.status);
        console.log("STRAPI_TOKEN_FAILURE message :", error.response?.data.statusmessage);
        console.log("STRAPI_TOKEN_FAILURE error :", error);
        console.log('====================================');
        yield put(strapiTokenFailure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* strapiTokenSaga() {
    yield takeLatest(actionTypes.STRAPI_TOKEN_REQUEST, strapiToken);
}

export default strapiTokenSaga;
import React from 'react'

const MiniFooter = () => {
  return (
    <div className="flex justify-center items-center bg-[#14191C] h-[9rem] ">
        <div className="montserrat-medium text-[#F5F5F5] text-center text-[1.6rem]">
            ©2025 INFOMINEZ All Rights Reserved.
        </div>
    </div>
  )
}

export default MiniFooter
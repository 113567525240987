import React from 'react'
import ContactUs2 from '../../components/main/ContactUs2/ContactUs2'
import ContactUs1 from '../../components/main/ContactUs1/ContactUs1'
import FooterSection from '../../components/common/FooterSection/FooterSection'
import MiniFooter from '../../components/common/MiniFooter/MiniFooter'

const ContactUs = () => {
  return (
    <div>
      <ContactUs2 />
      <ContactUs1 />
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default ContactUs
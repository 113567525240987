import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import SelectButton from "../../common/SelectButton/SelectButton";


const CloudEngineering5 = () => {
  const scrollToSection = () => {
    const element = document.getElementById('GetStartedDetailsForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const dataPoints = [
    {
      title: "Scalable Solutions",
      description:
        "Adapt to your business’s growing needs without limitations. ",
    },
    {
      title: "Agility and Flexibility",
      description:
        "Innovate faster and bring new products to market with our flexible cloud infrastructures.",
    },
    {
      title: "Cost-Effective ",
      description:
        "Save on infrastructure costs with optimized cloud solutions.",
    },
    {
      title: "Enhanced Security ",
      description:
        "Protect critical business data with enterprise-grade cloud security. ",
    },
    {
      title: "Optimized Performance ",
      description:
        "Ensure seamless, high-performance operations with our cloud technologies.",
    },
    // {
    //   title: "Tailored Solutions",
    //   description:
    //     "We work with businesses across industries, delivering solutions specific to your business needs.",
    // },
  ];

  return (
    <div className="w-full h-full bg-[#F2F4F4] py-[5rem] mb-[8rem]">
      <div className="container-lg mx-auto flex flex-col">
        <div className="mx-auto flex flex-col items-center text-center">
        <SectionHeaders
          contentPrimary={
            <>
              How Cloud Excellence is Your Pathway to Success
              Accelerate your business transformation with scalable, secure, and cost-effective cloud solutions.
            </>
          }
          classes="poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] text-center my-[3.7rem]"
        />

        <p className="text-[1.8rem] md:text-[2.5rem] text-[#8A929A] poppins-regular mt-[2rem]">
          Our cloud services are designed to deliver agility, innovation, and cost efficiency.
          Whether you’re in Banking, FinTech, Retail, EduTech, or another industry, our cloud-first
          approach ensures that your business stays competitive in today’s digital world.
        </p>
        </div>

        <div className="text-left">
          <h1 className="text-[#000000] text-[4.5rem] text-start poppins-medium py-[3.9rem]">
            Benefits of Our Cloud Solutions
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] md:gap-[4rem] mt-[3.9rem]">
          {dataPoints.map((point, index) => (
            <div
              key={index}
              className="flex items-start gap-[1rem]"
            >
              <div className="w-[5%]"><div className="w-[1.2rem] h-[1.2rem] rounded-full bg-[#2666CF] mt-[1rem]" /></div>
              <div className="w-[94%] text-start ">
                <h3 className="text-[2.8rem] poppins-medium text-[#3C436A] mb-[0.8rem]">
                  {point.title}
                </h3>
                <p className="text-[2.2rem] text-[#8A929A] poppins-regular">
                  {point.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className='py-[4rem] flex justify-center items-center'>
          <SelectButton
            title={'Start Transforming Your Business Today '}
            bgColor="bg-[#00D47E]"
            width="w-[37.9rem]"
            textSize="text-[1.6rem]"
            fontWeight="poppins-regular"
            titleColor={`text-white hover:text-[#2B2B2B]`}
            height="h-[4.7rem]"
            onClick={scrollToSection}
          />
        </div>
      </div>
    </div>
  );
};

export default CloudEngineering5;
import React from 'react';
import './BlogAPage1.css';
import backgroundImage from "../../../static/img/blogb.png";

const BlogAPage1 = () => {
  return (
    <div className='blog-a w-full h-[60vh] flex flex-col'style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className='container-lg w-full h-full flex flex-col my-auto justify-center'>
        <h1 className="text-[2.5rem] sm:text-[3rem] lg:text-[6rem] text-[#fff] text-center mt-[2rem] lg:mt-[4rem] poppins-extralight overflow-hidden">
          From Your Idea to a Market-Ready Product -
        </h1>
        <p className="text-[2rem] sm:text-[2.5rem] lg:text-[6rem] text-[#fff] text-center poppins-extralight overflow-hidden">
          Faster, Better, Stronger
        </p>
      </div>
    </div>
  )
}

export default BlogAPage1
import React from "react";
import { IndustryImg } from "../../../static/constants/imageConstant";
import SectionHeaders from "../../common/Headers/SectionHeaders";

const IndustryPage4 = () => {
    const services = [
        {
            title: "Expertise in Fintech",
            description:
                "Bespoke software solutions for mobile apps, web platforms, and advanced analytics.",
            imgSrc: IndustryImg.industry6,
        },
        {
            title: "Payment Processing Solutions",
            description:
                "Secure and seamless transaction processing for thriving digital payment ecosystems.",
            imgSrc: IndustryImg.industry7,
        },
        {
            title: "Regulatory Compliance",
            description:
                "Comprehensive solutions to meet all legal requirements and industry standards.",
            imgSrc: IndustryImg.industry8,
        },
        {
            title: "Data Analytics",
            description:
                "Extract valuable insights from financial data with our data-driven services.",
            imgSrc: IndustryImg.industry9,
        },
        {
            title: "Blockchain and Cryptocurrency",
            description:
                "Expert guidance and development services to stay ahead in the blockchain and cryptocurrency space.",
            imgSrc: IndustryImg.industry10,
        },
        {
            title: "Security & Fraud Prevention",
            description:
                "Implement added cooling periods, multiple authentications, and PPI limits monitoring.",
            imgSrc: IndustryImg.industry11,
        },
    ];

    return (
        <div className="bg-[#F2F4F4] py-[6rem]">
            <div className="container-lg w-full flex flex-col ">
                <SectionHeaders
                    contentPrimary={"Service Offerings"}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
                />
                <div className="mt-[6rem] mb-[4rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative">
                    <div className="hidden lg:block absolute inset-x-0 top-1/2 transform -translate-y-1/2 border-t-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-y-0 left-1/3 transform -translate-x-1/2 border-l-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-y-0 left-2/3 transform -translate-x-1/2 border-l-[.1rem] border-[#00234480]"></div>

                    {services.map((service, index) => (
                        <div
                            key={index}
                            className="flex flex-col justify-center items-center gap-[2rem] px-[2rem] py-[2rem] "
                        >
                            <div className="w-[4.3rem] h-[4.3rem] flex justify-center items-center">
                                <img
                                    src={service.imgSrc}
                                    alt={service.title}
                                    className="w-full h-full"
                                />
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h3 className="text-[2.8rem] poppins-semibold text-center text-[#3C436A]">
                                    {service.title}
                                </h3>
                                <p className="mt-2 text-[2.2rem] poppins-light text-center text-[#707F96]">{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default IndustryPage4;

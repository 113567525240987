import React from 'react';
import { IndustryImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';

const IndustryEdutech4 = () => {
    const services = [
        {
            title: "Learning Management Systems (LMS)",
            description:
                "Centralized platform for course management, content delivery, and assessment. Personalized learning paths based on student performance. Seamless integration with other educational tools.  ",
            imgSrc: IndustryImg.industry6,
        },
        {
            title: "E-Learning Platforms",
            description:
                "Engaging and interactive online courses with multimedia content. Gamification elements to enhance learner motivation. Mobile-optimized for flexible learning.  ",
            imgSrc: IndustryImg.industry10,
        },
        {
            title: "Virtual Classroom Solutions",
            description:
                "Real-time interactive learning experiences with live video, chat, and whiteboard features. Collaborative tools for group work and discussions. Integration with LMS for seamless course delivery.  ",
            imgSrc: IndustryImg.industry11,
        },
        {
            title: "Edutainment Apps",
            description:
                "Fun and engaging learning experiences for young learners. Aligned with educational standards and curriculum. Gamified elements to make learning enjoyable.  ",
            imgSrc: IndustryImg.industry7,
        },
        {
            title: "Data Analytics and Insights",
            description:
                "Powerful analytics tools to measure student performance and identify trends. Actionable insights to inform instructional decisions and improve outcomes. Data privacy and security compliance. ",
            imgSrc: IndustryImg.industry9,
        },
        {
            title: "Security & Fraud Prevention",
            description:
                "Implement added cooling periods, multiple authentications, and PPI limits monitoring. ",
            imgSrc: IndustryImg.industry9,
        },

    ];

    return (
        <div className="bg-[#F2F4F4] py-[6rem]">
            <div className="container-lg w-full flex flex-col ">
                <SectionHeaders
                    contentPrimary={"Service Offerings"}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
                />
                <div className="mt-[6rem] mb-[4rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative">
                    <div className="hidden lg:block absolute inset-x-0 top-[53%] transform -translate-y-1/2 border-t-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-y-0 left-1/3 transform -translate-x-1/2 border-l-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-y-0 left-2/3 transform -translate-x-1/2 border-l-[.1rem] border-[#00234480]"></div>

                    {services.map((service, index) => (
                        <div className='flex flex-col justify-items-start'>
                            <div
                                key={index}
                                className="flex flex-col justify-center items-center gap-[2rem] px-[1rem] py-[2rem] ">
                                <div className="w-[4.3rem] h-[4.3rem] ">
                                    <img
                                        src={service.imgSrc}
                                        alt={service.title}
                                        className="w-full h-full"
                                    />
                                </div>
                                <div className="flex flex-col justify-center items-center">
                                    <h3 className="text-[2.8rem] poppins-semibold text-center text-[#3C436A]">
                                        {service.title}
                                    </h3>
                                    <p className="mt-2 text-[2.2rem] poppins-light text-center text-[#707F96]">{service.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='flex justify-center'>
                    <SelectButton
                        title={'Contact Us Now!'}
                        bgColor="bg-[#00D47E]"
                        width="w-[29.8rem]"
                        textSize="text-[1.8rem]"
                        fontWeight="poppins-regular"
                        titleColor={`text-white hover:text-[#2B2B2B]`}
                        height="h-[4.7rem]"
                    />
                </div>
            </div>
        </div>
    );
};


export default IndustryEdutech4
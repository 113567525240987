import { actionTypes } from '../types';

export const case_studies = (payload, callback) => ({
    type: actionTypes.CASE_STUDIES_REQUEST,
    payload,
    callback
});

export const case_studiesSuccess = (payload) => ({
    type: actionTypes.CASE_STUDIES_SUCCESS,
    payload,
});

export const case_studiesFailure = (payload) => ({
    type: actionTypes.CASE_STUDIES_FAILURE,
    payload,
});

export const case_studiesReset = () => ({
    type: actionTypes.CASE_STUDIES_RESET,
});

export const case_studiesResetAll = () => ({
    type: actionTypes.CASE_STUDIES_RESET_ALL,
}); 
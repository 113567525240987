import React from 'react';
import IndustryPage1 from '../../components/main/IndustryPage1/IndustryPage1';
import IndustryPage2 from '../../components/main/IndustryPage2/IndustryPage2';
import IndustryPage3 from '../../components/main/IndustryPage3/IndustryPage3';
import IndustryPage4 from '../../components/main/IndustryPage4/IndustryPage4';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';
import ClientTestimonial1 from '../../components/main/ClientTestimonial1/ClientTestimonial1';

const IndustryPage = () => {
  return (
    <div>
      <IndustryPage1 />
      <IndustryPage2 />
      <IndustryPage3 />
      <IndustryPage4 />
      <CaseStudy bgWhite/>
      <ClientTestimonial1 />
      <GetStartedDetailsForm bgWhite/>
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default IndustryPage
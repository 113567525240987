import React, { useEffect } from 'react'
import PrimaryLayout from '../../components/layouts/PrimaryLayout/PrimaryLayout'
import HomeLandingSections from '../../components/common/LandingSection/HomeLandingSections'
import ServicesSec from '../../components/main/ServiceSec/ServiceSec'
import IndustriesSec from '../../components/main/IndustriesSec/IndustriesSec'
import ProcessModel from '../../components/main/ProcessModel/ProcessModel'
import AboutUsSection from '../../components/main/AboutUsSection/AboutUsSection'
import WhyInfominezSec from '../../components/main/WhyInfominezSec/WhyInfominezSec'
import CaseStudy from '../../components/main/CaseStudy/CaseStudy'
import ClientTestimonial from '../../components/main/ClientTestimonial/ClientTestimonial'
import LatestInsightSections from '../../components/main/LatestInsightSections/LatestInsightSections'
import OurClientsSection from '../../components/main/OurClientsSection/OurClientsSection'
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm'
import FooterSection from '../../components/common/FooterSection/FooterSection'
import Insights from '../../components/main/Insights/Insights'
import MiniFooter from '../../components/common/MiniFooter/MiniFooter'
import { useDispatch, useSelector } from 'react-redux'
import { strapiToken } from '../../Store/actions/strapiTokenActions'

function Home() {

    const dispatch = useDispatch();

    const utilReducers = useSelector((state) => state.utilReducers);

    useEffect(() => {
        dispatch(strapiToken({
            "identifier": "api1@test.com",
            "password": "Test123&1997"
        }))
    }, []);

    console.log("utilReducers strapiToken :", utilReducers?.strapiToken);



    return (
        <div className='home'>
            <PrimaryLayout
                showNavbar={true}
                showFooterMain={true}
                ShowFooterMini={true}
                // ShowCaseStudy={true}
                // showLatestInsights={true}
                showContactUs={true}
            >
                <HomeLandingSections
                    heading={`Data Governance &\nAnalytics Services`}
                    subHeading={`Data Governance Unleashed. Analytics Services Empowering \nBusiness Evolution`}
                    actionTitle={`Get a Quote`}
                />
                <ServicesSec
                    isServicesAlt={false}
                    subHeading={"Infominez: Empowering Competitive Edge through Technology"}
                />
                <IndustriesSec />
                <ProcessModel />
                <AboutUsSection />
                <WhyInfominezSec />
                <ClientTestimonial />
                <Insights />
                <CaseStudy />
                <OurClientsSection />
                <GetStartedDetailsForm />
                <FooterSection />
                <MiniFooter />
            </PrimaryLayout>
        </div>
    )
}

export default Home

// // Marquee.js
import React from 'react';
import IIHANO from '../../../static/assets/img/logos/client-logo-1.png';
import nict from '../../../static/assets/img/logos/client-logo-2.png';
import Mayaa from '../../../static/assets/img/logos/client-logo-3.png';
import Paul from '../../../static/assets/img/logos/client-logo-4.jpg';
import PaidPromo from '../../../static/assets/img/logos/client-logo-5.png';
import kriti from '../../../static/assets/img/logos/Kriti.png';
import indaz from '../../../static/assets/img/logos/Inadz.png';
import rackbank from '../../../static/assets/img/logos/rackbank.png';
import Electronic from '../../../static/assets/img/logos/Electronic.png';
import FitZone from '../../../static/assets/img/logos/FitZone.png';
import Investmentor from '../../../static/assets/img/logos/Investmentor.jpg';
import andbuy from '../../../static/assets/img/logos/andbuy.png';
import chameleon from '../../../static/assets/img/logos/chameleon.png';

const Marquee = () => {
    const images = [
        { src: IIHANO, width: '27rem', height: '14rem', title: 'Indore Cancer Foundtion', link: 'https://indorecancerfoundation.org/' },
        { src: nict, width: '33.4rem', height: '7.6rem', title: <div>National Institute of Information<br /> <p className='text-[1.5rem]'>and Communications Technology</p></div>, link: 'https://nicttpl.com/' },
        { src: Mayaa, width: '20.2rem', height: '7rem', title: 'Mayaa Money', link: 'https://mayaa.money/' },
        { src: Paul, width: '28.6rem', height: '8.5rem', title: 'Paul Merchants Limited', link: 'https://www.paulmerchants.net/' },
        { src: PaidPromo, width: '25rem', height: '11rem', title: 'Paid Promo', link: 'https://example.com/paidpromo' },
        { src: kriti, width: '24.2rem', height: '10rem', title: 'kriti Industries', link: 'https://kritiindustries.com/' },
        { src: indaz, width: '28.2rem', height: '10rem', title: 'Indaz', link: 'https://inadz.com/' },
        { src: rackbank, width: '28.2rem', height: '13rem', title: 'Rack Bank', link: 'https://www.rackbank.com/' },
        { src: Electronic, width: '33.4rem', height: '14rem', title: <div>Electronic Payment & Services</div>, link: 'https://electronicpay.in/' },
        { src: FitZone, width: '40rem', height: '14rem', title: 'FitZone', link: 'https://fit.zone/' },
        { src: Investmentor, width: '20.2rem', height: '14rem', title: 'Investmentor', link: 'https://investmentor.com.au/' },
        { src: andbuy, width: '20.2rem', height: '14rem', title: 'andbuy', link: 'https://example.com/paidpromo' },
        { src: chameleon, width: '33.4rem', height: '14rem', title: 'The Chameleon Collective', link: 'https://example.com/paidpromo' },
        { src: IIHANO, width: '27rem', height: '14rem', title: 'Indore Cancer Foundtion', link: 'https://indorecancerfoundation.org/' },
        { src: nict, width: '33.4rem', height: '7.6rem', title: <div>National Institute of Information<br /> <p className='text-[1.5rem]'>and Communications Technology</p></div>, link: 'https://nicttpl.com/' },
        { src: Mayaa, width: '20.2rem', height: '7rem', title: 'Mayaa Money', link: 'https://mayaa.money/' },
        { src: Paul, width: '28.6rem', height: '8.5rem', title: 'Paul Merchants Limited', link: 'https://www.paulmerchants.net/' },
        { src: PaidPromo, width: '25rem', height: '11rem', title: 'Paid Promo', link: 'https://example.com/paidpromo' },
        { src: kriti, width: '24.2rem', height: '10rem', title: 'kriti Industries', link: 'https://kritiindustries.com/' },
        { src: indaz, width: '28.2rem', height: '10rem', title: 'Indaz', link: 'https://inadz.com/' },
        { src: rackbank, width: '28.2rem', height: '13rem', title: 'Rack Bank', link: 'https://www.rackbank.com/' },
        { src: Electronic, width: '33.4rem', height: '14rem', title: <div>Electronic Payment & Services</div>, link: 'https://electronicpay.in/' },
        { src: FitZone, width: '40rem', height: '14rem', title: 'FitZone', link: 'https://fit.zone/' },
        { src: Investmentor, width: '20.2rem', height: '14rem', title: 'Investmentor', link: 'https://investmentor.com.au/' },
        { src: andbuy, width: '20.2rem', height: '14rem', title: 'andbuy', link: 'https://example.com/paidpromo' },
        { src: chameleon, width: '33.4rem', height: '14rem', title: 'The Chameleon Collective', link: 'https://example.com/paidpromo' },
    ];

    return (
        <div className="w-full mt-16">
            <div className="overflow-hidden whitespace-nowrap group">
                <div className="inline-block animate-marquee group-hover:animation-paused">
                    {images.map((image, index) => (
                        <div
                            key={index}
                            className="inline-block mx-[8rem] text-center"
                            style={{ width: image.width }}
                        >
                            <a href={image.link} target="_blank" rel="noopener noreferrer">
                                <div
                                    className="rounded-md overflow-hidden mb-4"
                                    style={{
                                        width: image.width,
                                        height: image.height,
                                    }}
                                >
                                    <img
                                        src={image.src}
                                        alt={image.title}
                                        className="w-full h-full object-contain"
                                    />
                                </div>
                                <p className="w-full text-[2rem] font-medium text-gray-800">{image.title}</p>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Marquee;


import React from 'react';
import { CareerImg } from '../../../static/constants/imageConstant';
import { AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';

const CareerPage6 = () => {
    return (
        <div className='bg-gredient-1'>
            <div className='container-lg w-full h-full py-[4rem] mt-[1rem] relative'>
                <div className="absolute left-[75%] md:left-[62.5%] w-[11.7rem] top-[0rem] h-[14.7rem] transform -translate-x-[50%]">
                    <img
                        src={CareerImg.employee1}
                        alt="Employee"
                        className="w-full h-full rounded-full object-cover"
                    />
                </div>
                <div className="absolute left-[75%] md:left-[87.5%] w-[11.7rem] top-[72rem] sm:top-[50rem] md:top-[0rem] h-[14.7rem] transform -translate-x-[50%]">
                    <img
                        src={CareerImg.employee1}
                        alt="Employee"
                        className="w-full h-full rounded-full object-cover"
                    />
                </div>
                <div className='flex'>
                    <div className='w-[50%] flex flex-col justify-center items-start'>
                        <h1 className='text-[6rem] text-[#000000] text-start poppins-extralight'>Hear from Our<br />
                            Team</h1>
                        <p className='text-[#8A929A] text-[2.5rem] poppins-regular mt-[3rem]'>
                            Don’t just take our word for it—hear what our employees have
                            to say about life at Infominez. Discover their stories of growth,
                            success, and the unique experiences that make working here so rewarding.</p>
                    </div>
                    <div className='w-[50%] flex'>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-[2rem] w-full mx-6">
                            <div className=" flex flex-col justify-start items-center bg-[#fff] shadow-2xl rounded-lg p-8">
                                <div className='w-[3.6rem] h-[3rem] flex justify-center items-center mt-[10rem]'>
                                    <img src={CareerImg.quates1} alt="" className="w-full h-full" />
                                </div>
                                <div className="mt-4 text-center">
                                    <h3 className="text-[#000] text-[2.5rem] montserrat-semibold mt-[2.2rem]">SHAIBU CHERIAN</h3>
                                    <p className="text-[#979EA5] text-[1.8rem] montserrat-semibold">Lorem Ipsum</p>
                                    <p className="text-[#979EA5] text-[2rem] py-[3.5rem] dm-sans-medium-italic">
                                        Infominez has been an invaluable strategic technology
                                        partner for Paul Merchants, guiding us through the complex
                                        landscape of digital financial services. Their expertise in
                                        developing...
                                    </p>
                                    <div className="flex justify-center items-center gap-6 py-[3.5rem]">
                                        <a href="#linkedin" className="text-[#8A929A] hover:text-[#00A24C] text-[2.1rem] flex">
                                            <AiFillLinkedin /><span className='text-[1.3rem] text-center'>Linkedin</span>
                                        </a>
                                        <a href="#twitter" className="text-[#8A929A] hover:text-[#00A24C] text-[2.1rem] flex">
                                            <AiOutlineTwitter /><span className='text-[1.3rem] text-center'>Twitter</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className=" flex flex-col justify-start items-center bg-[#fff] shadow-2xl rounded-lg p-8">
                                <div className='w-[3.6rem] h-[3rem] flex justify-center items-center mt-[10rem]'>
                                    <img src={CareerImg.quates1} alt="" className="w-full h-full" />
                                </div>
                                <div className="mt-4 text-center">
                                    <h3 className="text-[#000] text-[2.5rem] montserrat-semibold mt-[2.2rem]">SHAIBU CHERIAN</h3>
                                    <p className="text-[#979EA5] text-[1.8rem] montserrat-semibold">Lorem Ipsum</p>
                                    <p className="text-[#979EA5] text-[2rem] py-[3.5rem] dm-sans-medium-italic">
                                        Infominez has been an invaluable strategic technology
                                        partner for Paul Merchants, guiding us through the complex
                                        landscape of digital financial services. Their expertise in
                                        developing...
                                    </p>
                                    <div className="flex justify-center items-center gap-6 py-[3.5rem]">
                                        <a href="#linkedin" className="text-[#8A929A] hover:text-[#00A24C] text-[2.1rem] flex">
                                            <AiFillLinkedin /><span className='text-[1.3rem] text-center'>Linkedin</span>
                                        </a>
                                        <a href="#twitter" className="text-[#8A929A] hover:text-[#00A24C] text-[2.1rem] flex">
                                            <AiOutlineTwitter /><span className='text-[1.3rem] text-center'>Twitter</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-between'>
                    <button
                        className="w-[5rem] h-[5rem] rounded-full border-[2px] text-[#21CE70] border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center">
                        <FaArrowLeft size={20} />
                    </button>
                    <button
                        className="w-[5rem] h-[5rem] rounded-full border-[2px] text-[#21CE70] border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center">
                        <FaArrowRight size={20} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CareerPage6;


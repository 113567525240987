import React from 'react'
import './LifeAtInfominez.css'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import SelectButton from '../../common/SelectButton/SelectButton'

function LifeAtInfominez() {
    return (
        <div className="life-at w-full min-h-[94rem]">
            <div className="container-lg w-full h-full flex flex-coljustify-start backdrop-blur-sm bg-white/30 lg:backdrop-filter-none lg:bg-transparent">
                <div className="w-full lg:w-[40%] h-full mb-[2rem] mt-[7.5%]">
                    <SectionHeaders
                        contentPrimary={'Life at Infominez'}
                        classes={`poppins-extralight text-[6rem] items-start text-start mb-[1.2rem]`}
                    />
                    <p className="poppins-light text-gray-800 lg:text-[#707F96] text-[2.2rem] mb-[2rem]">
                    At Infominez, we foster a culture of innovation, collaboration, and growth. Our team thrives in a dynamic environment where creativity is encouraged, ideas are valued, and every challenge is an opportunity to learn. Whether it’s through mentorship, hands-on projects, or upskilling, we are committed to helping each individual reach their full potential, making Infominez not just a workplace, but a community where talent grows and success flourishes.
                    </p>
                    <SelectButton
                        bgColor={`bg-[#41DF6B]`}
                        fontWeight={`poppins-regular`}
                        height={`h-[4.7rem]`}
                        width={'w-[20rem]'}
                        title={`Know More`}
                        textSize={`text-[1.6rem]`}
                    />
                </div>
            </div>
        </div>
    )
}

export default LifeAtInfominez

import React from 'react';
import { CareerImg } from '../../../static/constants/imageConstant';

const CareerPage5 = () => {
    return (
        <div className="bg-[#F2F4F4] w-full h-full mb-[4rem]">
            <div className="container-lg w-full h-full flex flex-col px-[2rem]">
                <div className="w-full flex flex-col justify-center py-[4rem]">
                    <h1 className="text-[#0A0A0A] text-center text-[3rem] md:text-[4rem] lg:text-[6rem] poppins-extralight">
                        Our Core Values
                    </h1>
                    <p className="text-[#8A929A] text-center text-[1.6rem] md:text-[2rem] lg:text-[2.5rem] poppins-regular">
                        At the heart of everything we do at Infominez are our core values that drive our mission:
                    </p>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[2rem] w-full pb-[4rem]">
                    <div className="bg-white shadow-md rounded-[1rem] p-[2rem] flex flex-col items-center text-center">
                        <div className='w-[9rem] h-[10rem] mt-[7rem]'>
                            <img src={CareerImg.career3} alt="Innovation Icon" className="w-full h-full" />
                        </div>
                        <h3 className="text-[#0A0A0A] text-[2.4rem] poppins-light my-[3.2rem]">Innovation</h3>
                        <p className="text-[#8A929A] text-[2.2rem] poppins-light mb-[4rem]">
                            We push boundaries to deliver nextgen solutions.
                        </p>
                        {/* <div className='py-[4rem]'>
                            <button className="border-[0.2rem] border-[#00D47E] text-[#1F1217] text-[1.6rem] px-[2rem] py-[0.8rem] rounded-[0.8rem] poppins-regular">
                                Discover More
                            </button>
                        </div> */}
                    </div>

                    <div className="bg-white shadow-md rounded-[1rem] p-[2rem] flex flex-col items-center text-center">
                        <div className='w-[9rem] h-[10rem] mt-[7rem]'>
                            <img src={CareerImg.career4} alt="Collaboration Icon" className="w-full h-full" />
                        </div>
                        <h3 className="text-[#0A0A0A] text-[2.4rem] poppins-light my-[3.2rem]">Collaboration</h3>
                        <p className="text-[#8A929A] text-[2.2rem] poppins-light mb-[4rem]">
                            We believe great ideas come from working together.
                        </p>
                        {/* <div className='py-[4rem]'>
                            <button className="border-[0.2rem] border-[#00D47E] text-[#1F1217] text-[1.6rem] px-[2rem] py-[0.8rem] rounded-[0.8rem] poppins-regular">
                                Discover More
                            </button>
                        </div> */}
                    </div>

                    <div className="bg-white shadow-md rounded-[1rem] p-[2rem] flex flex-col items-center text-center">
                        <div className='w-[9rem] h-[10rem] mt-[7rem]'>
                            <img src={CareerImg.career5} alt="Growth Icon" className="w-full h-full" />
                        </div>
                        <h3 className="text-[#0A0A0A] text-[2.4rem] poppins-light py-[3.2rem]">Growth</h3>
                        <p className="text-[#8A929A] text-[2.2rem] poppins-light mb-[4rem]">
                            We invest in our people to grow both personally and professionally.
                        </p>
                        {/* <div className='py-[4rem]'>
                            <button className="border-[0.2rem] border-[#00D47E] text-[#1F1217] text-[1.6rem] px-[2rem] py-[0.8rem] rounded-[0.8rem] poppins-regular">
                                Discover More
                            </button>
                        </div> */}
                    </div>

                    <div className="bg-white shadow-md rounded-[1rem] p-[2rem] flex flex-col items-center text-center">
                        <div className='w-[9rem] h-[10rem] mt-[7rem]'>
                            <img src={CareerImg.career6} alt="Excellence Icon" className="w-full h-full" />
                        </div>
                        <h3 className="text-[#0A0A0A] text-[2.4rem] poppins-light py-[3.2rem]">Excellence</h3>
                        <p className="text-[#8A929A] text-[2.2rem] poppins-light mb-[4rem]">
                            We strive to exceed expectations in all our endeavors.
                        </p>
                        {/* <div className='py-[4rem]'>
                            <button className="border-[0.2rem] border-[#00D47E] text-[#1F1217] text-[1.6rem] px-[2rem] py-[0.8rem] rounded-[0.8rem] poppins-regular">
                                Discover More
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareerPage5;

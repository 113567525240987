import React from 'react';
import IndustryECommerceRetial1 from '../../components/main/IndustryECommerceRetial1/IndustryECommerceRetial1';
import IndustryECommerceRetial2 from '../../components/main/IndustryECommerceRetial2/IndustryECommerceRetial2';
import IndustryECommerceRetial3 from '../../components/main/IndustryECommerceRetial3/IndustryECommerceRetial3';
import IndustryECommerceRetial4 from '../../components/main/IndustryECommerceRetial4/IndustryECommerceRetial4';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import ClientTestimonial1 from '../../components/main/ClientTestimonial1/ClientTestimonial1';

const IndustryECommerceRetial = () => {
  return (
    <div>
      <IndustryECommerceRetial1 />
      <IndustryECommerceRetial2 />
      <IndustryECommerceRetial3 />
      <IndustryECommerceRetial4 />
      <CaseStudy bgWhite />
      <ClientTestimonial1 />
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default IndustryECommerceRetial;
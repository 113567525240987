import React from 'react';
import IndustryHealthCare1 from '../../components/main/IndustryHealthCare1/IndustryHealthCare1';
import IndustryHealthCare2 from '../../components/main/IndustryHealthCare2/IndustryHealthCare2';
import IndustryHealthCare3 from '../../components/main/IndustryHealthCare3/IndustryHealthCare3';
import IndustryHealthCare4 from '../../components/main/IndustryHealthCare4/IndustryHealthCare4';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import ClientTestimonial1 from '../../components/main/ClientTestimonial1/ClientTestimonial1';

const IndustryHealthCare = () => {
    return (
        <div>
            <IndustryHealthCare1 />
            <IndustryHealthCare2 />
            <IndustryHealthCare3 />
            <IndustryHealthCare4 />
            <CaseStudy bgWhite />
            <ClientTestimonial1 />
            <FooterSection />
            <MiniFooter />
        </div>
    )
}

export default IndustryHealthCare;
import React from 'react';
import { BlogBImg } from "../../../static/constants/imageConstant";

const PODServices6 = () => {
    return (
        <div className='bg-[#F2F4F4] py-[6rem]'>
            <div className='container-lg w-full flex gap-[6rem]'>
                <div className=' w-[40%] flex flex-col'>
                    <h1 className='text-[6rem] text-[#000000] poppins-extralight'>Start Your Journey
                        Today with Infominez
                        POD Services
                    </h1>
                    <p className='text-[2.2rem] text-[#707F96] poppins-light mt-[1.8rem]'>Whether you’re a startup looking to build your MVP or an enterprise seeking tailored tech solutions, Infominez is here to provide the talent and expertise you need.</p>
                </div>
                <div className='w-[60%]'>
                    <img src={BlogBImg.pod1} alt='blogBImg1' className='w-full h-full' />
                </div>
            </div>
        </div>
    )
}

export default PODServices6

// {/* <div className='bg-[#F2F4F4] py-[6rem] relative'>
//             <div className=' w-full flex justify-start items-start gap-[6rem]'>
//                 <div className='container-lg w-[40%] flex flex-col justify-start items-start'>
//                     <h1 className='text-[6rem] text-[#000000] poppins-extralight'>Start Your Journey
//                         Today with Infominez
//                         POD Services
//                     </h1>
//                     <p className='text-[2.2rem] text-[#707F96] poppins-light mt-[1.8rem]'>Whether you’re a startup looking to build your MVP or an enterprise seeking tailored tech solutions, Infominez is here to provide the talent and expertise you need.</p>
//                 </div>
//             </div>
//             <div className='absolute top-0 right-0 w-[60%]'>
//                 <img src={BlogBImg.pod1} alt='blogBImg1' className='w-full h-full' />
//             </div>
//         </div> */}
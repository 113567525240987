import { actionTypes } from '../types';

export const contactUsv1 = (payload, callback) => ({
    type: actionTypes.CONTACT_US_V1_REQUEST,
    payload,
    callback
});

export const contactUsv1Success = (payload) => ({
    type: actionTypes.CONTACT_US_V1_SUCCESS,
    payload,
});

export const contactUsv1Failure = (payload) => ({
    type: actionTypes.CONTACT_US_V1_FAILURE,
    payload,
});

export const contactUsv1Reset = () => ({
    type: actionTypes.CONTACT_US_V1_RESET,
});

export const contactUsv1ResetAll = () => ({
    type: actionTypes.CONTACT_US_V1_RESET_ALL,
}); 
import React, { Fragment, lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import NotFoundPage from "../components/utils/NotFoundPage";
import FontHelper from "../components/utils/Fonts/FontHelper";
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AboutUs";
import ServicesPage from "../pages/ServicesPage/ServicesPage";
import CaseStudy from "../pages/CaseStudy/CaseStudy";
import IMSDetails from "../pages/IMSDetails/IMSDetails";
import ContactUs from "../pages/ContactUs/ContactUs";
import IndustryPage from "../pages/IndustryPage/IndustryPage";
import CarrerPage from "../pages/CarrerPage/CarrerPage";
import Blog_b_Page from "../pages/Blog_b_Page/Blog_b_Page";
import IMSBlog from "../pages/IMSBlog/IMSBlog";
import Services from "../components/main/Services/Services";
import Blog_A_Page from "../pages/Blog_A_Page/Blog_A_Page";
import PODServices from "../pages/PODServices/PODServices";
import IndustryFintechPage from "../pages/IndustryFintechPage/IndustryFintechPage";
import IndustryECommerceRetial from "../pages/IndustryECommerceRetial/IndustryECommerceRetial";
import IndustryHealthCare from "../pages/IndustryHealthCare/IndustryHealthCare";
import ServicesProduct from "../pages/ServicesProduct/ServicesProduct";
import Ai_Ml_Services from "../pages/Ai_Ml_Services/Ai_Ml_Services";
import DataEngineering from "../pages/DataEngineering/DataEngineering";
import CloudEngineering from "../pages/CloudEngineering/CloudEngineering";
import IndustryEdutech from "../pages/IndustryEdutech/IndustryEdutech";
import CaseStudyDetail from "../pages/CaseStudy/CaseStudyDetail";


function AppRoutes() {
  return (
    // <Suspense fallback={<div>Loading...</div>}>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/services-page" element={<ServicesPage />} />
            <Route path="/case-study" element={<CaseStudy />} />
            <Route path="/case-study-detail/:caseId" element={<CaseStudyDetail />} />
            <Route path="/ims-details" element={<IMSDetails />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/industry-page" element={<IndustryPage />} />
            <Route path="/industry-fintech-page" element={<IndustryFintechPage />} />
            <Route path="/industry-e-commerce-retial" element={<IndustryECommerceRetial />} />
            <Route path="/industry-edutech" element={<IndustryEdutech />} />
            <Route path="/industry-health-care" element={<IndustryHealthCare />} />
            <Route path="/carrer-page" element={<CarrerPage />} />
            <Route path="/blog_b_page" element={<Blog_b_Page />} />
            <Route path="/blog_details/:blogId" element={<IMSBlog />} />
            <Route path="/all-blogs" element={<Blog_A_Page />} />
            <Route path="/pod-Services" element={<PODServices />} />
            <Route path="/services-product" element={<ServicesProduct />} />
            <Route path="/ai_ml_services" element={<Ai_Ml_Services />} />
            <Route path="/data-engineering" element={<DataEngineering />} />
            <Route path="/cloud-engineering" element={<CloudEngineering />} />

            <Route path="/services" element={<Services />} />
            {/* helper route leter to be removed */}
            <Route path="/fonts" element={<FontHelper />} />
            <Route element={<NotFoundPage />} /> {/* This will catch all unmatched routes */}
        </Routes>
    // </Suspense>
  )
}

export default AppRoutes;

// TransformYourVision.jsx
import React from 'react';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';

function TransformYourVision() {
  const features = [
    {
      title: 'End-to-End Expertise',
      description: 'From concept to launch, we handle everything, so you can focus on growing your business.',
      imgSrc: ServiceImg.service2,
    },
    {
      title: 'Future-Proof Innovation',
      description: 'Stay ahead in the competitive IT landscape with our team of experts injecting cutting-edge technology into your product.',
      imgSrc: ServiceImg.service5,
    },
    {
      title: 'Accelerate Time-to-Market',
      description: 'Get your innovative product into users\' hands faster with our agile development process, saving you 30-40% in development time.',
      imgSrc: ServiceImg.service3,
    },
    {
      title: 'Intuitive User Experiences',
      description: 'Drive engagement, customer loyalty, and conversion rates with our seamless, user-friendly interfaces designed to exceed expectations.',
      imgSrc: ServiceImg.service6,
    },
    {
      title: 'Cost-Effective Solutions',
      description: 'Enjoy transparent pricing, reduced costs, and a 20-30% decrease in development expenses with our efficient solutions.',
      imgSrc: ServiceImg.service4,
    },
    {
      title: 'Partnership Beyond Launch',
      description: 'Rely on our ongoing maintenance and support to ensure your product continues to thrive, adapt, and evolve with your users\' needs.',
      imgSrc: ServiceImg.service7,
    },
  ];

  return (
    <div className="w-full h-full bg-[#F2F4F4]">
      <div className="container-lg w-full h-full flex flex-col items-center">
        <SectionHeaders
          contentPrimary={'Transform Your Vision into a Thriving Reality'}
          classes={`poppins-extralight text-[6rem] items-center text-center justify-center my-[7.2rem]`}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[6rem] gap-x-[2rem] py-[2rem]">
          {features.map((feature, index) => (
            <div key={index} className="flex items-start space-x-[4rem]">
              <div className="w-[10rem] h-[10rem] flex-shrink-0">
                <img src={feature.imgSrc} alt={feature.title} className="w-full h-full object-contain" />
              </div>
              <div className='w-[80%] text-start'>
                <h2 className="text-[2.8rem] poppins-semibold text-[#3C436A]">{feature.title}</h2>
                <p className="text-[#707F96] text-[2.2rem] poppins-regular mt-2">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='py-[4rem]'>
          <SelectButton
            title={'Transform Your Idea Today'}
            bgColor="bg-[#00D47E]"
            width="w-[37.9rem]"
            textSize="text-[1.6rem]"
            fontWeight="poppins-regular"
            titleColor={`text-white hover:text-[#2B2B2B]`}
            height="h-[4.7rem]"
          />
        </div>
      </div>
    </div>
  );
}

export default TransformYourVision;

import React from 'react';
import BlogAPage1 from '../../components/main/BlogAPage1/BlogAPage1';
import BlogAPage2 from '../../components/main/BlogAPage2/BlogAPage2';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';

const Blog_A_Page = () => {
  return (
    <div>
      <BlogAPage1 />
      <BlogAPage2 />
      <GetStartedDetailsForm />
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default Blog_A_Page
import React from 'react';
import SelectButton from '../../common/SelectButton/SelectButton';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import {ServicesSectionPageImages} from '../../../static/constants/imageConstant';

const Ai_Ml_Services3 = () => {
    return (
        <div className="container-lg">
            <SectionHeaders
                contentPrimary={
                    <h2 className="overflow-hidden">
                        Driving Growth with End-to-End AI & ML Strategy Our AI & ML Approach Includes
                    </h2>
                }
                classes={`poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[7.2rem]`}
            />
            <div className="w-full h-full flex flex-col lg:flex-row gap-[4rem]">
                <div className="w-full lg:w-[48%]">
                    <img
                        src={ServicesSectionPageImages.OurAi}
                        alt="Designer working on screen"
                        className="w-full"
                    />
                </div>
                <div className="w-full lg:w-[50%] h-full flex flex-col justify-start mb-[4rem] 2xl:mb-[8rem]">
                    <div className='w-full'>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Define Business Objectives</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem] text-wrap">
                                    Align AI/ML initiatives with your business goals.
                                    Assess Data Readiness: Evaluate your current data ecosystem for AI integration.
                                </p>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Develop Models</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Design and build ML models tailored to your use cases.
                                    Implement and Integrate: Seamlessly deploy and integrate AI systems into your existing infrastructure.
                                </p>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Optimize and Iterate</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Continuously refine models to improve accuracy and adaptability.
                                    Foster AI-Driven Culture: Equip your teams with the skills and mindset for AI adoption.
                                </p>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">DevOps and Continuous Integration</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Seamless user experiences that foster customer loyalty.
                                </p>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Maintenance and Support</h3>
                                <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                    Ongoing maintenance and support services to ensure your product stays up-to-date and responsive to changing market demands.
                                </p>
                            </div>
                        </li>
                    </div>
                    {/* <div className="mt-[4rem] px-[3rem] mb-[4rem]">
                        <SelectButton
                            title={'Ready to Transform Your Idea'}
                            bgColor="bg-[#00D47E]"
                            width="w-[37rem]"
                            textSize="text-[1.6rem]"
                            fontWeight="poppins-regular"
                            titleColor={`text-white hover:text-[#2B2B2B]`}
                            height="h-[4.7rem]"
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Ai_Ml_Services3
import React from 'react';
import { CareerImg } from '../../../static/constants/imageConstant';

const PODServices5 = () => {
    return (
        <div className="bg-[#fff] w-full">
            <div className="">
                <div className="relative flex flex-col lg:flex-row mb-[6rem]">
                    <div className="lg:w-[60%] w-full">
                        <img
                            src={CareerImg.carrer01}
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="lg:my-[5%] lg:translate-x-[-10%] bg-white py-[4rem] px-[7rem] lg:w-[50%] w-full ">
                        <h1 className='text-[6rem] text-[#000000] poppins-extralight'>Custom POD</h1>
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-medium text-[2.8rem] text-[#3C436A]">For Enterprises Seeking Full Flexibility</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        A fully customizable solution designed to meet unique enterprise-level demands, offering end-to-end development tailored to your specific project requirements.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-medium text-[2.8rem] text-[#3C436A]">Team Composition</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Fully flexible—choose from developers, QA engineers, DevOps experts, project managers, and more, based on your needs.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-medium text-[2.8rem] text-[#3C436A]">Ideal for</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Enterprises with specialized project requirements, cross-functional teams, and complex multi-phase deployments.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-medium text-[2.8rem] text-[#3C436A]">Benefits</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Full control over team structure, project pace, and resource allocation—adapt as your business grows and evolves.
                                    </p>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PODServices5
import React from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SelectButton from '../../common/SelectButton/SelectButton';

const DataEngineering3 = () => {
    return (
        <div className="container-lg">
            <SectionHeaders
                contentPrimary={
                    <h2 className="overflow-hidden">
                        Empowering Business Growth with an End-to-End Data Strategy
                    </h2>
                }
                classes={`poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[7.2rem]`}
            />
            <div className="w-full h-full flex flex-col lg:flex-row gap-[4rem] mb-[4rem]">
                <div className="w-full lg:w-[48%]">
                    <img
                        src={ServiceImg.aiservice8}
                        alt="Designer working on screen"
                        className="w-full"
                    />
                </div>
                <div className="w-full lg:w-[52%] h-full flex flex-col justify-start">
                    <div className='w-full mb-[2rem]'>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Define Business Objectives</h3>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Assess Current Data Landscape</h3>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Develop Data Governance Framework</h3>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Data Architecture Plan</h3>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Implement Data Management</h3>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Advanced Analytics and Visualization</h3>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Foster a Data-Driven Culture</h3>
                            </div>
                        </li>
                        <li className="flex items-start gap-[2rem]">
                            <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                            <div className='w-full'>
                                <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Continuous Improvement and Iteration</h3>
                            </div>
                        </li>
                    </div>
                    {/* <div className="mt-[4rem] px-[3rem] mb-[4rem]">
                        <SelectButton
                            title={'Ready to Transform Your Idea'}
                            bgColor="bg-[#00D47E]"
                            width="w-[37rem]"
                            textSize="text-[1.6rem]"
                            fontWeight="poppins-regular"
                            titleColor={`text-white hover:text-[#2B2B2B]`}
                            height="h-[4.7rem]"
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default DataEngineering3
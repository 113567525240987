import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import SelectButton from "../../common/SelectButton/SelectButton";


const CloudEngineering6 = () => {
  const dataPoints = [
    {
      title: "Proven Industry Experience",
      description:
        "We’ve successfully delivered cloud solutions across diverse industries.",
    },
    {
      title: "Tailored Cloud Solutions",
      description:
        "Every solution is custom-built to fit your business’s unique needs.",
    },
    {
      title: "Commitment to Security",
      description:
        "We prioritize security from day one, ensuring your business is protected.",
    },
    {
      title: "Cutting-Edge Technology",
      description:
        "We leverage the latest advancements in cloud technology to give your business a competitive edge.",
    },
    {
      title: "Dedicated 24/7 Support",
      description:
        "Our support team is always available to resolve issues and keep your systems running smoothly.",
    },
    // {
    //   title: "Tailored Solutions",
    //   description:
    //     "We work with businesses across industries, delivering solutions specific to your business needs.",
    // },
  ];

  return (
    <div className="w-full h-full bg-[#F2F4F4] py-[5rem] mb-[8rem]">
      <div className="container-lg mx-auto flex flex-col">
        <div className="mx-auto flex flex-col items-center text-center">
          <SectionHeaders
            contentPrimary={
              <>
                Advantages with Us<br />
                Infominez Your Strategic Cloud Partner
              </>
            }
            classes="poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] text-center my-[3.7rem]"
          />

          <p className="text-[1.8rem] md:text-[2.5rem] text-[#8A929A] poppins-regular mt-[2rem]">
            With years of experience, Infominez brings unmatched expertise to your cloud projects.
            Our team of cloud engineers and consultants works hand-in-hand with your business to
            ensure success at every stage of the journey.
          </p>
        </div>

        <div className="text-left">
          <h1 className="text-[#000000] text-[2.5rem] sm:text-[3rem] md:text-[3.5rem] lg:text-[4rem] 2xl:text-[4.5rem] text-start poppins-medium mt-[3.9rem]">
            Here’s why we’re the best partner for your cloud transformation
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] md:gap-[4rem] mt-[3.9rem]">
          {dataPoints.map((point, index) => (
            <div
              key={index}
              className="flex items-start gap-[1rem]"
            >
              <div className="w-[5%]"><div className="w-[1.2rem] h-[1.2rem] rounded-full bg-[#2666CF] mt-[1rem]" /></div>
              <div className="w-[94%] text-start ">
                <h3 className="text-[2.8rem] poppins-medium text-[#3C436A] mb-[0.8rem]">
                  {point.title}
                </h3>
                <p className="text-[2.2rem] text-[#8A929A] poppins-regular">
                  {point.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* <div className='py-[4rem] flex justify-center items-center'>
          <SelectButton
            title={'Talk to Our Cloud Experts Today'}
            bgColor="bg-[#00D47E]"
            width="w-[37.9rem]"
            textSize="text-[1.6rem]"
            fontWeight="poppins-regular"
            titleColor={`text-white hover:text-[#2B2B2B]`}
            height="h-[4.7rem]"
          />
        </div> */}
      </div>
    </div>
  );
};

export default CloudEngineering6;
import React from 'react'

function SelectButton({ title, bgColor, width, borderColor, titleColor, textSize, fontWeight, onClick, height, classNames }) {
  return (
    <button
      className={`
                flex items-center justify-center
                border-2 rounded-[8px]
                cursor-pointer 
                ${classNames}
                ${bgColor ? bgColor : "bg-[#FFFFFF]"} 
                ${width ? width : "w-[15rem]"} 
                ${borderColor ? borderColor : "border-[#41DF6B]"} 
                ${titleColor ? titleColor : "text-[#FFFFFF]"}
                ${textSize ? textSize : "text-[2rem]"}
                ${fontWeight ? fontWeight : "font-normal"}
                ${height ? height : "h-[8rem]"}
            `}
      onClick={onClick}
    >
      {title ? title : "Press me"}
    </button>
  )
}

export default SelectButton
import React from "react";

import GetStartedDetailsForm from "../../components/common/GetStartedDetailsForm/GetStartedDetailsForm";
import FooterSection from "../../components/common/FooterSection/FooterSection";
import MiniFooter from "../../components/common/MiniFooter/MiniFooter";
import CaseStudyPage1 from "../../components/main/CaseStudy/CaseStudyPage1";
import CaseStudyPage2 from "../../components/main/CaseStudy/CaseStudyPage2";
import CaseStudyPage3 from "../../components/main/CaseStudy/CaseStudyPage3";
import IMSPage1 from "../../components/main/IMSPage1/IMSPage1";
import IMSPage2 from "../../components/main/IMSPage2/IMSPage2";
import IMSPage6 from "../../components/main/IMSPage6/IMSPage6";
import IMSPage5 from "../../components/main/IMSPage5/IMSPage5";
import IMSPage4 from "../../components/main/IMSPage4/IMSPage4";
import IMSPage3 from "../../components/main/IMSPage3/IMSPage3";


const CaseStudyDetail =()=> {


    return (
        <div>
            <IMSPage1/>
            <IMSPage2/>
            <IMSPage3/>
            <IMSPage4/>
            <IMSPage5/>
            <IMSPage6/>
            <GetStartedDetailsForm/>
            <FooterSection/>
            <MiniFooter/>
        </div>
    )
}

export default CaseStudyDetail

import React from 'react';
import { IndustryImg } from "../../../static/constants/imageConstant";
import SectionHeaders from "../../common/Headers/SectionHeaders";

const PODServices4 = () => {
    const services = [
        {
            title: "Speed to Market",
            description:
                "Our plug-and-play POD teams, equipped with frontend, backend, QA, and project management expertise, get your products to market faster. With agile processes in place, we help you iterate quickly, reducing development time and accelerating innovation.",
            imgSrc: IndustryImg.industry6,
        },
        {
            title: "Custom-Built for Your Needs",
            description:
                "Every business is unique. Whether you're a startup with a lean budget or an enterprise with specific project needs, our PODs are tailored to your exact requirements. Choose the right mix of developers, testers, and project managers, or create a fully customized solution. ",
            imgSrc: IndustryImg.industry7,
        },
        {
            title: "Cost-Effective, High-Impact",
            description:
                "Pay only for the resources you need—our flexible POD plans give you the perfect balance between cost and quality. Avoid the long-term overhead of hiring full-time teams while enjoying the benefits of expert-driven, high-impact development.",
            imgSrc: IndustryImg.industry8,
        },
        {
            title: "Complete Ownership and Focus",
            description:
                "Each POD operates as an independent, self-managed unit, ensuring projects are executed smoothly, on time, and within budget. Our teams work autonomously while integrating seamlessly into your existing processes, allowing you to focus on strategic growth.",
            imgSrc: IndustryImg.industry9,
        },
        {
            title: "Scalable Teams for Enterprises",
            description:
                "For large-scale enterprises, our Custom PODs provide the ability to scale your team as projects evolve. Add or adjust resources, including developers, project managers, and testers, based on specific needs and project phases.",
            imgSrc: IndustryImg.industry10,
        },
        {
            title: "Expert Guidance for Startups ",
            description:
                "Startups thrive with Infominez PODs by accessing top-tier talent without full-time commitments. Our experienced teams help you build, test, and pivot faster, ensuring you stay competitive and achieve product-market fit.",
            imgSrc: IndustryImg.industry11,
        },
        {
            title: "Seamless Collaboration",
            description:
                "Our POD teams are fully integrated into your business, using the tools, methodologies, and workflows that align with your existing processes. This ensures smooth collaboration, clear communication, and the ability to scale efficiently.",
            imgSrc: IndustryImg.industry11,
        },
        {
            title: "Built-In Project Leadership",
            description:
                "With experienced project managers included in your POD, your initiatives move forward without the need for additional oversight. This reduces management complexity and ensures your projects are delivered on time and on budget.",
            imgSrc: IndustryImg.industry11,
        },
    ];

    return (
        <div className="bg-[#F2F4F4] py-[6rem]">
            <div className="container-lg w-full flex flex-col ">
                <SectionHeaders
                    contentPrimary={"Service Offerings"}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
                />
                <div className="mt-[6rem] mb-[4rem] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative">
                <div className="hidden lg:block absolute inset-x-0 top-1/3 transform -translate-y-1/2 border-t-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-x-0 top-[70%] transform -translate-y-1/2 border-t-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-y-0 left-1/3 transform -translate-x-1/2 border-l-[.1rem] border-[#00234480]"></div>
                    <div className="hidden lg:block absolute inset-y-0 left-2/3 transform -translate-x-1/2 border-l-[.1rem] border-[#00234480]"></div>

                    {services.map((service, index) => (
                        <div className='flex flex-col justify-items-start'>
                            <div
                            key={index}
                            className="flex flex-col justify-center items-center gap-[2rem] px-[2rem] py-[2rem] "
                        >
                            <div className="w-[4.3rem] h-[4.3rem] flex justify-center items-center">
                                <img
                                    src={service.imgSrc}
                                    alt={service.title}
                                    className="w-full h-full"
                                />
                            </div>
                            <div className="flex flex-col justify-center items-center">
                                <h3 className="text-[2.8rem] poppins-semibold text-center text-[#3C436A]">
                                    {service.title}
                                </h3>
                                <p className="mt-2 text-[2.2rem] poppins-light text-center text-[#707F96]">{service.description}</p>
                            </div>
                        </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PODServices4
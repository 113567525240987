import React from 'react'
import SectionHeaders from '../../common/Headers/SectionHeaders'
import Marquee from '../../utils/Marquee/Marquee'

function OurClientsSection() {
  return (
    <div className="mt-[8rem] mb-[4rem]">
        <SectionHeaders
          contentPrimary={'Our Trusted Clients'}
          classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
          // contentSecondary={`We can add logos of the clients`}
          secondaryClasses={`poppins-medium text-[2.5rem] text-[#8A929A]`}
        />
        <Marquee />
    </div>
  )
}

export default OurClientsSection
import React from 'react'
import { FaArrowRightLong } from "react-icons/fa6";

function BtnOutlined({ title, bgColor, width, borderColor, titleColor, textSize, fontWeight, onClick, ButtonHeight, hasArrow, classNames }) {
    return (
        <button
            className={`
                flex items-center justify-center
                
                cursor-pointer
                ${classNames}
                ${bgColor ? bgColor : "bg-[#FFFFFF] hover:bg-[#333333]"} 
                ${width ? width : "w-[15rem]"} 
                ${borderColor ? borderColor : ""} 
                ${titleColor ? titleColor : "text-[#FFFFFF] hover:text-[#333333]"}
                ${textSize ? textSize : "text-[2rem]"}
                ${fontWeight ? fontWeight : "font-normal"}
                ${ButtonHeight ? ButtonHeight : "h-[5.2rem]"}
            `}
            onClick={onClick}
        >
            {title ? title : "Press me"}
            {hasArrow ?
                <div className="mx-[2rem]">
                    <FaArrowRightLong />
                </div>
                : <></>}
        </button>

    )
}

export default BtnOutlined

import React from 'react';
import CarrerPage1 from '../../components/main/CarrerPage1/CarrerPage1';
import CarrerPage3 from '../../components/main/CarrerPage3/CarrerPage3';
import CarrerPage2 from '../../components/main/CarrerPage2/CarrerPage2';
import CarrerPage4 from '../../components/main/CarrerPage4/CarrerPage4';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import CareerPage5 from '../../components/main/CareerPage5/CareerPage5';
import CareerPage6 from '../../components/main/CareerPage6/CareerPage6';

const CarrerPage = () => {
  return (
    <div>
      <CarrerPage1 />
      <CarrerPage2 />
      <CareerPage5 />
      <CareerPage6 />
      <CarrerPage4 />
      <CarrerPage3 />
      {/* <GetStartedDetailsForm /> */}
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default CarrerPage
import React from 'react'
// import { ContactIconImgs, LatestInsightsImgs } from '../../../static/constants/imgConstants/imgConstants'
import { useNavigate } from 'react-router';
import SelectButton from '../SelectButton/SelectButton';

function ServicesCard({ id, icon, title, content, isServicesAlt, path, buttonContent }) {
    const navigate = useNavigate();

    return (
        <div
            id={id}
            className="container-lg flex items-center px-[3.6rem] bg-white py-[2rem] rounded-[1rem] border-[.1rem] border-[#D5D5D5] lg:w-full w-[90%] h-full"
            onClick={() => { }}
        >
            <div id={`#${id}`} className='flex w-full items-center' >
                <div className='services-card m-[2rem] h-[80%] w-[15%]'>
                    <img
                        className='w-full h-full'
                        src={icon}
                    />
                </div>
                <div className="w-[72%] sm:mx-[3rem]">
                    <div className='text-[2.8rem] poppins-semibold text-[#171717] mb-[1.2rem]'>
                        {title}
                    </div>
                    <h2 className='text-[2.2rem] text-[#8A929A] mb-[2rem] dm-sans-medium'>
                        {content}
                    </h2>
                    <SelectButton
                        title={buttonContent}
                        bgColor={`bg-white hover:bg-[#00D47E]`}
                        width="sm:w-[31rem] w-[25rem]"
                        textSize="text-[1.6rem]"
                        fontWeight="poppins-medium"
                        height="h-[4.7rem]"
                        titleColor={'bg-[#000000]'}
                        onClick={() => { navigate(path) }}
                    />
                </div>
            </div>
        </div>

    )
}

export default ServicesCard
import React from "react";
import "./AboutUsSection.css"
import { AboutUsSectionImages } from "../../../static/constants/imageConstant";
import SelectButton from "../../common/SelectButton/SelectButton";
// import { Navigate } from "react-router";
import WhyInfominezSec from "../WhyInfominezSec/WhyInfominezSec";
import { useNavigate } from "react-router";

function AboutUsSection() {
  const navigation = useNavigate();

  return (
    <div className="bg-[#F2F4F4] w-full">
      <div className="poppins-extralight text-[6rem] text-center py-[5rem]">
        About Us
      </div>
      <div className="w-full flex flex-col relative h-[75rem] overflow-hidden">
        <div className="absolute top-0 bottom-0 w-full lg:h-[65.6rem] 2xl:h-full about-us-exp-image z-[9]">
        </div>
        <div className="container-lg !z-[10] relative my-[18%] lg:my-[8%] 2xl:my-[6%] overflow-hidden">
          <div className="bg-[#fff] w-[50%] 2xl:w-[75%] ml-auto p-[4rem] 2xl:px-[6.7rem] 2xl:pt-[6.6rem] ">
            <div className="flex-wrap text-[2.2rem] text-[#707F96] poppins-regular text-start">
              <p className="line-clamp-3 lg:line-clamp-6 2xl:line-clamp-none">
              At Infominez, we are the driving force behind transforming technology into impactful
              business outcomes. Our team of visionary technologists specializes in AI and ML innovations,
              along with expertise in fintech, data analytics, and cloud services, to deliver solutions that
              redefine industry standards. With a deep understanding of AI-driven strategies and a commitment
              to customer success, we create transformative results that align with our clients' unique goals.
              By combining technical expertise with creative problem-solving, we enable businesses to unlock the
              power of data, innovate with confidence, and thrive in today's AI-driven world.
              </p>
            </div>
            <div className="py-[4rem]">
              <SelectButton
                title={'Know More'}
                bgColor="bg-[#41DF6B]"
                width="w-[19.8rem]"
                textSize="text-[1.8rem]"
                fontWeight="poppins-regular"
                titleColor={`hover:text-white text-[#2B2B2B]`}
                height="h-[4.7rem]"
                onClick={() => { navigation("/about-us") }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsSection;
import React, {useEffect} from 'react';
import "./IMSPage1.css"
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {case_studies} from "../../../Store/actions/case_studiesActions";


const IMSPage1 = () => {
    const dispatch = useDispatch();
    const { caseId } = useParams();

    const case_studiesReducerData = useSelector((state) => state.case_studiesReducer?.data || []);

    useEffect(() => {
        dispatch(case_studies({}));
    }, []);

    const data = case_studiesReducerData.find((item) => item.id == parseInt(caseId))
    return (
        <div>
            <div className="relative w-full h-screen flex flex-col justify-center items-center">
                <div className="ims-1 w-full h-full flex flex-col items-start justify-center">
                    <div className=" container-lg w-[90%] flex flex-col items-start justify-center  text-start text-white ">
                        <h1 className="text-[4.5rem] text-[#fff] poppins-light mb-8">
                            Revolutionize Your ATM Operations with<br /> Our Incident Management System
                        </h1>
                        <div className="w-[90%] lg:w-[70%] xl:w-[65%] 2xl:w-[55%] flex justify-start items-start h-1 bg-gray-600 mb-8"></div>
                        <p className="text-[2.4rem] text-[#fff] poppins-regular mb-8">
                            {data.attributes.Title}
                        </p>
                    </div>
                </div>
            </div>
            <div className="absolute container-lg bottom-[0rem] left-1/2 transform -translate-x-1/2 bg-white rounded-[1rem] shadow-lg p-6 sm:p-8 lg:p-10 text-center w-[90%] translate-y-[65%]">
                <h1 className='text-[4.5rem] text-[#202020] poppins-medium'>OverView</h1>
                <p className="text-[#404040] text-[2.4rem] poppins-light text-center px-[2rem] py-[2rem]">
                    {data.attributes.overview}
                </p>
            </div>
        </div>
    )
}

export default IMSPage1

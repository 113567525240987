import React from 'react';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';

const IndustryECommerceRetial2 = () => {
    return (
      <div className="bg-[#F2F4F4]">
              <div className="container-lg">
                  <SectionHeaders
                      contentPrimary={'Advantages with Us'}
                      classes={`poppins-extralight text-[6rem] items-center text-center justify-center my-[4rem] mt-[8rem]`}
                  />
                  <div className="w-full h-full flex flex-col lg:flex-row gap-[10rem]">
                      <div className="w-full lg:w-[50%] flex flex-col items-center">
                          <img
                              src={ServiceImg.fintech01}
                              alt="Designer working on screen"
                              className="w-full"
                          />
                          {/* <div className='w-[57rem] h-max-w-min'>
                          <img
                              src={ServiceImg.fintech010}
                              alt="Designer working on screen"
                              className="w-full h-full"
                          />
                          </div> */}
                      </div>
                      <div className="w-full lg:w-[50%] h-full flex flex-col justify-start py-[3rem] mb-[3rem]">
                          <div className='w-full'>
                              <li className="flex items-start gap-[2rem] mb-[1rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Industry Expertise</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      Our team boasts extensive knowledge of the supply chain and retail sector, ensuring tailored solutions to address your specific needs and challenges.
                                      </p>
                                  </div>
                              </li>
                              <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Innovation</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      We leverage emerging technologies like AI, blockchain, and IoT to future-proof your operations and help you stay competitive in a rapidly changing market.  
                                      </p>
                                  </div>
                              </li>
                              <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Scalable Solutions:</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      Our offerings are designed to adapt and grow with your business, ensuring seamless integration and minimal disruption. 
                                      </p>
                                  </div>
                              </li>
                              <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Security</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      Your data and transactions are safeguarded by robust security measures, ensuring confidentiality, integrity, and availability. 
                                      </p>
                                  </div>
                              </li>
                              <li className="flex items-start gap-[2rem] mt-[2rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Client-Centric Approach</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      Your success is our priority, and we collaborate closely with you to achieve it, providing personalized support and guidance every step of the way. Value Propositions  
                                      </p>
                                  </div>
                              </li>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      )
  }

export default IndustryECommerceRetial2;
import React from 'react'
import PODServices1 from '../../components/main/PODServices1/PODServices1'
import PODServices2 from '../../components/main/PODServices2/PODServices2'
import PODServices3 from '../../components/main/PODServices3/PODServices3'
import PODServices4 from '../../components/main/PODServices4/PODServices4'
import ClientTestimonial from '../../components/main/ClientTestimonial/ClientTestimonial'
import PODServices5 from '../../components/main/PODServices5/PODServices5'
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm'
import PODServices6 from '../../components/main/PODServices6/PODServices6'

const PODServices = () => {
  return (
    <div>
        <PODServices1 />
        <PODServices2 />
        <PODServices3 />
        <PODServices5 />
        <PODServices4 />
        <ClientTestimonial />
        <PODServices6 />
        <GetStartedDetailsForm bgWhite />
    </div>
  )
}

export default PODServices
export const base_url = "https://infominez.com/api";

export const strapi_base_url = "https://dev.infominez.com"

export const endpoints = {
    contactUsv1: "/aboutUsV2",
    contactUsV2: "/contactUs",
    strapiToken: "/api/auth/local",
    // strapiBlogs: "/api/articles",
    strapiBlogs: "/api/blogs",
    case_studies: "/api/case-studies",
    technologies: "/api/technologies",
    white_paper: "api/articles",
    news_event: "api/articles",
    case_studies1: "/api/case-studies?populate=*&filters[SubCategory][slug]=*"
}

import React from 'react';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import SelectButton from '../../common/SelectButton/SelectButton';

function Ai_Ml_Services2() {
  const scrollToSection = () => {
    const element = document.getElementById('GetStartedDetailsForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const features = [
    {
      title: 'Data-Driven Decision Making',
      description: 'Use AI-powered insights to optimize operations, uncover opportunities, and manage risks effectively.',
      imgSrc: ServiceImg.service2,
    },
    {
      title: 'Enhanced Productivity',
      description: 'Automate repetitive tasks and streamline workflows with intelligent algorithms.',
      imgSrc: ServiceImg.service5,
    },
    {
      title: 'Customer Personalization',
      description: 'Deliver tailor-made experiences using predictive analytics and machine learning models.',
      imgSrc: ServiceImg.service3,
    },
    {
      title: 'Scalable Solutions',
      description: 'Build robust AI systems that grow with your business.',
      imgSrc: ServiceImg.service6,
    },
    {
      title: 'Ensure Data Security',
      description: 'Implement AI solutions designed to protect data privacy and maintain compliance.',
      imgSrc: ServiceImg.service4,
    },
    {
      title: 'Partnership Beyond Launch',
      description: 'Rely on our ongoing maintenance and support to ensure your product continues to thrive, adapt, and evolve with your users needs.',
      imgSrc: ServiceImg.service7,
    },
  ];

  return (
    <div className="w-full h-full bg-[#F2F4F4]">
      <div className="container-lg w-full h-full flex flex-col items-center">
        <SectionHeaders
          contentPrimary={<div className='overflow-hidden'>Transform Your Vision into Reality<br/> Leverage the Power of AI and ML for Business Transformation</div>}
          classes={`poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[7.2rem]`}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[6rem] gap-x-[2rem] py-[2rem]">
          {features.map((feature, index) => (
            <div key={index} className="flex items-start space-x-[4rem]">
              <div className="w-[10rem] h-[10rem] flex-shrink-0">
                <img src={feature.imgSrc} alt={feature.title} className="w-full h-full object-contain" />
              </div>
              <div className='w-[80%] text-start'>
                <h2 className="text-[2.8rem] poppins-semibold text-[#000]">{feature.title}</h2>
                <p className="text-[#8A929A] text-[2.2rem] poppins-regular mt-2">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className='py-[4rem]'>
          <SelectButton
            title={'Transform Your Idea Today'}
            bgColor="bg-[#00D47E]"
            width="w-[37.9rem]"
            textSize="text-[1.6rem]"
            fontWeight="poppins-regular"
            titleColor={`text-white hover:text-[#2B2B2B]`}
            height="h-[4.7rem]"
            onClick={scrollToSection}
          />
        </div>
      </div>
    </div>
  );
}

export default Ai_Ml_Services2;
import React from 'react';
import { AboutUsImg } from '../../../static/constants/imageConstant';

const AboutUsLanding = () => {
  return (
    <div className='w-full h-full'>
      <div className='relative w-full'>
        <img src={AboutUsImg.aboutus1} alt="About Us" className="w-full h-full" />
        <div className='absolute inset-0 flex items-center justify-center'>
          <h1 className='text-[8rem] poppins-extralight text-white'>About Us</h1>
        </div>
      </div>
      <div className='container-lg text-center mt-[7rem] mb-[10rem]'>
        <p className='text-[#8A929A] text-[2.5rem] poppins-medium mx-[5rem]'>
        At Infominez, we drive innovation and technology to create real business value. Our team of forward-thinking technologists is dedicated to transforming digital possibilities into tangible outcomes. With a mission to empower businesses, we help them reach their full digital potential and embrace fearless innovation.        </p>
      </div>
    </div>
  );
}

export default AboutUsLanding;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ServiceImg } from '../../../static/constants/imageConstant';
import { FaArrowRight } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { strapiBlogs } from '../../../Store/actions/strapiBlogsActions';
import { strapi_base_url } from '../../../Store/constants';
import moment from 'moment/moment';
import { case_studies } from '../../../Store/actions/case_studiesActions';
import { white_paper } from '../../../Store/actions/white_paperActions';
import { news_event } from '../../../Store/actions/news_eventActions';

const Insights = () => {
    const { blogId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const strapiBlogsReducerData = useSelector((state) => state.strapiBlogsReducer?.data);
    // const case_studiesReducerData = useSelector((state) => state.case_studiesReducer?.data);
    const white_paperReducerData = useSelector((state) => state.white_paperReducer?.data);
    const news_eventReducerData = useSelector((state) => state.news_eventReducer?.data);

    const [selectedCategory, setSelectedCategory] = useState('Blog');

    useEffect(() => {
        dispatch(strapiBlogs());
        // dispatch(case_studies());
        dispatch(white_paper());
        dispatch(news_event());
    }, []);

    console.log("strapiBlogsReducerData :", strapiBlogsReducerData?.data);
    // console.log("case_studiesReducerData :", case_studiesReducerData?.data);
    console.log("white_paperReducerData :", white_paperReducerData?.data);
    console.log("news_eventReducerData :", news_eventReducerData?.data);
    console.log("blogId :", blogId);


    return (
        <div className="container-lg w-full h-full py-[4rem]">
            <h1 className="text-[6.3rem] text-[#001736] poppins-extralight text-center mb-8">Read Our Latest Insights</h1>
            <div className="w-full flex justify-between lg:justify-center mb-[4rem] lg:space-x-[10rem]">
                {['Blog'].map((category, index) => (
                    <div key={index} className="flex flex-col">
                        <button
                            className={`text-[2rem] text-[#010101] pb-1 ${category === selectedCategory ? 'poppins-bold' : 'poppins-regular'}`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {category}
                        </button>
                        {category === selectedCategory && <div className="bg-[#00D47E] rounded-full w-full h-[.7rem]"></div>}
                    </div>
                ))}
            </div>

            <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-[8rem]">
                {(selectedCategory === "Blog" ? strapiBlogsReducerData?.data : selectedCategory === "Whitepaper" ? white_paperReducerData?.data : selectedCategory === "News Event" ? news_eventReducerData?.data : [])?.length > 0 ?
                    (selectedCategory === "Blog" ? strapiBlogsReducerData?.data?.slice(0, 3) : selectedCategory === "Whitepaper" ? white_paperReducerData?.data?.slice(0, 3) : selectedCategory === "News Event" ? news_eventReducerData?.data?.slice(0, 3) : [])?.map((item, index) => (
                        <div
                            key={index}
                            className="border rounded-[.1rem] overflow-hidden md:block flex justify-center items-center p-[2.8rem]"
                        >
                            <div className='w-full h-[23.3rem]'>
                                <img
                                    src={`${strapi_base_url + `/api_img`}${item?.attributes?.main_image?.data.attributes.url}`}
                                    alt="Insight Thumbnail"
                                    className="w-full h-full"
                                />
                            </div>
                            <div>
                                <div className="flex items-center justify-between text-gray-600 my-[1.8rem]">
                                    <div className="w-full flex items-center gap-[1rem]">
                                        <div className='w-[13.8%]'>
                                            <div className='w-[5rem] h-[5rem]'>
                                                <img
                                                    src={ServiceImg.service9}
                                                    alt="Author"
                                                    className="w-full h-full rounded-full"
                                                />
                                            </div>
                                        </div>
                                        <div className='w-[85%] flex justify-between'>
                                            <span className='text-[1.8rem] text-[#010001] poppins-regular'>{item?.attributes?.author_name}</span>
                                            <span className='text-[#535B63] text-[1.8rem] poppins-regular'>{moment(item?.attributes?.updatedAt).format("DD-MMM-YYYY")}</span>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="text-[2.2rem] text-[#202020] poppins-semibold mb-[1rem]">
                                    {item.attributes.main_title}
                                </h2>
                                <div className="flex items-center">
                                    <div
                                        className="flex justify-center items-center w-[4.2rem] h-[4.2rem] bg-[#00D47E] rounded-full mr-[1.2rem] cursor-pointer"
                                        onClick={() => navigate('/blog_details/' + item.id)}
                                    >
                                        <FaArrowRight size={16} color='#FFF' />
                                    </div>
                                    {selectedCategory === 'Blog' ?
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/blog_details/' + item.id);
                                        }} className="text-[#2666CF] text-[1.8rem] poppins-medium hover:underline">Read More</a>
                                        :
                                        <a href="#" className="text-[#2666CF] text-[1.8rem] poppins-medium hover:underline">Comming soon</a>
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div className="w-full">
                        <div className="text-[2.2rem] text-[#202020] poppins-semibold text-center">
                            No data available right now!
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Insights;

import React, { useEffect } from 'react';
import { strapi_base_url } from '../../../Store/constants';
import { useDispatch, useSelector } from 'react-redux';
import { case_studies } from '../../../Store/actions/case_studiesActions';
import { useNavigate } from 'react-router-dom';

const CasePage1 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const data = useSelector((state) => state.case_studiesReducer?.data || []);

    useEffect(() => {
        dispatch(case_studies({}));
    }, []);

    console.log("================================data  =================================", data);

    return (
        <>
            <div className="container-lg w-full h-full bg-[#fff] flex flex-col py-[4rem] mb-[8rem]">
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-[2rem] mt-[4rem]">
                    {data?.map((row) => {
                        const attributes = row.attributes;
                        const imageUrl = attributes.thumbnail_image?.data?.attributes?.url;

                        return (
                            <div key={row.id} className="w-full flex flex-col bg-[#fff] shadow-xl overflow-hidden">
                                <div className="w-full flex items-center">
                                    <div className="w-[78.3rem] h-[38.7rem] px-[2rem] py-[2rem]">
                                        <img
                                            src={`${strapi_base_url}/api_img${imageUrl}`}
                                            alt="Card Image"
                                            className="w-full h-full"
                                        />
                                    </div>
                                </div>
                                <div className="p-6 px-[2rem]">
                                    <h2 className="text-[3.5rem] text-[#202020] poppins-semibold">
                                        {attributes.Title}
                                    </h2>
                                    <div className="flex flex-wrap gap-[0.8rem] mt-4">
                                        <div className="bg-[#F4F7FA] rounded-full p-[0.8rem]">
                                            <button className="text-[2.0rem] text-[#020B04] poppins-medium px-[1rem]">
                                                WEB DEVELOPMENT
                                            </button>
                                        </div>
                                        <div className="bg-[#F4F7FA] rounded-full p-[0.8rem]">
                                            <button className="text-[2.0rem] text-[#020B04] poppins-medium px-[1rem]">
                                                STRATEGY
                                            </button>
                                        </div>
                                        <div className="bg-[#F4F7FA] rounded-full p-[0.8rem]">
                                            <button className="text-[2.0rem] text-[#020B04] poppins-medium px-[1rem]">
                                                DESIGN
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex justify-between py-[4rem]">
                                        <button
                                            className="text-[#12C140] text-[1.8rem] mt-4 poppins-medium underline decoration-[#12C140]"
                                            onClick={() => navigate('/case-study-detail/' + row.id)}
                                        >
                                            Read More
                                        </button>
                                        <span className="text-[#535B63] text-[1.8rem] poppins-regular mt-4">
                                            23-May-2023
                                        </span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default CasePage1;

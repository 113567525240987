import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleWare from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleWare();

const store = compose(
    applyMiddleware(sagaMiddleware),
)(createStore)(rootReducer);

sagaMiddleware.run(rootSaga)

export default store;

export const persistor = persistStore(store);
import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../constants';
import { AxiosService } from '../services';
import { actionTypes } from '../types';
import { contactUsv2Failure, contactUsv2Success } from '../actions/contactUsV2Actions';

function* contactUsv2({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.contactUsV2,
            data: payload,
            isAuth: false,
            ContentType: 'multipart/form-data',
        });

        console.log('====================================');
        console.log("CONTACT_US_V2_SUCCESS status :", response.status);
        console.log("CONTACT_US_V2_SUCCESS data :", response.data);
        console.log("CONTACT_US_V2_SUCCESS message :", response.data.message);
        console.log('====================================');
        yield put(contactUsv2Success({
            status: response?.status,
            data: response?.data,
            message: response?.data.message
        })
        )
    } catch (error) {
        console.log('====================================');
        console.log("CONTACT_US_V2_FAILURE error :", error.response?.status);
        console.log("CONTACT_US_V2_FAILURE message :", error.response?.data.message);
        console.log("CONTACT_US_V2_FAILURE error :", error);
        console.log('====================================');
        yield put(contactUsv2Failure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* contactUsv2Saga() {
    yield takeLatest(actionTypes.CONTACT_US_V2_REQUEST, contactUsv2);
}

export default contactUsv2Saga;
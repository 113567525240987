import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import SelectButton from "../../common/SelectButton/SelectButton";


const CloudEngineering4 = () => {
  const dataPoints = [
    {
      title: "1. Cloud Solution Design",
      description:
        "We architect custom cloud solutions that align with your business goals, delivering efficiency, performance, and cost savings. ",
    },
    {
      title: "2. Cloud Infrastructure Development ",
      description:
        "Our team builds robust, scalable cloud infrastructures that evolve with your business, ensuring smooth operations and minimal downtime. ",
    },
    {
      title: "3. Cloud Migration and Integration ",
      description:
        "We seamlessly transition your business to the cloud with minimal disruption, ensuring seamless integration with existing systems. ",
    },
    {
      title: "4. DevOps and Cloud Automation ",
      description:
        "Automate development, testing, and deployment with our cloud-powered DevOps solutions, allowing your business to innovate faster. ",
    },
    {
      title: "5. Cloud Security and Compliance ",
      description:
        "Our security experts ensure that your cloud environment is protected with state-of-the-art security measures and compliance with industry regulations. ",
    },
    // {
    //   title: "Tailored Solutions",
    //   description:
    //     "We work with businesses across industries, delivering solutions specific to your business needs.",
    // },
  ];

  return (
    <div className="w-full h-full bg-[#F2F4F4] py-[5rem] mb-[8rem]">
      <div className="container-lg mx-auto flex flex-col items-center text-center">
        <SectionHeaders
          contentPrimary={
            <>
              Our Cloud Engineering Services<br />
              Tailored solutions for every stage of your cloud journey.
            </>
          }
          classes="poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] text-center my-[3.7rem]"
        />

        <p className="text-[1.8rem] md:text-[2.5rem] text-[#8A929A] poppins-regular mt-[2rem]">
          At Infominez, we offer a full range of cloud engineering services designed to drive your
          business’s success. From infrastructure development to security and compliance, we’ve got you covered.
        </p>

        {/* <h1 className="text-[#000000] text-[3rem] poppins-medium py-[3.9rem]">
          What Sets Us Apart
        </h1> */}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] md:gap-[4rem] mt-[9rem]">
          {dataPoints.map((point, index) => (
            <div
              key={index}
              className="flex items-start gap-[1rem]"
            >
              <div className="w-[5%]"><div className="w-[1.2rem] h-[1.2rem] rounded-full bg-[#2666CF] mt-[1rem]" /></div>
              <div className="w-[94%] text-start ">
                <h3 className="text-[2.8rem] poppins-medium text-[#3C436A] mb-[0.8rem]">
                  {point.title}
                </h3>
                <p className="text-[2.2rem] text-[#8A929A] poppins-regular">
                  {point.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* <div className='py-[4rem]'>
          <SelectButton
            title={'Discover Our Full Range of Services'}
            bgColor="bg-[#00D47E]"
            width="w-[37.9rem]"
            textSize="text-[1.6rem]"
            fontWeight="poppins-regular"
            titleColor={`text-white hover:text-[#2B2B2B]`}
            height="h-[4.7rem]"
          />
        </div> */}
      </div>
    </div>
  );
};

export default CloudEngineering4;
import React from 'react';
import "./IndustryHealthCare1.css";
import BtnOutlined from '../../common/BtnOutlined/BtnOutlined';
import backgroundImage from "../../../static/assets/img/backgrounds/industries-landing-bg.jpg";

const IndustryHealthCare1 = () => {
    return (
        <div>
            <div className="industryhealth w-full h-[63rem] flex flex-col items-center justify-center" style={{backgroundImage: `url(${backgroundImage})`}}>
                <div className="container-lg flex flex-col items-center justify-center text-center text-white px-4">
                    <h1 className="text-[2.5rem] sm:text-[3rem] lg:text-[5.6rem] poppins-thin overflow-hidden">
                    Optimal Health: Your Wellness, Our Priority
                    </h1>
                    <p className="text-[2.4rem] md:text-[2.7rem] lg:text-[3.2rem] poppins-light">
                    Achieve holistic well-being with personalized healthcare solutions, designed to empower you with advanced technology and expert care. 
                    </p>
                    <div className='flex justify-center mt-[4rem]'>
                        <BtnOutlined
                            title="Learn More"
                            width="w-[29.4rem]"
                            textSize="text-[2.2rem]"
                            classNames={"border-2 rounded-full"}
                            titleColor={`text-white hover:text-[#0E0731]`}
                            borderColor="border-[#00D47E]"
                            bgColor={`bg-inherit text-white hover:bg-[#00D47E]`}
                            fontWeight={"poppins-medium"}
                            ButtonHeight={"h-[8rem]"}
                            hasArrow
                        //onClick={() => { navigate("/contact-us") }}
                        />
                    </div>
                </div>
            </div>
            <div className="container-lg bg-white text-center">
                <p className="text-[#8A929A] text-[2.5rem] sm:text-[2rem] md:text-[2.4rem] py-[9rem]">
                We provide tailored healthcare and wellness solutions utilizing the latest in medical technology and expert insights to guide you on your wellness journey. Our approach integrates cutting-edge technology with personalized care, making us your strategic partner in achieving optimal health outcomes
                </p>
            </div>
        </div>
    )
}

export default IndustryHealthCare1;
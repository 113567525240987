import React from 'react';
import Blog_b_Page1 from '../../components/main/Blog_b_Page1/Blog_b_Page1';
import Blog_b_Page2 from '../../components/main/Blog_b_Page2/Blog_b_Page2';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';

const Blog_b_Page = () => {
  return (
    <div>
      <Blog_b_Page1 />
      <Blog_b_Page2 />
      <GetStartedDetailsForm />
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default Blog_b_Page
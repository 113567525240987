import React from 'react';
import { AboutUsImg } from '../../../static/constants/imageConstant';
import { IoLogoLinkedin } from 'react-icons/io';

const teamMembers = [
    { name: 'Ajit', title: '', img: AboutUsImg.ajit, linkedin: 'https://www.linkedin.com/in/ajitmaya' },
    { name: 'Kevin', title: '', img: AboutUsImg.kevin, linkedin: 'https://www.linkedin.com/in/kevinsurmali' },
    { name: 'Anand', title: '', img: AboutUsImg.andy, linkedin: 'https://www.linkedin.com/in/anandinfominez' },
    { name: 'Sanjay', title: '', img: AboutUsImg.sanjay, linkedin: 'https://www.linkedin.com/in/sanjay-gawatiya-7b7585102' },
    { name: 'Aditya', title: '', img: AboutUsImg.aditya, linkedin: 'https://www.linkedin.com/in/aditya-shukla-629372120' },
    { name: 'Mayank', title: '', img: AboutUsImg.mayank, linkedin: 'https://www.linkedin.com/in/mayank-namdev-5111551b8' },
    { name: 'Rajdeep', title: '', img: AboutUsImg.rajdeep, linkedin: 'https://www.linkedin.com/in/rajdip-biswas-468401250' },
    // { name: 'Akshay', title: '', img: AboutUsImg.akshay, linkedin: 'https://www.linkedin.com/in/akshay-jadia-b11877bb' },
    { name: 'Pratik', title: '', img: AboutUsImg.pratik, linkedin: 'https://www.linkedin.com/in/pratikjat' },
    { name: 'Raksha', title: '', img: AboutUsImg.raksha, linkedin: 'https://www.linkedin.com/in/raksha-verma/' },
    { name: 'Chhavinath', title: '', img: AboutUsImg.chhavinath, linkedin: 'https://www.linkedin.com/in/schhavi1922' },
    { name: 'Harshit', title: '', img: AboutUsImg.harshit, linkedin: 'https://www.linkedin.com/in/harshit-tomar-24a14b212' },
    { name: 'Tarun', title: '', img: AboutUsImg.tarun, linkedin: 'https://www.linkedin.com/in/tarun-jat-15526a240' },
    { name: 'Kapil', title: '', img: AboutUsImg.kapil, linkedin: 'https://www.linkedin.com/in/dr-kapil-k-kushwah-24b525254' },
    { name: 'Monesh', title: '', img: AboutUsImg.monesh, linkedin: 'https://www.linkedin.com/in/monesh-jain-88ab50228' },
    { name: 'Nagendra', title: '', img: AboutUsImg.nagendra, linkedin: 'https://www.linkedin.com/in/nagendra-sharma-567578174' },
    { name: 'Alok', title: '', img: AboutUsImg.alok, linkedin: 'https://www.linkedin.com/in/drexzer' },
    { name: 'Aditya', title: '', img: AboutUsImg.aditya1, linkedin: 'https://www.linkedin.com/in/aditya-desai-9180a61a5' },
    { name: 'Pankaj', title: '', img: AboutUsImg.pankaj, linkedin: 'https://www.linkedin.com/in/pankaj-gupta-b14a241bb' },
    { name: 'Jay', title: '', img: AboutUsImg.jay, linkedin: 'https://www.linkedin.com/in/jay-sahu-47325a236' },
    { name: 'Anita', title: '', img: AboutUsImg.anita, linkedin: 'https://www.linkedin.com/in/anitha-balan-b8480822a' },
    { name: 'Rashmi', title: '', img: AboutUsImg.rashmi, linkedin: 'https://www.linkedin.com/in/rashmi-sharma-ba1b142b8' },
];

const OurLeadershipTeam = () => {
    return (
        <div className="container-lg w-full h-full flex flex-col items-center bg-white">
            <div className="w-full flex flex-col items-center mb-10">
                <h1 className="text-[#000] text-[7rem] poppins-extralight">Our Leadership Team</h1>
                <p className="text-[#979EA5] text-[2.5rem] text-center poppins-regular">
                    Our team is our greatest asset. With expertise in various technological domains, our team members are
                    the driving force behind our innovative solutions. Meet some of our key team members:
                </p>
            </div>

            <div className="w-full flex items-center justify-between flex-wrap">
                {teamMembers.map((member, index) => (
                    <div key={index} className="w-[48%] lg:w-[30%] flex flex-col items-center text-center group">
                        <div className="w-[36.8rem] h-[36.8rem]">
                            <img
                                src={member.img}
                                alt={member.name}
                                className="w-full h-full rounded-full grayscale-img hover:border-[.7rem] border-[#41DF6B] transition duration-300"
                            />
                        </div>
                        <div
                            className={`flex justify-center items-center mt-[1.6rem] ${
                                member.title ? 'mb-[.4rem]' : 'mb-[7.2rem]'
                            }`}
                        >
                            <h3 className="text-[#06304C] text-[3.2rem] poppins-regular mr-[1.2rem]">
                                {member.name}
                            </h3>
                            {member.linkedin && (
                                <a
                                    href={member.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="hover:cursor-pointer"
                                >
                                    <IoLogoLinkedin size={34} color="#0078b7" />
                                </a>
                            )}
                        </div>
                        {member.title && (
                            <p className="text-[#06304C] text-[2.2rem] poppins-regular mb-[7.2rem]">
                                {member.title}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurLeadershipTeam;

import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClientTestimonialSectionClientIcon, ClientTestimonialSectionClientImage, ServiceImg } from '../../../static/constants/imageConstant';
import { FaArrowLeft, FaArrowRight, FaLinkedin, FaTwitter } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../ClientTestimonial/ClientTestimonial.css";
import SelectButton from '../../common/SelectButton/SelectButton';
import { FaXTwitter } from 'react-icons/fa6';

const ClientTestimonial1 = () => {

  const sliderRef = useRef();
  const navigate = useNavigate();

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} flex justify-center items-center w-[30%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick} >
        <button className="w-[5rem] h-[5rem] rounded-full border-[2px] border-[#21CE70] hover:bg-[#21CE70] text-[#21CE70] hover:text-black flex items-center justify-center">
          <FaArrowLeft size={20} className='' />
        </button>
      </div>
    );
  };

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} flex justify-center items-center w-[30%] md:w-[10%] h-[20%] z-[10]`} onClick={onClick}>
        <button className="w-[5rem] h-[5rem] rounded-full border-[2px] border-[#21CE70] hover:bg-[#21CE70] text-[#21CE70] hover:text-black flex items-center justify-center">
          <FaArrowRight size={20} className='' />
        </button>
      </div>
    );
  };

  const gotoNext = () => {
    sliderRef.current.slickNext();
  }

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  }


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 6000,
    centerPadding: '0'
  }

  return (
    <div className='bg-gredient-1'>
      <div className="container-lg">
        <div className='w-full flex flex-col mt-[4.8rem]'>
          <h1 className="text-[6rem] poppins-extralight">Client Testimonial</h1>
          <p className="poppins-medium text-[2.5rem] text-[#8A929A]">What they say about Us</p>
        </div>
        <div className="flex justify-end items-end gap-[1rem] px-[8rem]">
          <button
            className="w-[5rem] h-[5rem] rounded-full border-[2px] text-black border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center"
            onClick={gotoPrev}
          >
            <FaArrowLeft size={20} />
          </button>
          <button
            className="w-[5rem] h-[5rem] rounded-full border-[2px] text-black border-[#21CE70] hover:bg-[#21CE70] hover:text-[#FFFFFF] flex items-center justify-center"
            onClick={gotoNext}
          >
            <FaArrowRight size={20} />
          </button>
        </div>
        <div className="w-full h-full">
          <Slider ref={sliderRef} {...settings} className="overflow-hidden pt-[1rem] pb-[10rem] px-[8rem] relative">
            <div className="rounded-[1.1rem] w-full h-[55%] bg-white px-[8.4rem] pb-[1rem] overflow-hidden">
              <div className="flex flex-col md:flex-row gap-[4.4rem] items-center">
                <div className="relative w-[35rem] h-[50rem] flex flex-col justify-end">
                  <div className="w-[35rem] h-[45rem] rounded-[1rem] absolute bottom-[3rem]">
                    <img
                      src={ClientTestimonialSectionClientImage.client1}
                      alt=""
                      className="rounded-[1rem]"
                    />
                  </div>
                  <div className='flex justify-center items-center gap-[2.3rem]'>
                    <a
                      href="https://www.linkedin.com/in/iamsonalibansal?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className='flex justify-center items-center gap-[0.8rem]'>
                        <p className='text-[2.1rem]'><FaLinkedin /></p>
                        <h2 className="text-[1.5rem] text-[#3E4642] text-center montserrat-bold">LinkedIn</h2>
                      </button>
                    </a>
                    <a
                      href="https://x.com/bansal_sonali?s=21"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex justify-center items-center gap-[0.8rem]"
                    >
                      <p className="text-[2.1rem]">
                        <FaXTwitter />
                      </p>
                      <h2 className="text-[1.5rem] text-[#3E4642] text-center montserrat-bold">
                        Twitter
                      </h2>
                    </a>
                  </div>
                </div>
                <div className="w-full md:w-[60%] flex flex-col justify-start items-start">
                  <div className="flex justify-between items-start">
                    <img src={ClientTestimonialSectionClientIcon.quates} alt="" className="w-[50rem] h-[4.7rem] mr-[2rem]" />
                    <div className='flex flex-col'>
                      <h1 className='text-[2rem] my-[2rem] md:mt-0 text-[#5D656D] dm-sans-medium-italic'>Infominez has been an invaluable strategic technology partner for Paul Merchants, guiding us through the complex landscape of digital financial services. Their expertise in developing robust solutions for Prepaid Instruments, Pre-Paid Cards, BBPS, and UPI, along with a deep understanding of integration protocols and payment gateways, has been crucial to our success. Their strong grasp of the Indian fintech ecosystem has enabled them to deliver innovative and compliant solutions tailored to our needs. Moreover, Infominez's commitment to agile development methodologies and their ability to adapt swiftly to evolving regulatory requirements have been indispensable to our business. We highly value our partnership and look forward to continued collaboration.</h1>
                      <div className="border border-t border-[#2666CF] h-[1px] w-full mt-5"></div>
                      <div className="flex flex-col justify-start items-start">
                        <div className="text-[#000000] text-[3.2rem] montserrat-semibold">
                          SONALI BANSAL
                        </div>
                        <div className="text-[1.8rem] montserrat-semibold text-[#000000]">
                          Whole Time Director at Paul Merchants Finance
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-[1.1rem] w-full h-[55%] bg-white px-[8.4rem] pb-[1rem] overflow-hidden">
              <div className="flex flex-col md:flex-row gap-[4.4rem] items-center">
                <div className="relative w-[35rem] h-[50rem] flex flex-col justify-end">
                  <div className="w-[35rem] h-[45rem] rounded-[1rem] absolute bottom-[3rem]">
                    <img
                      src={ClientTestimonialSectionClientImage.client2}
                      alt=""
                      className="rounded-[1rem]"
                    />
                  </div>
                  <div className='flex justify-center items-center gap-[2.3rem]'>
                    <a
                      href="https://www.linkedin.com/in/shaibu-cherian-6a098019?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className='flex justify-center items-center gap-[0.8rem]'>
                        <p className='text-[2.1rem]'><FaLinkedin /></p>
                        <h2 className="text-[1.5rem] text-[#3E4642] text-center montserrat-bold">LinkedIn</h2>
                      </button>
                    </a>
                    <a
                      href="https://x.com/shaibugc?s=21"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex justify-center items-center gap-[0.8rem]"
                    >
                      <p className="text-[2.1rem]">
                        <FaXTwitter />
                      </p>
                      <h2 className="text-[1.5rem] text-[#3E4642] text-center montserrat-bold">
                        Twitter
                      </h2>
                    </a>
                  </div>
                </div>
                <div className="w-full md:w-[60%] flex flex-col justify-start items-start">
                  <div className="flex justify-between items-start">
                    <img src={ClientTestimonialSectionClientIcon.quates} alt="" className="w-[50rem] h-[4.7rem] mr-[2rem]" />
                    <div className='flex flex-col'>
                      <h1 className='text-[2rem] my-[2rem] md:mt-0 text-[#5D656D] dm-sans-medium-italic'>Infominez has been an invaluable strategic technology partner for Paul Merchants, guiding us through the complex landscape of digital financial services. Their expertise in developing robust solutions for Prepaid Instruments, Pre-Paid Cards, BBPS, and UPI, along with a deep understanding of integration protocols and payment gateways, has been crucial to our success. Their strong grasp of the Indian fintech ecosystem has enabled them to deliver innovative and compliant solutions tailored to our needs. Moreover, Infominez's commitment to agile development methodologies and their ability to adapt swiftly to evolving regulatory requirements have been indispensable to our business. We highly value our partnership and look forward to continued collaboration.</h1>
                      <div className="border border-t border-[#2666CF] h-[1px] w-full mt-5"></div>
                      <div className="flex flex-col justify-start items-start">
                        <div className="text-[#000000] text-[3.2rem] montserrat-semibold">
                          SHAIBU CHERIAN
                        </div>
                        <div className="text-[1.8rem] montserrat-semibold text-[#000000]">
                          Whole Time Director at Paul Merchants Finance
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-[1.1rem] w-full h-[55%] bg-white px-[8.4rem] pb-[1rem] overflow-hidden">
              <div className="flex flex-col md:flex-row gap-[4.4rem] items-center">
                <div className="relative w-[35rem] h-[50rem] flex flex-col justify-end">
                  <div className="w-[35rem] h-[45rem] rounded-[1rem] absolute bottom-[3rem]">
                    <img
                      src={ClientTestimonialSectionClientImage.client3}
                      alt=""
                      className="rounded-[1rem]"
                    />
                  </div>
                  <div className='flex justify-center items-center gap-[2.3rem]'>
                    <a
                      href="https://www.linkedin.com/in/manohar-bhoi-7609361a8?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className='flex justify-center items-center gap-[0.8rem]'>
                        <p className='text-[2.1rem]'><FaLinkedin /></p>
                        <h2 className="text-[1.5rem] text-[#3E4642] text-center montserrat-bold">LinkedIn</h2>
                      </button>
                    </a>
                    <a
                      href="https://x.com/manoharbhoi14?s=21"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex justify-center items-center gap-[0.8rem]"
                    >
                      <p className="text-[2.1rem]">
                        <FaXTwitter />
                      </p>
                      <h2 className="text-[1.5rem] text-[#3E4642] text-center montserrat-bold">
                        Twitter
                      </h2>
                    </a>
                  </div>
                </div>
                <div className="w-full md:w-[60%] flex flex-col justify-start items-start">
                  <div className="flex justify-between items-start">
                    <img src={ClientTestimonialSectionClientIcon.quates} alt="" className="w-[50rem] h-[4.7rem] mr-[2rem]" />
                    <div className='flex flex-col'>
                      <h1 className='text-[2rem] my-[2rem] md:mt-0 text-[#5D656D] dm-sans-medium-italic'>Collaborating with Infominez on a range of projects, such as incident management systems and mobile applications, has been an exceptional experience. Their team consistently demonstrated a high level of expertise and dedication, delivering a comprehensive suite of services that addressed our diverse needs. From enhancing user interfaces to developing specialized modules, Infominez provided solutions that were both innovative and effective. Their meticulous approach and commitment to quality ensured that every project was executed with precision and efficiency. The team's professionalism, expertise, and willingness to assist at all times made the process run smoothly and successfully. I am highly impressed with their work and look forward to continuing our partnership in future collaborations.</h1>
                      <div className="border border-t border-[#2666CF] h-[1px] w-full mt-5"></div>
                      <div className="flex flex-col justify-start items-start">
                        <div className="text-[#000000] text-[3.2rem] font-[700]">
                          MANOHAR BHOI
                        </div>
                        <div className="text-[1.8rem] montserrat-semibold text-[#000000]">
                          COO, EPS (Electronic Payment And Services Pvt. Ltd.)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ClientTestimonial1;

import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import SectionHeaders from '../Headers/SectionHeaders';
import SelectButton from '../SelectButton/SelectButton';


function IndustriesSecCard({ id, title, content, iconLight, path, selectId, setSelectId }) {
    const navigate = useNavigate();
    
    console.log("selectId :", selectId);
    
    return (
        <div className={`cursor-pointer relative h-[49rem] my-[1rem] bg-[#FFFFFF] flex flex-col justify-between items-center rounded-[1rem] transition-all overflow-hidden duration-600 ease-in-out ${id !== selectId ? "w-[60rem] lg:w-[31rem]" : "w-[60rem] lg:w-[71rem]"} `}
            onMouseEnter={() => setSelectId(id)}
        >
        {
            id == selectId ? 
            <div className='hidden lg:flex'>
                <div className="flex flex-col justify-center items-center my-[4.5rem] ">
                    <div className="flex justify-center items-center overflow-hidden">
                        <div className="w-[10rem] h-[10rem] mx-[2rem]">
                            <img 
                                src={iconLight}  
                                className='w-full h-full'
                            />
                        </div>
                        <div className="flex flex-col overflow-hidden">
                            <SectionHeaders
                                contentPrimary={title}
                                classes={`poppins-regular text-[2.4rem] text-[#121212] text-start mt-[2rem] overflow-hidden`}
                            />
                            <div className="border-[0.1rem] border-[#D9D9D9] "></div>
                            <div className='poppins-medium text-[2.2rem] text-[#757D85] text-start overflow-hidden'>
                                {content}
                            </div>
                        </div>
                    </div>
                    <div className="poppins-regular text-left text-[2.2rem] text-[#5D656D] w-[85%] my-[3rem] overflow-hidden">
                        Experience the future of finance with our fintech and banking services, designed to accelerate your financial prosperity through seamless, secure, and transformative solutions. Call to Action: Discover        
                    </div>
                    <div className="flex justify-start items-start w-[85%] overflow-hidden">
                            <SelectButton 
                                title={'Discover More'}
                                bgColor={`bg-inherit hover:bg-[#00D47E]`}
                                width="w-[19.8rem]"
                                textSize="text-[1.6rem]"
                                fontWeight="poppins-medium"
                                height="h-[4.7rem]"
                                titleColor={`text-[#000000] hover:text-[#ffffff]`}
                                onClick={() => {navigate(path)}}
                            />
                        </div>
                </div>
            </div>
            :
            <div className='hidden lg:flex'>
                <div className="flex flex-col justify-center items-center my-[4.5rem] w-[90%]">
                    <div className="w-[6rem] h-[6.8rem]">
                        <img 
                            src={iconLight}  
                            className='w-full h-full'
                        />
                    </div>
                    <SectionHeaders
                        contentPrimary={title}
                        classes={`poppins-regular text-[2.4rem] text-[#121212] text-center mt-[2rem] h-[8rem]`}
                    />
                    <div className='poppins-medium text-[2.2rem] text-[#757D85] text-center mt-[2rem]'>
                        {content}
                    </div>
                    <div className="my-[10%]">
                        <SelectButton 
                            title={'Discover More'}
                            bgColor={`bg-inherit hover:bg-[#00D47E]`}
                            width="w-[19.8rem]"
                            textSize="text-[1.6rem]"
                            fontWeight="poppins-medium"
                            height="h-[4.7rem]"
                            titleColor={`text-[#000000] hover:text-[#ffffff]`}
                            onClick={() => {navigate("#")}}
                        />
                    </div>
                </div>
            </div> 
        }
            <div className='flex lg:hidden'>
                <div className="flex flex-col justify-center items-center my-[4.5rem] ">
                    <div className="flex justify-center items-center overflow-hidden">
                        <div className="w-[10rem] h-[10rem] mx-[2rem]">
                            <img 
                                src={iconLight}  
                                className='w-full h-full'
                            />
                        </div>
                        <div className="flex flex-col overflow-hidden">
                            <SectionHeaders
                                contentPrimary={title}
                                classes={`poppins-regular text-[2.4rem] text-[#121212] text-start mt-[2rem] overflow-hidden`}
                            />
                            <div className="border-[0.1rem] border-[#D9D9D9] "></div>
                            <div className='poppins-medium text-[2.2rem] text-[#757D85] text-start overflow-hidden'>
                                {content}
                            </div>
                        </div>
                    </div>
                    <div className="poppins-regular text-left text-[2.2rem] text-[#5D656D] w-[85%] my-[3rem] overflow-hidden">
                        Experience the future of finance with our fintech and banking services, designed to accelerate your financial prosperity through seamless, secure, and transformative solutions. Call to Action: Discover        
                    </div>
                    <div className="flex justify-start items-start w-[85%] overflow-hidden">
                            <SelectButton 
                                title={'Discover More'}
                                bgColor="bg-[#41DF6B]"
                                width="w-[19.8rem]"
                                textSize="text-[1.6rem]"
                                fontWeight="poppins-medium"
                                height="h-[4.7rem]"
                                titleColor={'bg-[#000000] '}
                                onClick={() => {navigate("#")}}
                            />
                        </div>
                </div>
            </div>
        </div>
    )
}

export default IndustriesSecCard
import { put, call, takeLatest } from 'redux-saga/effects';
import { endpoints, strapi_base_url } from '../constants';
import { AxiosService } from '../services';
import { actionTypes } from '../types';
import { white_paperFailure, white_paperSuccess } from '../actions/white_paperActions';

function* white_paper({ payload, callback }) {
    console.log("calling white_paper saga");
    
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: "",
            endPoint: `https://dev.infominez.com/api/articles?populate=*&filters[category][slug][%24eq]=white_paper`,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("WHITE_PAPER_SUCCESS status :", response.status);
        console.log("WHITE_PAPER_SUCCESS data :", response.data);
        console.log("WHITE_PAPER_SUCCESS message :", response.message);
        console.log('====================================');
        yield put(white_paperSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.message
        })
        )
    } catch (error) {
        console.log('====================================');
        console.log("WHITE_PAPER_FAILURE error :", error.response?.status);
        console.log("WHITE_PAPER_FAILURE message :", error.response?.message);
        console.log("WHITE_PAPER_FAILURE error :", error);
        console.log('====================================');
        yield put(white_paperFailure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* white_paperSaga() {
    yield takeLatest(actionTypes.WHITE_PAPER_REQUEST, white_paper);
}

export default white_paperSaga;
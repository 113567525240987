import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import SelectButton from "../../common/SelectButton/SelectButton";
import { AboutUsImg } from "../../../static/constants/imageConstant";

const CloudEngineering2 = () => {
  const services = [
    { name: "Custom Cloud Architectures", icon: AboutUsImg.CloudIcon },
    { name: "Cloud Infrastructure Development", icon: AboutUsImg.InfraIcon },
    { name: "Cloud Migration and Integration", icon: AboutUsImg.MigrationIcon1 },
    { name: "DevOps and Automation", icon: AboutUsImg.DevOpsIcon },
    { name: "Cloud Security and Compliance", icon: AboutUsImg.SecurityIcon },
    { name: "Cloud Migration and Integration", icon: AboutUsImg.MigrationIcon },
  ];

  return (
    <div className="w-full h-full bg-[#F2F4F4] py-[5rem] mb-[8rem]">
      <div className="container-lg mx-auto flex flex-col items-center text-center">
        <SectionHeaders
          contentPrimary={
            <>
              Transform your business through <br /> the power of the cloud.
            </>
          }
          classes="poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[4rem]"
        />

        <p className="text-[1.8rem] md:text-[2.5rem] text-[#8A929A] poppins-regular mt-[2rem]">
          At Infominez, we offer comprehensive cloud solutions that cover every
          stage of your cloud journey—from strategy and design to deployment,
          management, and ongoing optimization. Our solutions empower
          businesses to innovate, streamline operations, and stay competitive
          in an ever-evolving digital landscape.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-[3.6rem] gap-x-[6rem] mt-[5.3rem] poppins-medium mb-[4rem]">
          {services.map((service, index) => (
            <div
              key={index}
              className="text-[2.8rem] text-[#000000] text-start cursor-pointer flex items-center gap-[2rem]"
            >
              <div className="w-[8.5rem] h-[8.5rem]">
              <img
                src={service.icon}
                alt={`${service.name} icon`}
                className="w-full h-full object-contain"
              />
              </div>
              {service.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CloudEngineering2;

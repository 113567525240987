// src/VisionMission.js
import React from "react";
import "./VisionMission.css"
import { AboutUsImg } from "../../../static/constants/imageConstant";
import SectionHeaders from "../../common/Headers/SectionHeaders";


const VisionMission = () => {
    return (
        <div className="vision w-full h-full flex justify-start items-center py-[4rem]">
            <div className="container-lg w-full h-full flex flex-col">
                <div className="w-full h-full mb-[2rem]">
                    <SectionHeaders
                        contentPrimary={'Our Vision and Mission Statement'}
                        classes={`poppins-extralight text-[6rem] items-start text-start mt-[4rem] mb-[1.2rem]`}
                        // contentSecondary={<>Our vision is to empower businesses to unlock their digital potential <br /> and innovate fearlessly. </>}
                        // secondaryClasses={`poppins-light text-[2.5rem] text-[#8A929A] mb-[6rem]`}
                    />
                    <div className="w-full md:w-[72%] flex flex-col gap-[4rem] justify-center items-center mt-[6.7rem] mb-[8rem]">
                        <div className="w-full h-full flex items-center justify-center bg-[#F2F4F4] rounded-[1.6rem] px-[4rem]">
                            <div className="w-[13rem] mx-[2rem]">
                                <img src={AboutUsImg.arrow} alt="Vision" className="w-full" />
                            </div>
                            <div className="w-full p-6 lg:p-7 text-start">
                                <h2 className="text-[3.5rem] poppins-medium text-[#121212] mb-2">Our Vision</h2>
                                <div className="w-full flex justify-center items-center">
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular text-start">
                                        To be the catalyst for fearless innovation, empowering businesses to harness
                                        the power of technology, transform challenges into opportunities, and lead in
                                        a digital-first world. We envision a future where every organization thrives
                                        through sustainable growth and continuous transformation.</p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full h-full flex items-center justify-center bg-[#F2F4F4] rounded-[1.6rem] px-[2rem]">
                            <div className="w-full p-6 rounded-lg start px-[4rem]">
                                <h2 className="text-[3.5rem] poppins-medium text-[#121212] mb-2">Our Mission</h2>
                                <div className="w-full flex flex-col justify-center items-center">
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular text-start">
                                        To deliver secure, cutting-edge digital solutions that inspire innovation and
                                        enable our customers to achieve their greatest ambitions. We are committed to:
                                    </p>
                                    <div className="flex flex-col px-[1.5rem]">
                                        <div className="flex">
                                            <span className="px-[1rem] text-[#707F96] text-[2.2rem]">•</span>
                                            <h1 className="text-[#707F96] text-[2.2rem] poppins-regular text-start">Building trust through technology that ensures safety and resilience.</h1>
                                        </div>
                                        <div className="flex">
                                            <span className="px-[1rem] text-[#707F96] text-[2.2rem]">•</span>
                                            <h1 className="text-[#707F96] text-[2.2rem] poppins-regular text-start">Driving progress by redefining industries and fostering long-term success.</h1></div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[13rem] mx-[2rem]">
                                <img src={AboutUsImg.arrow2} alt="Vision" className="w-full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisionMission;

import React from 'react'

function SectionHeaders({ contentPrimary, classes, contentSecondary, secondaryClasses }) {
    return (
        <div className={`section-headers flex flex-col ${classes}`}>
            <div className='leading-tight overflow-hidden'>
                {contentPrimary}
            </div>
            {
                contentSecondary ? 
                <>
                    <div className={`flex  ${secondaryClasses} `}>
                        {contentSecondary}
                    </div>
                </> 
                : 
                <></>
            }
        </div>
    )
}

export default SectionHeaders

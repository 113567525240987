import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import contactUsv1Reducer from "./reducers/contactUsv1Reducer";
import contactUsv2Reducer from "./reducers/contactUsv2Reducer";
import strapiTokenReducer from "./reducers/strapiTokenReducer";
import utilReducers from "./reducers/utilReducers";
import strapiBlogsReducer from "./reducers/strapiBlogsReducer";
import case_studiesReducer from "./reducers/case_studiesReducer";
import white_paperReducer from "./reducers/white_paperReducer";
import news_eventReducer from "./reducers/news_eventReducer";
import technologiesReducer from "./reducers/technologiesReducer";

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: [
        // add reducers to persist data
        'utilReducers',
    ],
};

const appReducer = combineReducers({
    utilReducers: utilReducers,
    contactUsv1Reducer: contactUsv1Reducer,
    contactUsv2Reducer: contactUsv2Reducer,
    strapiTokenReducer: strapiTokenReducer,
    strapiBlogsReducer: strapiBlogsReducer,
    case_studiesReducer: case_studiesReducer,
    white_paperReducer: white_paperReducer,
    news_eventReducer: news_eventReducer,
    technologiesReducer: technologiesReducer,
})

const rootReducers = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return {
            ...state,
            // utilReducers: undefined,
        }
    }

    return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducers);

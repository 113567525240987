import { Provider } from 'react-redux';
import './App.css';
import { ToastProvider } from './components/hooks/useToast';
import Navbar from './components/layouts/Navbar/Navbar';
import ScrollToTop from './components/utils/ScrollToTop';
import AppRoutes from './routes';
import store, { persistor } from './Store/configStore';
import { PersistGate } from 'redux-persist/integration/react';

function App() {
  const showNavbar = true;

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ToastProvider defaultPosition="top-center" defaultDuration={5000}>
          <div className="App">
            <Navbar show={!showNavbar} />
            <AppRoutes />
            {/* ScrollToTop component inside App component */}
            <ScrollToTop />
          </div>
        </ToastProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import { NavbarImg } from '../../../static/constants/imageConstant';
import './Navbar.css'
import BtnOutlined from '../../common/BtnOutlined/BtnOutlined';
import { CgClose } from 'react-icons/cg';
import { HiMenu } from 'react-icons/hi';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoChevronDownOutline } from 'react-icons/io5';

function Navbar() {

    const handleNavigation = (path) => {
        navigate(path);
        setShowServicesDropdown(false); // Close the dropdown after navigation
    };

    const navigate = useNavigate();
    const activePage = window.location.href.split("/")[window.location.href.split("/").length - 1];
    const darkcontent = window.location.href.split("/")[window.location.href.split("/").length - 1] == "contact-us";

    const [isDetailsPage, setIsDetailsPage] = useState(false);

    const [showServicesDropdown, setShowServicesDropdown] = useState(false);
    const [showIndustryDropdown, setShowIndustryDropdown] = useState(false);
    const [showResourcesDropdown, setShowResourcesDropdown] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);
    const [navbarDropdown, setNavbarDropdown] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const renderNavBtn = () => {
        return (
            <BtnOutlined
                title="Get Started"
                width="w-[15rem] ml-[2rem]"
                textSize="text-[1.6rem]"
                classNames={"border-2 rounded-full"}
                titleColor={`${darkcontent && !isScrolled ? 'text-black' : 'text-white'} hover:text-[#0E0731]`}
                borderColor="border-[#00D47E]"
                bgColor={`bg-inherit ${darkcontent && !isScrolled ? 'text-black' : 'text-white'} hover:bg-[#00D47E]`}
                fontWeight={"poppins-semibold"}
                onClick={() => { navigate("/contact-us") }}
            />
        )
    }

    const handleMouseEnter = () => setShowServicesDropdown(true);
    const handleMouseLeave = () => setShowServicesDropdown(false);

    console.log("navbarDropdown ;", navbarDropdown);


    console.log("activePage ;", activePage);
    console.log(isScrolled);

    return (
        <nav id="navbar" className={`navbar ${isScrolled ? 'bg-blue-950' : 'bg-transparent'} ${!isDetailsPage && "navbar-bg"} transition ease-in duration-100 poppins-light flex justify-center items-center w-full h-[10rem] fixed z-[80]`}>
            <div className='nav-container flex justify-between items-center container-lg py-[2rem]'>
                <div
                    className='h-full nav-logo cursor-pointer'
                    onClick={() => { navigate("/") }}
                >
                    <div className='w-[22.8rem] h-[5.2rem]'>
                        <img
                            className='w-full h-full'
                            src={darkcontent && !isScrolled ? NavbarImg.LogoDark : NavbarImg.LogoLight}
                            alt='logo'
                        />
                    </div>
                </div>
                <div className='hidden md:flex justify-end items-center relative'>
                    <div className='flex flex-col justify-between items-center lg:mx-[1.6rem]'>
                        <a className={` ${activePage === "" || activePage === "#" ? 'poppins-bold' : ''}  text-[1.6rem] nav-link ${darkcontent && !isScrolled ? 'text-black' : 'text-white'}  mb-[.4rem]`} href='/'>
                            Home
                        </a>
                        {
                            activePage === "" ||
                                activePage === "#" ?
                                <div className='w-[100%] h-[0.2rem] rounded-[.35rem] bg-[#00D47E]'></div>
                                :
                                ""
                        }
                    </div>
                    <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem] relative' onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        {/* Services Link */}
                        <a
                            className={`
                    ${window.location.href.includes('services-page') ? 'poppins-bold' : ''} 
                    text-[1.6rem] nav-link 
                    ${darkcontent && !isScrolled ? 'text-black' : 'text-white'} 
                    mb-[.4rem]
                `}
                            href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                setShowServicesDropdown((prev) => !prev);
                            }}
                        >
                            <div className="flex justify-between items-center">
                                <div className="mr-2">
                                    Services
                                </div>
                                <IoChevronDownOutline size={18} />
                            </div>
                        </a>
                        {window.location.href.includes('services-page') ? (
                            <div className='w-[100%] h-[0.2rem] rounded-[.3rem] bg-[#00D47E]'></div>
                        ) : (
                            ''
                        )}

                        {/* Dropdown Menu */}
                        {showServicesDropdown && (
                            <div className='fixed top-[6.5rem] w-[200px] bg-blue-950 shadow-lg rounded-lg z-10' onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                                <ul className='flex flex-col p-[1rem]'>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[1.6rem] text-[#fff] poppins-regular'
                                        onClick={() => handleNavigation('/services-product')}
                                    >
                                        Product Engineering
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                        onClick={() => handleNavigation('/ai_ml_services')}
                                    >
                                        AI & ML
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                        onClick={() => handleNavigation('/data-engineering')}
                                    >
                                        Data Engineering
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                        onClick={() => handleNavigation('/cloud-engineering')}
                                    >
                                        Cloud Engineering
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[1.6rem] text-[#fff] poppins-regular'
                                        onClick={() => handleNavigation('/pod-Services')}
                                    >
                                        POD Service
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem] relative' onMouseEnter={() => setShowIndustryDropdown(true)}
                        onMouseLeave={() => setShowIndustryDropdown(false)}>
                        {/* Services Link */}
                        <a
                            className={`
                    ${window.location.href.includes('services-page') ? 'poppins-bold' : ''} 
                    text-[1.6rem] nav-link 
                    ${darkcontent && !isScrolled ? 'text-black' : 'text-white'} 
                    mb-[.4rem]
                `}
                            href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                setShowIndustryDropdown((prev) => !prev);
                            }}
                        >
                            <div className="flex justify-between items-center">
                                <div className="mr-2">
                                    Industries
                                </div>
                                <IoChevronDownOutline size={18} />
                            </div>
                        </a>
                        {window.location.href.includes('services-page') ? (
                            <div className='w-[100%] h-[0.2rem] rounded-[.3rem] bg-[#00D47E]'></div>
                        ) : (
                            ''
                        )}

                        {/* Dropdown Menu */}
                        {showIndustryDropdown && (
                            <div className='fixed top-[6.5rem] w-[200px] bg-blue-950 shadow-lg rounded-lg z-10' onMouseEnter={() => setShowIndustryDropdown(true)}
                                onMouseLeave={() => setShowIndustryDropdown(false)}>
                                <ul className='flex flex-col p-[1rem]'>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[1.6rem] text-[#fff] poppins-regular'
                                        onClick={() => handleNavigation('/industry-fintech-page')}
                                    >
                                        Fintech & Banking
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                        onClick={() => handleNavigation('/industry-e-commerce-retial')}
                                    >
                                        E-Commerce & Retail
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                        onClick={() => handleNavigation('/industry-edutech')}
                                    >
                                        Edutech and Learning
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                        onClick={() => handleNavigation('/industry-health-care')}
                                    >
                                        Health and Wellness
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem] relative' onMouseEnter={() => setShowResourcesDropdown(true)}
                        onMouseLeave={() => setShowResourcesDropdown(false)}>
                        {/* Services Link */}
                        <a
                            className={`
                    ${window.location.href.includes('services-page') ? 'poppins-bold' : ''} 
                    text-[1.6rem] nav-link 
                    ${darkcontent && !isScrolled ? 'text-black' : 'text-white'} 
                    mb-[.4rem]
                `}
                            href='#'
                            onClick={(e) => {
                                e.preventDefault();
                                setShowResourcesDropdown((prev) => !prev);
                            }}
                        >
                            <div className="flex justify-between items-center">
                                <div className="mr-2">
                                    Resources
                                </div>
                                <IoChevronDownOutline size={18} />
                            </div>
                        </a>
                        {window.location.href.includes('services-page') ? (
                            <div className='w-[100%] h-[0.2rem] rounded-[.3rem] bg-[#00D47E]'></div>
                        ) : (
                            ''
                        )}

                        {/* Dropdown Menu */}
                        {showResourcesDropdown && (
                            <div className='fixed top-[6.5rem] w-[200px] bg-blue-950 shadow-lg rounded-lg z-10' onMouseEnter={() => setShowResourcesDropdown(true)}
                                onMouseLeave={() => setShowResourcesDropdown(false)}>
                                <ul className='flex flex-col p-[1rem]'>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[1.6rem] text-[#fff] poppins-regular'
                                        onClick={() => handleNavigation('/all-blogs')}
                                    >
                                        Blog
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                        onClick={() => handleNavigation('/services-page/plan2')}
                                    >
                                        White Paper
                                    </li>
                                    <li
                                        className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                        onClick={() => handleNavigation('/case-study')}
                                    >
                                        Case Studies
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                        <a className={` ${window.location.href.includes('carrer-page') ? "poppins-bold" : ""} nav-link text-[1.6rem] ${darkcontent && !isScrolled ? 'text-black' : 'text-white'}  mb-[.4rem]`} href='/carrer-page'>
                            Career
                        </a>
                        {
                            window.location.href.includes('carrer-page') ?
                                <div className='w-[100%] h-[0.2rem] rounded-[.3rem] bg-[#00D47E]'></div>
                                :
                                ""
                        }
                    </div>
                    <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                        <a className={` ${window.location.href.includes('about-us') ? "poppins-bold" : ""} nav-link text-[1.6rem] ${darkcontent && !isScrolled ? 'text-black' : 'text-white'}  mb-[.4rem]`} href='/about-us'>
                            About Us
                        </a>
                        {
                            window.location.href.includes('about-us') ?
                                <div className='w-[100%] h-[0.2rem] rounded-[.3rem] bg-[#00D47E]'></div>
                                :
                                ""
                        }
                    </div>
                    <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                        <a className={` ${window.location.href.includes('contact-us') ? 'poppins-bold' : ''} text-[1.6rem] nav-link  ${darkcontent && !isScrolled ? 'text-black' : 'text-white'}  mb-[.4rem]`} href='/contact-us'>
                            Contact Us
                        </a>
                        {
                            window.location.href.includes('contact-us') ?
                                <div className='w-[100%] h-[0.2rem] rounded-[.3rem] bg-[#EA4335]'></div>
                                :
                                ""
                        }
                    </div>

                </div>
                <div className="md:block hidden">
                    {renderNavBtn()}
                </div>
                <div className='flex md:hidden justify-end items-center'>
                    {renderNavBtn()}
                    <div className='ham-menu ml-[1.2rem]' onClick={() => setNavbarDropdown(!navbarDropdown)}>
                        {navbarDropdown ?
                            <CgClose size={24} color={`${darkcontent && !isScrolled ? "#000000" : "#ffffff"}`} />
                            :
                            <HiMenu size={24} color={`${darkcontent && !isScrolled ? "#000000" : navbarDropdown ? "#000000" : "#ffffff"}`} />
                        }
                    </div>
                </div>
                {
                    navbarDropdown ?
                        <div className={`md:hidden fixed right-0 top-[10rem] w-[30rem] ${darkcontent ? "bg-white" : "bg-blue-950"} rounded-b-xl z-[80] h-[30rem]`}>
                            <div className="mt-[1rem] flex flex-col justify-center items-start mx-[2rem]">
                                <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                                    <a className={` ${activePage === "" || activePage === "#" ? 'poppins-bold' : ''}  text-[1.6rem] nav-link text-white mb-[.4rem]`} href='/#'>
                                        Home
                                    </a>
                                    {
                                        activePage === "" ||
                                            activePage === "#" ?
                                            <div className='w-[100%] h-[0.2rem] rounded-[.35rem] bg-[#00D47E]'></div>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className="mt-[1rem] flex flex-col justify-center items-start mx-[2rem] relative">
                                <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                                    <a
                                        className={` ${activePage === "" || activePage === "/services-product" ? 'poppins-bold' : ''}  text-[1.6rem] nav-link text-white mb-[.4rem]`}
                                        href='/#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowServicesDropdown(!showServicesDropdown);
                                        }}
                                    >
                                        <div className="flex justify-between items-center">
                                            <div className="mr-2">
                                                Services
                                            </div>
                                            <IoChevronDownOutline size={16} />
                                        </div>
                                    </a>
                                    {
                                        activePage === "" ||
                                            activePage === "services-product" ?
                                            <div className='w-[100%] h-[0.2rem] rounded-[.35rem] bg-[#00D47E]'></div>
                                            :
                                            ""
                                    }
                                    {showServicesDropdown && (
                                        <div className="fixed top-[18rem] right-20 bg-blue-950 text-white shadow-lg rounded-[.5rem] border z-10 w-[150px]">
                                            <ul className='flex flex-col p-[1rem]'>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[1.6rem] text-[#fff] poppins-regular'
                                                    onClick={() => handleNavigation('/industry-fintech-page')}
                                                >
                                                    Fintech & Banking
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                                    onClick={() => handleNavigation('/industry-e-commerce-retial')}
                                                >
                                                    E-Commerce & Retail
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                                    onClick={() => handleNavigation('/industry-edutech')}
                                                >
                                                    Edutech and Learning
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                                    onClick={() => handleNavigation('/industry-health-care')}
                                                >
                                                    Health and Wellness
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[1.6rem] text-[#fff] poppins-regular'
                                                    onClick={() => handleNavigation('/pod-Services')}
                                                >
                                                    POD Service
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mt-[1rem] flex flex-col justify-center items-start mx-[2rem] relative">
                                <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                                    <a
                                        className={` ${activePage === "" || activePage === "/services-product" ? 'poppins-bold' : ''}  text-[1.6rem] nav-link text-white mb-[.4rem]`}
                                        href='/#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowIndustryDropdown(!showIndustryDropdown);
                                        }}
                                    >
                                        <div className="flex justify-between items-center">
                                            <div className="mr-2">
                                                Industries
                                            </div>
                                            <IoChevronDownOutline size={16} />
                                        </div>
                                    </a>
                                    {
                                        activePage === "" ?
                                            <div className='w-[100%] h-[0.2rem] rounded-[.35rem] bg-[#00D47E]'></div>
                                            :
                                            ""
                                    }
                                    {showIndustryDropdown && (
                                        <div className="fixed top-[23rem] right-20 bg-blue-950 text-white shadow-lg rounded-[.5rem] border z-10 w-[150px]">
                                            <ul className='flex flex-col p-[1rem]'>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[1.6rem] text-[#fff] poppins-regular'
                                                    onClick={() => handleNavigation('/industry-fintech-page')}
                                                >
                                                    Fintech & Banking
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                                    onClick={() => handleNavigation('/industry-e-commerce-retial')}
                                                >
                                                    E-Commerce & Retail
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                                    onClick={() => handleNavigation('/industry-edutech')}
                                                >
                                                    Edutech and Learning
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                                    onClick={() => handleNavigation('/industry-health-care')}
                                                >
                                                    Health and Wellness
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mt-[1rem] flex flex-col justify-center items-start mx-[2rem] relative">
                                <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                                    <a
                                        className={` ${activePage === "" || activePage === "/services-product" ? 'poppins-bold' : ''}  text-[1.6rem] nav-link text-white mb-[.4rem]`}
                                        href='/#'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowResourcesDropdown(!showResourcesDropdown);
                                        }}
                                    >
                                        <div className="flex justify-between items-center">
                                            <div className="mr-2">
                                                Resources
                                            </div>
                                            <IoChevronDownOutline size={16} />
                                        </div>
                                    </a>
                                    {
                                        activePage === "" ?
                                            <div className='w-[100%] h-[0.2rem] rounded-[.35rem] bg-[#00D47E]'></div>
                                            :
                                            ""
                                    }
                                    {showResourcesDropdown && (
                                        <div className="fixed top-[27rem] right-20 bg-blue-950 text-white shadow-lg rounded-[.5rem] border z-10 w-[150px]">
                                            <ul className='flex flex-col p-[1rem]'>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[1.6rem] text-[#fff] poppins-regular'
                                                    onClick={() => handleNavigation('/all-blogs')}
                                                >
                                                    Blog
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                                    onClick={() => handleNavigation('/services-page/plan2')}
                                                >
                                                    White Paper
                                                </li>
                                                <li
                                                    className='hover:bg-gray-500 p-2 cursor-pointer text-left text-[#fff] text-[1.6rem] poppins-regular'
                                                    onClick={() => handleNavigation('/case-study')}
                                                >
                                                    Case Studies
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mt-[1rem] flex flex-col justify-center items-start mx-[2rem]">
                                <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                                    <a className={` ${activePage === "" || activePage === "carrer-page" ? 'poppins-bold' : ''}  text-[1.6rem] nav-link text-white mb-[.4rem]`} href='/carrer-page'>
                                        Career
                                    </a>
                                    {
                                        activePage === "" ||
                                            activePage === "carrer-page" ?
                                            <div className='w-[100%] h-[0.2rem] rounded-[.35rem] bg-[#00D47E]'></div>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className="mt-[1rem] flex flex-col justify-center items-start mx-[2rem]">
                                <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                                    <a className={` ${activePage === "" || activePage === "about-us" ? 'poppins-bold' : ''}  text-[1.6rem] nav-link text-white mb-[.4rem]`} href='/about-us'>
                                        About Us
                                    </a>
                                    {
                                        activePage === "" ||
                                            activePage === "about-us" ?
                                            <div className='w-[100%] h-[0.2rem] rounded-[.35rem] bg-[#00D47E]'></div>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                            <div className="mt-[1rem] flex flex-col justify-center items-start mx-[2rem]">
                                <div className='flex flex-col justify-between items-center lg:mx-[1.6rem] mx-[1rem]'>
                                    <a className={` ${activePage === "" || activePage === "constact-us" ? 'poppins-bold' : ''}  text-[1.6rem] nav-link text-white mb-[.4rem]`} href='/constact-us'>
                                        Contact Us
                                    </a>
                                    {
                                        activePage === "" ||
                                            activePage === "constact-us" ?
                                            <div className='w-[100%] h-[0.2rem] rounded-[.35rem] bg-[#00D47E]'></div>
                                            :
                                            ""
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </div>
        </nav>
    )
}

export default Navbar

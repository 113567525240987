import React from 'react';
import "./PODServices1.css"
import SelectButton from '../../common/SelectButton/SelectButton';
import backgroundImage from "../../../static/img/podimg.png"

const PODServices1 = () => {
  return (
    <div>
      <div className='pod w-full h-full flex flex-col' style={{backgroundImage: `url(${backgroundImage})`}}>
        <div className='container-lg w-full h-screen flex justify-center'>
          <div className='w-full flex flex-col items-center justify-center'>
            <h1 className='text-[5rem] xl:text-[6rem] poppins-extralight text-white text-center overflow-hidden'>
              Accelerate Your Product Development with Infominez
              Agile POD Teams: Customized for Your Growth
            </h1>
            <p className='w-full text-white text-[3.2rem] text-center poppins-light'>Integrated, flexible teams for startups and enterprises—delivering tailored solutions from MVPs to scalable innovations.
            </p>
            <div className='py-[2rem] mt-[2rem]'>
              <SelectButton
                title={'Schedule a Free Consultation'}
                bgColor="bg-[#00D47E]"
                width="w-[31.2rem]"
                textSize="text-[1.6rem]"
                fontWeight="poppins-regular"
                height="h-[4.7rem]"
              // onClick={() => { Navigate("#") }}
              />
              {/* <button className='bg-[#41DF6B] text-[#060000] text-[1.6rem] poppins-regular rounded-[0.8rem] px-[8rem] py-[1.5rem]'>Your success Start here</button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg bg-white text-center py-[5rem]">
        <h1 className='text-[#000000] text-[6rem] poppins-extralight py-[2rem]'>Our POD Plans </h1>
        <p className="text-[#8A929A] text-[2.5rem] poppins-light  ">
          Infominez Agile POD Services are designed to seamlessly integrate with your business, empowering both startups and enterprises to achieve their goals faster and smarter. Whether you’re looking to build an MVP or scale complex enterprise solutions, our custom-built PODs provide the flexibility and expertise you need to succeed.<br/><br/>
          <span className='text-[#8A929A] text-[2.5rem] poppins-light'>
            We offer three tailored POD service models designed to fit your business’s stage and development needs.</span>
        </p>
      </div>
    </div>
  )
}

export default PODServices1
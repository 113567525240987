import React, { useEffect, useState } from "react";
import BtnOutlined from "../BtnOutlined/BtnOutlined";
import SelectButton from "../SelectButton/SelectButton";
import SectionHeaders from "../Headers/SectionHeaders";
import { useDispatch, useSelector } from "react-redux";
import { contactUsv1, contactUsv1Reset } from "../../../Store/actions/contactUsv1Actions";
import { useToast } from "../../hooks/useToast";

function GetStartedDetailsForm({ bgWhite }) {

  const dispatch = useDispatch();
  const Toast = useToast();

  const contactUsv1ReducerStatus = useSelector((state) => state.contactUsv1Reducer?.status);
  const contactUsv1ReducerMessage = useSelector((state) => state.contactUsv1Reducer?.message);


  const data = [
    {
      id: "name",
      title: "Your name",
      type: "string"
    },
    {
      id: "email",
      title: "Contact email",
      type: "string"
    },
    {
      id: "phone number",
      title: "Phone No",
      type: "string"
    },
    {
      id: "message",
      title: "Message",
      type: "string"
    },
  ]

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactUs, setContactUs] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (contactUsv1ReducerStatus == 200) {
      Toast(contactUsv1ReducerMessage, "success");
      dispatch(contactUsv1Reset());
    } else if (contactUsv1ReducerStatus) {
      Toast(contactUsv1ReducerMessage, "error");
      dispatch(contactUsv1Reset());
    }
  }, [contactUsv1ReducerStatus])


  return (
    <div id="GetStartedDetailsForm" className={bgWhite ? "bg-white" : "bg-[#F2F4F4]"}>
      <div className="py-[2rem] container-lg flex flex-col">
        <SectionHeaders
          contentPrimary={'Ready to Get Started?'}
          classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
          contentSecondary={`Transforming businesses with data, AI/ML, and cloud solutions`}
          secondaryClasses={`poppins-medium text-[2.5rem] text-[#8A929A] mb-[3.2rem]`}
        />
        <form className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] px-0 md:px-[4rem] lg:px-[8rem]">
          {
            data.map((item) => {
              return (
                <div className="flex flex-col my-[1.2rem]" key={item.id}>
                  <label className="poppins-medium text-[1.8rem] mb-[.8rem]">
                    {item.title}
                    <span className="poppins-medium text-[1.8rem] text-red-500">*</span>
                  </label>
                  <input
                    type={item.type}
                    id={item.id}
                    className="border-[0.5px] border-[#476E84] text-[1.8rem] rounded-[0.8rem] px-[2rem] h-[6rem] focus:outline-none focus:border-[#38C84F]"
                    placeholder=""
                    onChange={(e) => {
                      if (item.id === "name") {
                        setName(e.target.value)
                      } else if (item.id === "email") {
                        setEmail(e.target.value)
                      } else if (item.id === "phone number") {
                        setContactUs(e.target.value)
                      } else if (item.id === "message") {
                        setMessage(e.target.value)
                      }
                    }}
                  />
                </div>
              )
            })
          }
        </form>
        <div className="flex items-center justify-center mt-[2rem]">
          <SelectButton
            bgColor={`bg-[#41DF6B]`}
            fontWeight={`poppins-regular`}
            height={`h-[4.7rem]`}
            width={'w-[20rem]'}
            title={`Submit`}
            textSize={`text-[1.6rem]`}
            titleColor={'text-[#020000]'}
            onClick={() => dispatch(contactUsv1({
              "fullName": name,
              "email": email,
              "contact": contactUs,
              "subjectLine": "",
              "message": message
            }))}
          />
        </div>
      </div>
    </div>
  );
}

export default GetStartedDetailsForm;

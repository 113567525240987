import React from 'react';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';

const IndustryFintech2 = () => {
    return (
        <div className="bg-[#F2F4F4]">
            <div className="container-lg">
                <SectionHeaders
                    contentPrimary={'Advantages with Us'}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center my-[4rem]`}
                />
                <div className="w-full h-full flex flex-col lg:flex-row gap-[8rem]">
                    <div className="w-full lg:w-[50%] flex flex-col items-center">
                        <img
                            src={ServiceImg.fintech}
                            alt="Designer working on screen"
                            className="w-full"
                        />
                    </div>
                    <div className="w-full lg:w-[50%] h-full flex flex-col justify-start py-[3rem] mb-[3rem]">
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Industry Expertise</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Partner with a team that speaks your language and understands your challenges.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Innovation</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Get ahead of the curve with groundbreaking technology and trends.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Custom Solutions</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Receive solutions tailored specifically to your vision and goals
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Security</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Rest easy knowing your financial data is protected with state-of-the-art security.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mt-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Client-Centric Approach</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Your success is our success—expect a dedicated, collaborative partnership.
                                    </p>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndustryFintech2;
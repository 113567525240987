import React from 'react';
import { FiSearch } from 'react-icons/fi';
import { BlogBImg } from '../../../static/constants/imageConstant';

const Blog_b_Page1 = () => {
    return (
        <div className="container-lg w-full h-full flex flex-col bg-white py-[4rem]">
            <div className="w-full flex justify-between ">
                <div className="w-[80%] flex justify-center items-center gap-[5.1rem] ">
                    <a href="#" className="text-[2.2rem] text-[#030308] poppins-light">
                        App Development
                    </a>
                    <a href="#" className="text-[2.2rem] text-[#030308] poppins-light">
                        Data Analytics
                    </a>
                    <a href="#" className="text-[2.2rem] text-[#030308] poppins-light">
                        Blockchain
                    </a>
                    <a href="#" className="text-[2.2rem] text-[#030308] poppins-light">
                        Software Dev
                    </a>
                    <a href="#" className="text-[2.2rem] text-[#030308] poppins-light">
                        Cloud
                    </a>
                    <a href="#" className="text-[2.2rem] text-[#030308] poppins-light">
                        Business
                    </a>
                </div>
                <div className="w-[15%] flex gap-[1.5rem] justify-center items-center">
                    <FiSearch className="text-[#000] text-[2.4rem]" />
                    <input
                        type="text"
                        placeholder="Search"
                        className="w-full py-2 text-[2.2rem] text-[#030308] border-none placeholder-[#030308] focus:outline-none "
                    />
                </div>
            </div>


            <div className="flex flex-col sm:flex-row justify-between gap-8 py-[4rem]">
                <div className="w-full md:w-[48%]">
                    <img
                        src={BlogBImg.blogb1}
                        alt="Speaker"
                        className="rounded-lg w-full h-full"
                    />
                </div>

                <div className="w-full md:w-[49%] flex flex-col justify-center">
                    <p className="text-[1.8rem] poppins-regular text-[#000000]">Guides</p>
                    <h1 className="text-[4.5rem] poppins-regular  text-gray-900 py-[1rem]">
                        How Should a Developer Divide Their Time to Get the Most Out?
                    </h1>
                    <p className="text-[1.9rem] text-[#000000] poppins-light mt-[1rem] mb-[2rem]">
                        Genesis Expo will gather over 5,000 CTOs in one place, and about another
                        11,000 engineers. If you are a first time dev at Expo this post should
                        help you think about your trip.
                    </p>
                    <p className="text-[1.9rem] text-[#000000] poppins-light py-[1rem]">John Doe</p>
                </div>
            </div>


            <div className="py-[4rem]">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                    {Array(3)
                        .fill(null)
                        .map((_, index) => (
                            <div
                                key={index}
                                className="bg-[#fff] rounded-lg shadow-md overflow-hidden"
                            >
                                <div className='w-full h-[28.5rem]'>
                                <img
                                    src={BlogBImg.blogb2}
                                    alt="Event"
                                    className="w-full h-full"
                                />
                                </div>
                                <div className="px-[2.7rem]">
                                    <p className="text-[1.8rem] text-[#000000] poppins-regular py-[3.2rem]">
                                        [ October 9, 2018 ]
                                    </p>
                                    <h2 className="text-[2.2rem] poppins-regular text-[#000000] mb-[3.2rem]">
                                        A Developer's Guide to Expo: Workshops & Meetups
                                    </h2>
                                    <p className="text-[#000] poppins-light mb-[4rem] text-[2.2rem]">
                                        Genesis Expo will gather over 5,000 CTOs in one place, and
                                        about...
                                    </p>
                                </div>
                            </div>
                        ))}
                </div>
            </div>

        </div>
    );
};

export default Blog_b_Page1
import React from 'react';
import "./IndustryFintech1.css";
import BtnOutlined from '../../common/BtnOutlined/BtnOutlined';
import backgroundImage from "../../../static/assets/img/backgrounds/industries-landing-bg.jpg";

const IndustryFintech1 = () => {
    return (
        <div>
            <div className="industryfin w-full h-[63rem] flex flex-col items-center justify-center" style={{backgroundImage: `url(${backgroundImage})`}}>
                <div className="container-lg flex flex-col items-center justify-center text-center text-white">
                    <h1 className="text-[2.5rem] sm:text-[3rem] lg:text-[6rem] poppins-thin overflow-hidden mt-[16rem]">
                        Pioneering the Digital Finance Revolution
                    </h1>
                    <p className="text-[2.4rem] md:text-[2.7rem] lg:text-[3.2rem] poppins-light mt-[3rem]">
                        Experience the Future of Finance with Seamless, Secure, and Transformative Solutions
                    </p>
                    <div className='flex justify-center mt-[3rem] mb-[9rem]'>
                        <BtnOutlined
                            title="Learn More"
                            width="w-[29.4rem]"
                            textSize="text-[2.2rem]"
                            classNames={"border-2 rounded-full"}
                            titleColor={`text-white hover:text-[#0E0731]`}
                            borderColor="border-[#00D47E]"
                            bgColor={`bg-inherit text-white hover:bg-[#00D47E]`}
                            fontWeight={"poppins-medium"}
                            ButtonHeight={"h-[8rem]"}
                            hasArrow
                        //onClick={() => { navigate("/contact-us") }}
                        />
                    </div>
                </div>
            </div>
            <div className="container-lg bg-white text-center">
                <p className="text-[#8A929A] text-[2rem] lg:text-[2.4rem] 2xl:text-[2.5rem] px-[4rem] 2xl:px-[10rem] py-[9rem]">
                Infominez transforms financial challenges into opportunities, helping businesses of all sizes—startups, scaling companies, and established enterprises—achieve their goals. Our expert-driven solutions are designed to enhance customer experiences, streamline operations, and fuel growth. From fintech software development and payment processing to blockchain, regulatory compliance, data analytics, and robust security measures, we deliver the tools and strategies you need to succeed. Partner with Infominez to stay ahead in the fast-evolving fintech landscape.
                </p>
            </div>
        </div>
    )
}

export default IndustryFintech1;
import React from 'react';
import { BlogBImg } from '../../../static/constants/imageConstant';
import { ServiceImg } from '../../../static/constants/imageConstant';

const Blog_b_Page2 = () => {
    const latestPosts = [
        {
            id: 1,
            date: "October 9, 2018",
            title: "A Developer's Guide to Expo: Workshops & Meetups",
            description:
                "Genesis Expo will gather over 5,000 CTOs in one place, and about Genesis Expo will gather over ...",
            image: BlogBImg.blogb3,
        },
        {
            id: 2,
            date: "October 9, 2018",
            title: "A Developer's Guide to Expo: Workshops & Meetups",
            description:
                "Genesis Expo will gather over 5,000 CTOs in one place, and about Genesis Expo will gather over ...",
            image: BlogBImg.blogb3,
        },
        {
            id: 3,
            date: "October 9, 2018",
            title: "A Developer's Guide to Expo: Workshops & Meetups",
            description:
                "Genesis Expo will gather over 5,000 CTOs in one place, and about Genesis Expo will gather over ...",
            image: BlogBImg.blogb3,
        },
    ];

    const recentPosts = [
        {
            id: 1,
            title: "Discover Tech Talent with Indeed at Forum",
            date: "October 9, 2018",
            image: ServiceImg.ThomasMay,
        },
        {
            id: 2,
            title: "Lunch at Genesis Expo: What's on the menu?",
            date: "October 8, 2018",
            image: ServiceImg.ThomasMay,
        },
        {
            id: 3,
            title: "We're Hiring. Come Join Us at Genesis Expo",
            date: "October 8, 2018",
            image: ServiceImg.ThomasMay,
        },
    ];

    const tags = [
        "business",
        "conference",
        "development",
        "digital",
        "speaker",
        "tech",
        "venue",
        "workshop",
    ];

    return (
        <div className="container-lg w-full h-full flex flex-col lg:flex-row gap-[2rem] mb-[4rem]">
            <div className="w-full lg:w-2/3">
                <h2 className="text-[4.5rem] text-[#000] poppins-medium mb-[2rem]">Latest</h2>
                <div className="w-full space-y-[3rem]">
                    {latestPosts.map((post) => (
                        <div
                            key={post.id}
                            className="w-full flex flex-col sm:flex-row gap-[3rem] bg-white shadow-md rounded-lg overflow-hidden"
                        >
                            <div className="w-full md:w-[45.1rem] md:h-[28.4rem]">
                                <img
                                    src={post.image}
                                    alt={post.title}
                                    className="w-full h-full"
                                />
                            </div>
                            <div className="px-[2rem]  flex flex-col justify-center items-start">
                                <p className="text-[1.8rem] poppins-regular text-gray-500 py-[1rem]">[ {post.date} ]</p>
                                <h3 className="text-[2.2rem] poppins-regular font-semibold py-[1rem]">{post.title}</h3>
                                <p className="text-gray-600 text-[1.9rem] poppins-light py-[1rem] mb-[1rem]">{post.description}</p>
                                <a
                                    href="#"
                                    className="text-[#12C140] text-[1.9rem] poppins-regular hover:underline"
                                >
                                    Read More
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="w-full lg:w-1/3 px-[4rem]">
                <div className="mb-[2rem]">
                    <h2 className="text-[4.5rem] text-[#000] poppins-medium mb-[2rem]">Recent Posts</h2>
                    <ul className="space-y-[2rem]">
                        {recentPosts.map((post) => (
                            <li key={post.id} className="flex items-center gap-[2rem]">
                                <div className="w-[10rem] h-[10rem]">
                                    <img
                                        src={post.image}
                                        alt={post.title}
                                        className="w-full h-full rounded-full"
                                    />
                                </div>
                                <div>
                                    <h4 className="text-[2.2rem] text-[#000] poppins-regular py-[1rem]">{post.title}</h4>
                                    <p className="text-[1.8rem] text-[#000] poppins-light mb-[1rem]">{post.date}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className=''>
                    <h2 className="text-[4.5rem] text-[#000] text-center poppins-medium py-[4rem]">Tags</h2>
                    <div className="flex flex-wrap gap-[2rem]">
                        {tags.map((tag, index) => (
                            <span
                                key={index}
                                className="bg-gray-200 text-gray-700 text-[1.8rem] poppins-regular px-[2rem] py-[1rem] rounded-full"
                            >
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Blog_b_Page2;

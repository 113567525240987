import React from 'react';
import BtnOutlined from '../../common/BtnOutlined/BtnOutlined';
import backgroundGif from '../../../static/assets/img/backgrounds/service-landing-bg1.mkv';
import ReactPlayer from 'react-player';

const Ai_Ml_Services1 = () => {
    return (
        <div>
            <div className="relative w-full h-[63rem] flex flex-col">
                <div className="w-full h-full relative overflow-hidden">
                    <ReactPlayer
                        url={backgroundGif}
                        playing
                        loop
                        muted
                        width="100vw"
                        height="auto"
                        objectFit="cover"
                        className="absolute top-0 left-0 object-cover "
                    />
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white px-4">
                        <h1 className="text-[2.5rem] sm:text-[3rem] lg:text-[4.6rem] poppins-extralight leading-snug overflow-hidden">
                            Empower Your Business with Intelligent AI & ML Solutions
                        </h1>
                        <div className='flex justify-center mt-[3.1rem]'>
                            <BtnOutlined
                                title="Learn More"
                                width="w-[29.4rem]"
                                textSize="text-[2.2rem]"
                                classNames={"border-2 rounded-full"}
                                titleColor={`text-white hover:text-[#0E0731]`}
                                borderColor="border-[#00D47E]"
                                bgColor={`bg-inherit text-white hover:bg-[#00D47E]`}
                                fontWeight={"poppins-medium"}
                                ButtonHeight={"h-[8rem]"}
                                hasArrow
                            // onClick={() => { navigate("/contact-us") }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-lg w-full bg-white">
                <p className="text-[#8A929A] text-[1.6rem] sm:text-[2rem] md:text-[2.4rem] py-[9rem] mx-[5rem] poppins-regular text-center">
                    At Infominez, we guide your organization through a transformative AI and ML journey, turning data into powerful insights and creating intelligent systems that drive innovation, efficiency, and growth.
                </p>
            </div>
        </div>

    );
};

export default Ai_Ml_Services1
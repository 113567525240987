import React from 'react';
import Ai_Ml_Services1 from '../../components/main/Ai_Ml_Services1/Ai_Ml_Services1';
import Ai_Ml_Services2 from '../../components/main/Ai_Ml_Services2/Ai_Ml_Services2';
import Ai_Ml_Services3 from '../../components/main/Ai_Ml_Services3/Ai_Ml_Services3';
import Ai_Ml_Services4 from '../../components/main/Ai_Ml_Services4/Ai_Ml_Services4';
import Ai_Ml_Services5 from '../../components/main/Ai_Ml_Services5/Ai_Ml_Services5';
import Ai_Ml_Services6 from '../../components/main/Ai_Ml_Services6/Ai_Ml_Services6';
import ClientTestimonial from '../../components/main/ClientTestimonial/ClientTestimonial';
import GetStartedDetailsForm from '../../components/common/GetStartedDetailsForm/GetStartedDetailsForm';
import FooterSection from '../../components/common/FooterSection/FooterSection';
import MiniFooter from '../../components/common/MiniFooter/MiniFooter';
import Insights from '../../components/main/Insights/Insights';
import CaseStudy from '../../components/main/CaseStudy/CaseStudy';

const Ai_Ml_Services = () => {
  return (
    <div>
      <Ai_Ml_Services1 />
      <Ai_Ml_Services2 />
      <Ai_Ml_Services3 />
      <Ai_Ml_Services4 />
      <Ai_Ml_Services5 />
      <CaseStudy bgWhite />
      <ClientTestimonial />
      <Insights />
      <GetStartedDetailsForm bgWhite />
      <FooterSection />
      <MiniFooter />
    </div>
  )
}

export default Ai_Ml_Services
import React from 'react';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SelectButton from '../../common/SelectButton/SelectButton';

const DataEngineering5 = () => {
    const scrollToSection = () => {
        const element = document.getElementById('GetStartedDetailsForm');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="bg-[#F2F4F4] mt-[6rem]">
            <div className="container-lg mb-[8rem]">
                <SectionHeaders
                    contentPrimary={<> Why Partner with Infominez for  <br />End-to-End Success </>}
                    classes={`poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] items-center text-center justify-center my-[7.2rem]`}
                />
                <div className="w-full h-full flex flex-col lg:flex-row gap-[5rem]">
                    <div className="w-full lg:w-[48%]">
                        <div className='w-full'>
                        <img
                            src={ServiceImg.aiservice09}
                            alt="img"
                            className="w-full"
                        />
                        </div>
                    </div>
                    <div className="w-full lg:w-[50%] h-full flex flex-col justify-start">
                        <div className='w-full'>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Data Expertise</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Our data technology experts offer extensive experience and precision to your initiatives.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Innovative Solutions</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Transform raw data into actionable insights that drive strategic choices for a competitive edge.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Compliance Assurance</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        We prioritize data privacy and compliance, ensuring strict protection throughout our governance process.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem] mb-[1.6rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[1rem]">Scalable Solutions</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Our offerings adapt to your dynamic data needs and expanding analytics requirements.
                                    </p>
                                </div>
                            </li>
                            <li className="flex items-start gap-[2rem]">
                                <span className="h-[1.8rem] w-[1.8rem] bg-[#2666CF] rounded-full mt-4"></span>
                                <div className='w-full'>
                                    <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.8rem]">Faster Time-to-Insight</h3>
                                    <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                        Streamlined data processes unlock rapid value extraction, enabling real-time analytics for agile market responses.
                                    </p>
                                </div>
                            </li>
                        </div>
                        <div className="mt-[4rem] px-[3rem]">
                            <SelectButton
                                title={'Discover How We Can Help You Succeed'}
                                bgColor="bg-[#00D47E]"
                                width="w-[41.6rem]"
                                textSize="text-[1.6rem]"
                                fontWeight="poppins-regular"
                                titleColor={`text-white hover:text-[#2B2B2B]`}
                                height="h-[4.7rem]"
                                onClick={scrollToSection}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataEngineering5
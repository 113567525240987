import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import { IndustryImg } from "../../../static/constants/imageConstant";

const cardData = [
    {
        id: 1,
        title: "Expertise in Fintech",
        description: "Our seasoned professionals deliver unmatched insight and expertise in the financial sector.",
        icon: IndustryImg.IndustryPage01,
    },
    {
        id: 2,
        title: "Innovative Technology",
        description: "Harness the power of advanced technology to unlock new opportunities.",
        icon: IndustryImg.IndustryPage02,
    },
    {
        id: 3,
        title: "Personalized Solutions",
        description: "Every solution is crafted to meet your business's unique needs and aspirations.",
        icon: IndustryImg.IndustryPage03,
    },
    {
        id: 4,
        title: "Enhanced Security",
        description: "We go the extra mile to safeguard your data with cutting-edge security protocols. ",
        icon: IndustryImg.IndustryPage04,
    },
    {
        id: 5,
        title: "Collaborative Success",
        description: "We work hand-in-hand with you to achieve your business objectives, ensuring a seamless experience every step of the way. ",
        icon: IndustryImg.IndustryPage05,
    },
    {
        id: 6,
        title: "Expertise in Fintech",
        description: "Our seasoned professionals deliver unmatched insight and expertise in the financial sector. ",
        icon: IndustryImg.IndustryPage06,
    },
];

const IndustryFintech3 = () => {
    return (
        <div className="w-full h-full flex flex-col items-center px-[7rem]">
            <div className="container-lg py-[8rem]">
                <SectionHeaders
                    contentPrimary={"Value Propositions"}
                    classes={`poppins-extralight text-[6rem] text-center`}
                />
            </div>

            <div className="container-lg grid grid-cols-1 md:grid-cols-2  gap-y-[4rem] gap-x-[3rem] w-full mb-[8rem]">
                {cardData.map((card) => (
                    <div
                        key={card.id}
                        className="flex flex-col items-center p-[4rem] bg-[#F2F4F4] rounded-[1rem] shadow-md hover:shadow-lg transition-shadow duration-300"
                    >
                        <div className="flex items-start text-center">
                            <div className="w-[36%]">
                                <div className="w-[18.1rem] h-[18.1rem]">
                                    <img
                                        src={card.icon}
                                        alt={card.title}
                                        className="w-full h-full"
                                    />
                                </div>
                            </div>
                            <div className="w-[60%] flex flex-col text-start">
                                <h1 className="poppins-medium text-[2.8rem] text-[#000000] mb-[1rem]">
                                    {card.title}
                                </h1>
                                <h3 className="border border-t border-[#979EA5] h-[1px] w-full mt-[0.8rem] mb-[0.8rem]"></h3>
                                <p className="text-[2.2rem] text-[#979EA5] poppins-light">{card.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IndustryFintech3;

import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url, endpoints } from '../constants';
import { AxiosService } from '../services';
import { actionTypes } from '../types';
import { contactUsv1Failure, contactUsv1Success } from '../actions/contactUsv1Actions';

function* contactUsv1({ payload, callback }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url,
            endPoint: endpoints.contactUsv1,
            data: payload,
            isAuth: false,
        });

        console.log('====================================');
        console.log("CONTACT_US_V1_SUCCESS status :", response.status);
        console.log("CONTACT_US_V1_SUCCESS data :", response.data);
        console.log("CONTACT_US_V1_SUCCESS message :", response.message);
        console.log('====================================');
        yield put(contactUsv1Success({
            status: response?.status,
            data: response?.data,
            message: response?.message
        })
        )
    } catch (error) {
        console.log('====================================');
        console.log("CONTACT_US_V1_FAILURE error :", error.response?.status);
        console.log("CONTACT_US_V1_FAILURE message :", error.response?.data.statusmessage);
        console.log("CONTACT_US_V1_FAILURE error :", error);
        console.log('====================================');
        yield put(contactUsv1Failure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* contactUsv1Saga() {
    yield takeLatest(actionTypes.CONTACT_US_V1_REQUEST, contactUsv1);
}

export default contactUsv1Saga;
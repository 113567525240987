import React from 'react';
import { ContactImg } from '../../../static/constants/imageConstant'; 

const ContactInfo = ({ imgSrc, title, details, iconBig }) => (
    <div className="h-full flex flex-1 items-center gap-[4rem] border-[.1rem] rounded-[1rem] p-[5rem]">
        <div className={`${iconBig ? 'w-[16rem]' : 'w-[9rem]'} h-[9rem]`}>
            <img src={imgSrc} alt={title} className="w-full h-full" />
        </div>
        <div>
            <h3 className="text-[3rem] text-[#202020] poppins-semibold mb-2">{title}</h3>
            {details.map((detail, index) => (
                <p key={index} className="text-[#3C436A] text-[2.5rem] poppins-light">{detail}</p>
            ))}
        </div>
    </div>
);

const ContactUs1 = () => {
    return (
        <div className='container-lg w-full h-full flex '>
            <div className="flex flex-col justify-start items-start sm:flex-row gap-[2rem] py-[4rem]">
                <ContactInfo
                    imgSrc={ContactImg.ContactIcon1}
                    title="Office"
                    details={["787 South End Rd Jayanagar, Indore, 560005 India"]}
                    iconBig
                />
                <ContactInfo
                    imgSrc={ContactImg.ContactIcon2}
                    title="Call Us"
                    details={["+91-987-987-7986", "+91-987-987-7986"]}
                />
                <ContactInfo
                    imgSrc={ContactImg.ContactIcon3}
                    title="Send Us"
                    details={["info@infinimes.com", "career@infinimes.com"]}
                />
            </div>
        </div>
    );
};

export default ContactUs1;

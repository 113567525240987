import React from 'react';
import { ServiceImg } from '../../../static/constants/imageConstant';
import SectionHeaders from '../../common/Headers/SectionHeaders';

const IndustryHealthCare2 = () => {
    return (
      <div className="bg-[#F2F4F4]">
              <div className="container-lg">
                  <SectionHeaders
                      contentPrimary={'Advantages with Us'}
                      classes={`poppins-extralight text-[6rem] items-center text-center justify-center my-[4rem] mt-[8rem]`}
                  />
                  <div className="w-full h-full flex flex-col lg:flex-row gap-[5rem]">
                      <div className="w-full lg:w-[50%] flex flex-col items-center">
                          <img
                              src={ServiceImg.fintech02}
                              alt="Designer working on screen"
                              className="w-full"
                          />
                      </div>
                      <div className="w-full lg:w-[50%] h-full flex flex-col justify-start py-[3rem] mb-[3rem]">
                          <div className='w-full'>
                              <li className="flex items-start gap-[2rem] mb-[1rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Expertise in Healthcare & Wellness</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      Our team includes specialists with deep knowledge of the healthcare and wellness sectors, ensuring customized solutions that fit your unique needs. 
                                      </p>
                                  </div>
                              </li>
                              <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Innovative Technology</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      From AI-powered diagnostics to telemedicine, we leverage the latest innovations to bring you personalized care and proactive health management.   
                                      </p>
                                  </div>
                              </li>
                              <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Scalable Solutions</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      Our services grow with you, adapting to your changing health needs, providing seamless integration and easy access to care.  
                                      </p>
                                  </div>
                              </li>
                              <li className="flex items-start gap-[2rem] mb-[1rem] mt-[2rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Data Security</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      We prioritize your privacy, securing your medical data with industry-leading protocols to ensure confidentiality and integrity.  
                                      </p>
                                  </div>
                              </li>
                              <li className="flex items-start gap-[2rem] mt-[2rem]">
                                  <span className="h-[1.8rem] w-[1.8rem] bg-[#41DF6B] rounded-full mt-4"></span>
                                  <div className='w-full'>
                                      <h3 className="poppins-semibold text-[2.8rem] text-[#3C436A] mb-[0.5rem]">Patient-Centered Approach</h3>
                                      <p className="text-[#707F96] text-[2.2rem] poppins-regular mb-[1rem]">
                                      Your health is our mission. We work closely with you to tailor a wellness plan that supports your journey toward a healthier lifestyle  
                                      </p>
                                  </div>
                              </li>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      )
  }

export default IndustryHealthCare2;
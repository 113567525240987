import React from 'react';
import { CareerImg } from '../../../static/constants/imageConstant';
import SelectButton from '../../common/SelectButton/SelectButton';

const CarrerPage4 = () => {
    return (
        <div className='w-full h-full flex flex-col md:flex-row py-[6rem]'>
            <div className='w-full md:w-[55%]'>
                <div className='w-full h-full'>
                    <img src={CareerImg.career2} alt='' className='w-full h-full' />
                </div>
            </div>
            <div className="container-lg w-full md:w-[45%] flex justify-center items-center">
                <div className="bg-white w-full text-center px-[2rem] py-[2rem]">
                    <div className='w-full px-[4rem] py-[2rem]'>
                        <h1 className="text-[#000000] text-[6rem] text-start poppins-extralight mb-4">
                            Why Choose
                            Infominez?
                        </h1>
                        <p className="text-[2.2rem] text-[#707F96] dm-sans-medium text-start leading-relaxed mb-6">
                            At Infominez, we are more than just a technology company; we are a family of 
                            forward-thinkers, innovators, and passionate professionals. Our work spans
                             across digital transformation, AI, data engineering, and cutting-edge product 
                             development. By joining us, you’ll have the chance to work with industry experts, 
                             solve complex problems, and make a tangible impact on the world of technology.
                        </p>
                    </div>
                    <div className='w-full flex justify-start items-start px-[4rem] mb-[4rem]'>
                    <SelectButton
                            title={'Explore Opportunities'}
                            bgColor="bg-[#00D47E]"
                            width="w-[24.4rem]"
                            textSize="text-[1.8rem]"
                            fontWeight="poppins-medium"
                            height="h-[4.7rem]"
                            // onClick={() => { Navigate("#") }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CarrerPage4
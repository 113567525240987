import React from "react";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import SelectButton from "../../common/SelectButton/SelectButton";

const CloudEngineering3 = () => {
    const scrollToSection = () => {
        const element = document.getElementById('GetStartedDetailsForm');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

    const dataPoints = [
        {
            title: "Industry Expertise",
            description:
                "Our deep knowledge across multiple sectors ensures that our solutions are highly customized for your unique challenges.",
        },
        {
            title: "Security-First Approach",
            description:
                "We embed advanced security protocols in every solution to safeguard your data.",
        },
        {
            title: "24/7 Support",
            description:
                "Our dedicated support team is available around the clock to keep your cloud operations running smoothly.",
        },
        {
            title: "Pioneers in Technology",
            description:
                "We’re always ahead of the curve, leveraging the latest cloud technologies to drive innovation.",
        },
        {
            title: "Scalability and Agility",
            description:
                "Our cloud infrastructures are designed to grow with your business, ensuring peak performance as you scale.",
        },
        {
            title: "Tailored Solutions",
            description:
                "We work with businesses across industries, delivering solutions specific to your business needs.",
        },
    ];

    return (
        <div className="w-full h-full bg-[#F2F4F4] py-[5rem] mb-[8rem]">
            <div className="container-lg mx-auto flex flex-col items-center text-center px-6">
                <SectionHeaders
                    contentPrimary={
                        <>
                            Why Partner with Infominez for End-to-End Success?
                            Your trusted partner for cloud excellence.
                        </>
                    }
                    classes="poppins-extralight text-[2rem] sm:text-[3rem] md:text-[4rem] lg:text-[5rem] 2xl:text-[6rem] text-center my-[3.7rem]"
                />

                <p className="text-[1.8rem] md:text-[2.5rem] text-[#8A929A] poppins-regular mt-[2rem]">
                    When you choose Infominez, you’re choosing a cloud engineering
                    partner that prioritizes innovation, scalability, and security. We
                    work with businesses across industries—including Banking, FinTech,
                    EduTech, Retail, and more—delivering tailored solutions designed to
                    meet your specific business needs.
                </p>

                <h1 className="text-[#000000] text-[3rem] poppins-medium py-[3.9rem]">
                    What Sets Us Apart
                </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-[3rem] md:gap-[4rem]">
                    {dataPoints.map((point, index) => (
                        <div
                            key={index}
                            className="flex items-start gap-[1rem]"
                        >
                            <div className="w-[5%]"><div className="w-[1.2rem] h-[1.2rem] rounded-full bg-[#2666CF] mt-[1rem]" /></div>
                            <div className="w-[94%] text-start ">
                                <h3 className="text-[2.8rem] poppins-medium text-[#3C436A] mb-[0.8rem]">
                                    {point.title}
                                </h3>
                                <p className="text-[2.2rem] text-[#8A929A] poppins-regular">
                                    {point.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='py-[4rem]'>
                    <SelectButton
                        title={'See How We Can Help Your Business '}
                        bgColor="bg-[#00D47E]"
                        width="w-[37.9rem]"
                        textSize="text-[1.6rem]"
                        fontWeight="poppins-regular"
                        titleColor={`text-white hover:text-[#2B2B2B]`}
                        height="h-[4.7rem]"
                        onClick={scrollToSection}
                    />
                </div>
            </div>
        </div>
    );
};

export default CloudEngineering3;


import React, { useEffect, useState } from 'react';
import "./ContactUs2.css"
import ContactUsBg from "../../../static/assets/img/backgrounds/contact-us-bg.jpg"
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { SiGoogledocs } from 'react-icons/si';
import SectionHeaders from '../../common/Headers/SectionHeaders';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsv2, contactUsv2Reset } from '../../../Store/actions/contactUsV2Actions';
import { useToast } from '../../hooks/useToast';

const ContactUs2 = () => {

    const dispatch = useDispatch();
    const Toast = useToast();


    const contactUsv2ReducerStatus = useSelector((state) => state.contactUsv2Reducer?.status);
    const contactUsv2ReducerMessage = useSelector((state) => state.contactUsv2Reducer?.message);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [appBudget, setAppBudget] = useState("");
    const [projectDetails, setProjectDetails] = useState("");
    const [file, setFile] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("file", file);
        formData.append("email", email);
        formData.append("fullName", name);
        formData.append("company", company);
        formData.append("appBudget", appBudget);
        formData.append("projectDetails", projectDetails);

        dispatch(contactUsv2(formData));
    };

    useEffect(() => {
        if (contactUsv2ReducerStatus == 200) {
            Toast(contactUsv2ReducerMessage, "success");
            setName("");
            setEmail("");
            setCompany("");
            setAppBudget("");
            setProjectDetails("");
            setFile(null);
            dispatch(contactUsv2Reset());
        } else if (contactUsv2ReducerStatus) {
            Toast(contactUsv2ReducerMessage, "error");
            dispatch(contactUsv2Reset());
        }
    }, [contactUsv2ReducerStatus])

    return (
        <div>
            <div className='container-lg relative w-full h-[114rem]'>
                <div className='absolute top-[15rem] left-0 right-0 z-[10]'>
                    <SectionHeaders
                        contentPrimary={'Get In Touch With Our Team'}
                        classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
                        contentSecondary={<>We have the team & Know-how to help you scale 10x <br /> faster</>}
                        secondaryClasses={`poppins-medium text-[2.5rem] text-[#8A929A] mb-[3.2rem]`}
                    />
                </div>
                <div className="mt-[10rem]">
                    <img src={ContactUsBg} alt="" className="" />
                </div>
            </div>
            <div className="container-lg flex flex-col justify-center items-center bg-[#F2F4F4] rounded-[1.5rem] p-6 sm:p-8 lg:p-[4.4rem] text-center">
                <h2 className="text-[2.4rem] text-start poppins-semibold mb-[4rem]">
                    Write us a few words about your project, and we'll prepare a proposal for you within 24 hours.
                </h2>
                <form onSubmit={handleSubmit} className="w-full space-y-[4rem]">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-[4rem]">
                        <input
                            type="text"
                            placeholder="Your name"
                            className="w-full text-[#878791] h-[6.1rem] text-[1.8rem] poppins-medium bg-[#FFF] px-[2rem]"
                            required
                            onChange={(e) => setName(e.target.value)}
                        />
                        <input
                            type="email"
                            placeholder="Your email"
                            className="w-full text-[#878791] h-[6.1rem] text-[1.8rem] poppins-medium bg-[#FFF] px-[2rem]"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-[4rem]">
                        <input
                            type="text"
                            placeholder="Company"
                            className="w-full text-[#878791] h-[6.1rem] text-[1.8rem] poppins-medium bg-[#FFF] px-[2rem]"
                            onChange={(e) => setCompany(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="App Budget"
                            className="w-full text-[#878791] h-[6.1rem] text-[1.8rem] poppins-medium bg-[#FFF] px-[2rem]"
                            onChange={(e) => setAppBudget(e.target.value)}
                        />
                    </div>
                    <textarea
                        placeholder="Project Details"
                        className="w-full text-[#878791] h-[6.1rem] text-[1.8rem] poppins-medium bg-[#FFF] py-[1.5rem] px-[2rem]"
                        rows="6"
                        onChange={(e) => setProjectDetails(e.target.value)}
                    ></textarea>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-[4rem] items-center">
                        <div className="w-full p-[1.5rem] h-[11rem] bg-[#FFF] overflow-hidden">
                            <div className="w-full flex border-[.1rem] rounded-[1rem] border-dashed border-[#878791] text-gray-500 text-center ">
                                <div className="flex justify-start items-center w-full">
                                    <SiGoogledocs className='text-[8.3rem] text-[#4285F4] ml-[-1.2rem]' />
                                    <label htmlFor="file-upload" className="text-[#878791] text-[1.8rem] poppins-medium text-center cursor-pointer">
                                        {file ? file.name : "Drop it like it's hot"}
                                    </label>
                                    <input id="file-upload" type="file" className="hidden" onChange={(e) => setFile(e.target.files[0])} />
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="w-full h-[6.9rem] bg-[#41DF6B] hover:bg-[#36C15B] text-white text-[1.6rem] rounded-[0.8rem] py-3 poppins-regular"
                        >
                            Send
                        </button>
                    </div>
                </form>
                <p className="text-[#000000] mt-[4rem] text-[2.2rem] text-center poppins-regular">
                    If you need to have a DNA first, just contact us at <span className="text-[#14174E] poppins-bold">hello@infominez.com</span>
                </p>
            </div>
        </div>
    );
};

export default ContactUs2
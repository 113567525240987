import { put, call, takeLatest } from 'redux-saga/effects';
import { AxiosService } from '../services';
import { actionTypes } from '../types';
import { news_eventFailure, news_eventSuccess } from '../actions/news_eventActions';

function* news_event({ payload, callback }) {
    
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: "",
            endPoint: `https://dev.infominez.com/api/articles?populate=*&filters[category][slug][%24eq]=news_event`,
            data: payload,
            isAuth: true,
        });

        console.log('====================================');
        console.log("NEWS_EVENT_SUCCESS status :", response.status);
        console.log("NEWS_EVENT_SUCCESS data :", response.data);
        console.log("NEWS_EVENT_SUCCESS message :", response.message);
        console.log('====================================');
        yield put(news_eventSuccess({
            status: response?.status,
            data: response?.data,
            message: response?.message
        })
        )
    } catch (error) {
        console.log('====================================');
        console.log("NEWS_EVENT_FAILURE error :", error.response?.status);
        console.log("NEWS_EVENT_FAILURE message :", error.response?.message);
        console.log("NEWS_EVENT_FAILURE error :", error);
        console.log('====================================');
        yield put(news_eventFailure({
            status: error.response?.status,
            message: error.response?.message
        }));
    }
}

function* news_eventSaga() {
    yield takeLatest(actionTypes.NEWS_EVENT_REQUEST, news_event);
}

export default news_eventSaga;
import React from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { IndustryImg } from "../../../static/constants/imageConstant";
import SectionHeaders from "../../common/Headers/SectionHeaders";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IndustryPage3 = () => {
    const cardData = [
        {
            id: 1,
            range: "01–05",
            title: "Expertise in Fintech",
            description:
                "Our seasoned professionals bring unparalleled insight and experience in the financial industry.",
            image: IndustryImg.IndustryPage5,
        },
        {
            id: 2,
            range: "02–05",
            title: "Innovative Technology",
            description:
                "We harness the latest technological advancements to deliver state-of-the-art solutions.",
            image: IndustryImg.IndustryPage5,
        },
        {
            id: 3,
            range: "03–05",
            title: "Tailored to You",
            description:
                "Each solution is customized to meet the specific needs and goals of your business.",
            image: IndustryImg.IndustryPage5,
        },
    ];

    const PrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={`${className} flex justify-center items-center w-[11rem] h-[10rem] z-[10]`}
                onClick={onClick}
            >
                <button className="w-[5rem] h-[5rem] rounded-full border-[2px] border-[#21CE70] hover:bg-[#21CE70] text-[#21CE70] hover:text-black flex items-center justify-center">
                    <FaArrowLeft size={20} />
                </button>
            </div>
        );
    };

    const NextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div
                className={`${className} flex justify-center items-center w-[11rem] h-[10rem] z-[10]`}
                onClick={onClick}
            >
                <button className="w-[5rem] h-[5rem] rounded-full border-[2px] border-[#21CE70] hover:bg-[#21CE70] text-[#21CE70] hover:text-black flex items-center justify-center">
                    <FaArrowRight size={20} />
                </button>
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 6000,
        centerPadding: "0",
    };

    return (
        <div className="w-full h-full flex flex-col justify-center items-start px-[8rem]">
            <div className="container-lg py-[8rem]">
                <SectionHeaders
                    contentPrimary={"Value Propositions"}
                    classes={`poppins-extralight text-[6rem] items-center text-center justify-center`}
                    //contentSecondary={`You'll find an overview for what's on offer for developers, an interview with a dev, as well as some useful details. Expo has 3 dedicated conferences for devs; FullSTK, binate.io and creatiff. There are also a number of other conferences of some interest focused on AI, Robotics and related fields.`}
                    secondaryClasses={`poppins-regular text-[2.5rem] text-[#8A929A] mb-[3.2rem] px-[1rem] md:px-[4.2rem] lg:px-[6.4rem]`}
                />
            </div>
            <Slider {...settings} className="w-full pb-[8rem] px-[6rem]">
                {cardData.map((card) => (
                    <div
                        key={card.id}
                        className="w-[50%] h-[45rem] bg-[#F2F4F4] flex flex-col justify-center items-start px-[4rem] py-[4rem] rounded-[8px] mx-[1rem]"
                    >
                        <h1 className="text-[3rem] poppins-bold mb-[2rem]">
                            <span className="text-[#2865F6]">•</span> {card.range}
                        </h1>
                        <div className="w-[8.8rem] h-[8.8rem]">
                            <img
                                src={card.image}
                                alt={card.title}
                                className="w-full h-full"
                            />
                        </div>
                        <h3 className="text-[2.5rem] poppins-semibold mt-[1rem] py-[2rem]">
                            {card.title}
                        </h3>
                        <div className="w-[96%] flex justify-start items-start h-[.1rem] bg-gray-600 mb-8"></div>
                        <p className="text-[1.8rem] poppins-light text-[#979EA5] mt-[1rem]">
                            {card.description}
                        </p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default IndustryPage3;


// import React from "react";
// import { AboutUsImg } from "../../../static/constants/imageConstant";

// const cardData = [
//   {
//     title: "Expertise in Fintech",
//     description: "Our seasoned professionals deliver unmatched insight and expertise in the financial sector.",
//     image: AboutUsImg.icon1, // Replace with the actual image path
//   },
//   {
//     title: "Innovative Technology",
//     description: "Harness the power of advanced technology to unlock new opportunities.",
//     image: AboutUsImg.icon2, // Replace with the actual image path
//   },
//   {
//     title: "Personalized Solutions",
//     description: "Every solution is crafted to meet your business's unique needs and aspirations.",
//     image: AboutUsImg.icon3, // Replace with the actual image path
//   },
//   {
//     title: "Expertise in Fintech",
//     description: "Our seasoned professionals deliver unmatched insight and expertise in the financial sector.",
//     image: AboutUsImg.icon4, // Replace with the actual image path
//   },
//   {
//     title: "Expertise in Fintech",
//     description: "Our seasoned professionals deliver unmatched insight and expertise in the financial sector.",
//     image: AboutUsImg.icon5, // Replace with the actual image path
//   },
//   {
//     title: "Expertise in Fintech",
//     description: "Our seasoned professionals deliver unmatched insight and expertise in the financial sector.",
//     image: AboutUsImg.icon6, // Replace with the actual image path
//   },
// ];

// const IndustryPage3 = () => {
//   return (
//     <div className="px-4 py-8">
//       <h2 className="text-center text-2xl font-semibold mb-8">Value Propositions</h2>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
//         {cardData.map((card, index) => (
//           <div
//             key={index}
//             className="flex items-center bg-white rounded-lg shadow-md p-6"
//           >
//             <div className="flex-shrink-0 w-12 h-12">
//               <img
//                 src={card.image}
//                 alt={card.title}
//                 className="w-full h-full object-contain"
//               />
//             </div>
//             <div className="ml-4">
//               <h3 className="text-lg font-semibold">{card.title}</h3>
//               <p className="text-gray-600 mt-2 text-sm">{card.description}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default IndustryPage3;
